import { SET_ENABLED, SET_LATEST_TOKEN, SET_LOADED } from './actions'
import { GET_TOKEN_RECAPTCHA_DISABLED } from './fixtures'

/**
 * a reducer to update the state of the recaptcha provider
 */
const reCaptchaReducer = (state, action) => {
	switch (action.type) {
		case SET_ENABLED: {
			const { enabled } = action.payload

			return { ...state, enabled }
		}
		case SET_LOADED: {
			const { sdk } = action.payload

			return {
				...state,
				getToken: sdk?.execute
					? (action) => sdk.execute(state.siteKey, { action })
					: GET_TOKEN_RECAPTCHA_DISABLED,
				loaded: Boolean(sdk),
				sdk,
			}
		}
		case SET_LATEST_TOKEN: {
			const { latestToken } = action.payload

			return { ...state, latestToken }
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default reCaptchaReducer
