// React imports
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
// Other imports
import { datadogRum } from '@datadog/browser-rum'
// Local imports
import App from './App'

import Providers from './Providers'
import NVEligibilityCertifyCallback from '&views/NVEligibilityCertifyCallback'
import config from './utils/config.js'

datadogRum.init({
	applicationId: config.datadogAppId,
	clientToken: config.datadogClientToken,
	site: 'datadoghq.com',
	service: config.datadogService,
	env: config.datadogEnv,
	// Specify a version number to identify the deployed version of your application in Datadog
	// version: '1.0.0',
	sessionSampleRate: 100,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
	allowedTracingUrls: [config.apiBaseUrl],
})

const ProvidedApp = () => (
	<Providers>
		<App tab='home' />
	</Providers>
)

const NVCallbackApp = () => (
	<Providers>
		<NVEligibilityCertifyCallback />
	</Providers>
)

const RoutedApp = (
	<Router>
		<Routes>
			<Route path='/' index element={<ProvidedApp />} />
			<Route path='/callback' element={<NVCallbackApp />} />
			<Route path='/:partner' element={<ProvidedApp />} />
			<Route path='/:partner/:view' element={<ProvidedApp />} />
			<Route path='/:partner/:view/modal' element={<ProvidedApp />} />
		</Routes>
	</Router>
)

const container = document.getElementById('app')
const root = createRoot(container)
root.render(RoutedApp)
