import { createContext, useContext, useReducer } from 'react'
import nvReducer from './reducer'

// NVContext will store all of the information returned by the latest NV Eligibility API Call
const NVContext = createContext()

const NVProvider = ({ children }) => {
	const initialState = {
		applicationId: null,
		eligibilityCheckId: null,
		applicationStatus: null,
		failures: null,
		_links: null,
		uuid: null,
		response: null,
		loading: false,
		error: null,
	}

	const [state, dispatch] = useReducer(nvReducer, initialState)

	return (
		<NVContext.Provider value={[state, dispatch]}>
			{children}
		</NVContext.Provider>
	)
}

const useNVResponse = () => {
	const context = useContext(NVContext)
	if (context === undefined) {
		throw new Error('useNVResponse must be used within a NVProvider')
	}
	return context
}

export { NVContext, NVProvider, useNVResponse }
