import Header from '../Header'

const PageTitle = ({ color, title }) => {
	const styledTitle =
		title === 'GetACP.ORG' ? (
			<>
				Get<strong>ACP</strong>.ORG
			</>
		) : (
			title
		)

	return (
		<Header
			component='h1'
			sx={{
				color: { color },
				fontSize: '1em',
				fontWeight: 400,
				mb: 0,
				width: 'max-content',
			}}>
			{styledTitle}
		</Header>
	)
}

export default PageTitle
