/*
 we define our action types as constants to help avoid typos and make it clear what our possible
 actions are.
 */
export const SET_ENABLED = 'SET_ENABLED'
export const SET_LATEST_TOKEN = 'SET_LATEST_TOKEN'
export const SET_LOADED = 'SET_LOADED'

/**
 * an action to set reCaptcha as enabled or disabled
 *
 * @param {boolean} enabled – Whether or not the chat functionality is enabled
 */
export const setEnabledAction = (enabled = true) => ({
	type: SET_ENABLED,
	payload: { enabled },
})

/**
 * an action to indicate whether or not reCaptcha is loaded
 *
 * @param {Object} sdk – sdk instance from window object
 */
export const setLoadedAction = (sdk = null) => ({
	type: SET_LOADED,
	payload: { sdk },
})

/**
 * an action to set the most recently generated reCaptcha token
 *
 * @param {string} latestToken – recaptcha token
 */
export const setLatestTokenAction = (latestToken = null) => ({
	type: SET_LATEST_TOKEN,
	payload: { latestToken },
})
