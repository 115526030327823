import {
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
	FormHelperText,
	useTheme,
	alpha,
	useMediaQuery,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

/**
 * Reusable component for checkbox inputs. When multiple checkboxes
 * are used on the same page, a id must be provided to differentiate between them for accessibility purposes.
 *
 * @param {Array[object]} [options] - Array of radio button options, containing the label for the item and its value
 * @param {string} [value] – the current selected value of the radio group
 * @param {boolean} [isInvalid=false] – whether the value of the radio group is invalid
 * @param {boolean} [isRequired=true] – whether the radio value is required
 * @param {function} onRadioChange – callback function to handle changes to the radio buttons
 * @param {string} radioGroupLabel – the label for the checkbox input (translate in parent component)
 * @param {string} errorText – the error text for the checkbox input (translate in parent component)
 * @param {boolean} [isIdPage=false] – whether the radio group is on the ID page
 * @param {object} [labelSx] - styles for the label element
 * @param {object} [sx] - styles for the radio group element
 */
const RadioBtnInput = ({
	options,
	value = undefined,
	isInvalid = false,
	isRequired = true,
	radioGroupLabel,
	onRadioChange,
	errorText,
	isIdPage = false,
	labelSx,
	sx = {},
}) => {
	const theme = useTheme()

	// This is just for the ID page, where the currently selected button needs extra padding if it has a subInput
	const needsExtraPadding = (currValue) => {
		// If selected and the value does not have a subInput, we want needsExtraPadding to be true
		return isIdPage && currValue === value && currValue !== 'id'
	}

	// This is to determine if the user is on mobile (assuming largest screen is iPhone 14 Pro Max), so we can remove the hover outline effect on the radio inputs
	const isMobile = useMediaQuery(theme.breakpoints.down('431'))

	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	const inputStyles = {
		alignItems: 'flex-start',
		mb: 1,
		mr: 0,
		p: 3,
		borderRadius: '3px',
		gap: 2,
		'& .MuiButtonBase-root': {
			padding: 0,
			marginTop: '4px',
		},
		'&:hover': {
			// Do not show the teal outline or the boxShadow if we are on mobile
			outline: !isMobile && `solid 2px ${theme.palette.secondary.main}`,
			boxShadow:
				!isMobile &&
				`0px 0px 10px 0px ${alpha(theme.palette.secondary.main, 0.5)}`, //Is there a way to set opacity to hex values?
		},
		width: '100%',
		'& .MuiTypography-root': { fontSize: '1em', ...labelSx },
		'& .MuiFormControlLabel-asterisk': { display: 'none' },
	}

	const radioStyles = {
		backgroundColor: 'almostWhite',
		'& .MuiSvgIcon-root': {
			fontSize: theme.spacing(3),
		},
		'&:hover': {
			backgroundColor: 'almostWhite',
		},
		'&.Mui-checked': {
			color: theme.palette.secondary.main,
		},
	}

	return (
		<FormControl error={isInvalid} required={isRequired} fullWidth>
			<RadioGroup
				aria-label={radioGroupLabel}
				value={value}
				onChange={(e) => onRadioChange(e.target.value)}
				aria-describedby={isInvalid ? 'radio-error-text' : undefined}
				aria-activedescendant={value}>
				{options.map((item, index) => (
					<FormControlLabel
						key={index}
						sx={{
							...inputStyles,
							...sx,
							pt: needsExtraPadding(item.value) ? '30px' : 3,
							backgroundColor:
								value === item.value ? 'primary.lighter' : 'almostWhite',
						}}
						required
						control={
							<Radio
								inputProps={{
									id: item.value,
									role: 'radio',
									'data-ga-id': item.dataGaId,
									'aria-checked': value === item.value,
								}}
								sx={{
									...radioStyles,
								}}
							/>
						}
						value={item.value}
						label={
							value === item.value && item.subInput ? item.subInput : item.label
						}
						data-cy={item.dataCy}
					/>
				))}
			</RadioGroup>
			{isInvalid && (
				<FormHelperText
					role='alert'
					sx={{ ...messageStyles }}
					id={`radio-error-text`}>
					<ErrorOutlineIcon fontSize='small' />
					{errorText}
				</FormHelperText>
			)}
		</FormControl>
	)
}

export default RadioBtnInput
