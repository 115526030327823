// react imports

import { useTheme } from '@emotion/react'
// mui imports
import { Box } from '@mui/material'
// local imports
import { Button, Copy, Header, Layout, Link, NeedHelp } from '&components'
import Pineapple from '&assets/icons/pineapple.svg'
import { Launch } from '@mui/icons-material'

const NotQualified = () => {
	const theme = useTheme()

	const backgroundColor = theme.palette.secondary.main

	return (
		<Layout title='Not Qualified' topBarText={'Result'} {...backgroundColor}>
			<Box sx={{ display: 'flex', justifyContent: 'center', pb: 4, pt: 3 }}>
				<Pineapple style={{ height: theme.spacing(5.6) }} />
			</Box>
			<Header component='h1'>{'Sorry...'}</Header>
			<Copy sx={{ py: 3 }}>
				{`We are not able to determine your eligibility. You may qualify through your dependents or your income level by visiting the Universal Service Administration Company’s Website (USAC, the federal Administrator of the ACP).`}
			</Copy>
			<Box
				sx={{
					display: 'flex',
				}}>
				<Button
					data-ga-id='ineligible_usac-qualification'
					// Manually setting text transform since MUI is not passing the lowercase prop to the Link component
					sx={{ mb: 5, maxWidth: 'fit-content', textTransform: 'none' }}
					isCondensed
					component={Link}
					href='https://www.affordableconnectivity.gov/do-i-qualify/'
					newTab
					outbound>
					{'Continue on USAC’s website'}
					<Launch fontSize='small' sx={{ mb: 0, pb: 0 }} />
				</Button>
			</Box>
			<NeedHelp />
		</Layout>
	)
}

export default NotQualified

export const NotQualifiedView = {
	component: NotQualified,
	viewName: 'not-qualified',
}
