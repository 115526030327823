const LanguageCodes = {
	EN: 'en',
	ES: 'es',
}

const languageArray = [
	{
		code: LanguageCodes.EN,
		label: 'English',
		name: 'english',
	},
	{
		code: LanguageCodes.ES,
		label: 'Español',
		name: 'spanish',
	},
]

export { languageArray, LanguageCodes }
