// Icons
import SNAPIcon from '&assets/icons/apple.svg'
import MedicalIcon from '&assets/icons/medical.svg'
import HousingIcon from '&assets/icons/house.svg'
import MobileWifiIcon from '&assets/icons/mobile-wifi.svg'
import FeatherIcon from '&assets/icons/feather.svg'
import HatIcon from '&assets/icons/hat.svg'
import VeteransIcon from '&assets/icons/medal.svg'
import MoneyIcon from '&assets/icons/dollar.svg'
import BabyIcon from '&assets/icons/baby.svg'
import NoIcon from '&assets/icons/No.svg'
import HotspotIcon from '&assets/icons/hotspot.svg'
import HomewifiIcon from '&assets/icons/homewifi.svg'
import SmartphoneIcon from '&assets/icons/smartphone.svg'
import GamesIcon from '&assets/icons/game.svg'
import SchoolIcon from '&assets/icons/school.svg'
import ShoppingIcon from '&assets/icons/cart.svg'
import SocialMediaIcon from '&assets/icons/social media.svg'
import BillIcon from '&assets/icons/bill.svg'
import MoreIcon from '&assets/icons/more.svg'
import VideoCallIcon from '&assets/icons/videocall.svg'
import StreamingIcon from '&assets/icons/TV.svg'

// "Codes" should match the eligibilityProgramCodes on pages iv-v of the ACP- National Verifier API
export const benefitOptions = [
	{
		id: 'snap',
		value: 'SNAP',
		code: 'E2',
		qualificationPriority: 3,
		ariaLabel: 'Select SNAP',
		dataGaId: 'eligibility_snap',
		dataCy: 'select-benefits-snap-btn',
		icon: SNAPIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'medicaid',
		value: 'Medicaid',
		code: 'E1',
		qualificationPriority: 2,
		ariaLabel: 'Select Medicaid',
		dataGaId: 'eligibility_medicaid',
		dataCy: 'select-benefits-medicaid-btn',
		icon: MedicalIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'federalHousing',
		value: 'Federal Housing',
		code: 'E4',
		qualificationPriority: 11,
		ariaLabel: 'Select Federal Housing',
		dataGaId: 'eligibility_federal-housing',
		dataCy: 'select-benefits-federal-housing-btn',
		icon: HousingIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'lifeline',
		value: 'Lifeline',
		// there is no code for lifeline,
		// this will route to LifelineErrorView
		// and never call the API
		code: '',
		qualificationPriority: 1,
		ariaLabel: 'Select Lifeline',
		dataGaId: 'eligibility_lifeline',
		dataCy: 'select-benefits-lifeline-btn',
		icon: MobileWifiIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'tribal',
		value: 'Tribal Benefits',
		// the code can be either E8, E9, E10, E11, which will be selected in the view
		// following benefits selection
		code: '',
		qualificationPriority: 8,
		ariaLabel: 'Select Tribal Benefits',
		dataGaId: 'eligibility_tribal',
		dataCy: 'select-benefits-tribal-btn',
		icon: FeatherIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'pellGrant',
		value: 'Federal Pell Grant',
		code: 'E51',
		qualificationPriority: 4,
		ariaLabel: 'Select Federal Pell Grant',
		dataGaId: 'eligibility_pell-grant',
		dataCy: 'select-benefits-pell-btn',
		icon: HatIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'veteran',
		value: 'Veterans Benefits',
		code: 'E15',
		qualificationPriority: 5,
		ariaLabel: 'Select Veterans Benefits',
		dataGaId: 'eligibility_veterans-benefits',
		dataCy: 'select-benefits-veterans-btn',
		icon: VeteransIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'ssi',
		value: 'Supplemental Security Income',
		code: 'E3',
		qualificationPriority: 6,
		ariaLabel: 'Select SSI',
		dataGaId: 'eligibility_ssi',
		dataCy: 'select-benefits-ssi-btn',
		icon: MoneyIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'wic',
		value: 'WIC',
		code: 'E54',
		qualificationPriority: 7,
		ariaLabel: 'Select WIC',
		dataGaId: 'eligibility_wic',
		dataCy: 'select-benefits-wic-btn',
		icon: BabyIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 'none',
		value: 'None',
		code: '',
		ariaLabel: 'Select None',
		dataGaId: 'eligibility_other-way',
		dataCy: 'select-benefits-none-btn',
		icon: NoIcon,
		selected: false,
		isExclusive: true,
	},
]

export const connectionOptions = [
	{
		id: 1,
		value: 'Hotspot',
		ariaLabel: 'Select Hotspot',
		dataGaId: 'unconnected_hotspot',
		dataCy: 'select-connection-hotspot-btn',
		icon: HotspotIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 2,
		value: 'Home Wi-Fi',
		ariaLabel: 'Select Home Wi-Fi',
		dataGaId: 'connected_home-wi-fi',
		dataCy: 'select-connection-wifi-btn',
		icon: HomewifiIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 3,
		value: 'Smartphone',
		ariaLabel: 'Select Smartphone',
		dataGaId: 'unconnected_smartphone',
		dataCy: 'select-connection-smartphone-btn',
		icon: SmartphoneIcon,
		selected: false,
		isExclusive: false,
	},
	{
		id: 4,
		value: "I don't use the internet at home",
		ariaLabel: "Select I don't use the internet at home",
		dataGaId: 'unconnected_i-dont-use-internet',
		dataCy: 'select-connection-none-btn',
		icon: NoIcon,
		selected: false,
		isExclusive: true,
	},
]

export const internetUsageOptions = [
	{
		icon: SocialMediaIcon,
		value: 'Social Media',
		ariaLabel: 'Select Social Media',
		dataGaId: 'usage_social-media',
		dataCy: 'select-usage-social-media-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: StreamingIcon,
		value: 'Streaming TV',
		ariaLabel: 'Select Streaming TV',
		dataGaId: 'usage_streaming-tv',
		dataCy: 'select-usage-streaming-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: SchoolIcon,
		value: 'School or Work',
		ariaLabel: 'Select School or Work',
		dataGaId: 'usage_school-or-work',
		dataCy: 'select-usage-school-work-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: ShoppingIcon,
		value: 'Shopping',
		ariaLabel: 'Select Shopping',
		dataGaId: 'usage_shopping',
		dataCy: 'select-usage-shopping-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: MedicalIcon,
		value: 'Healthcare',
		ariaLabel: 'Select Healthcare',
		dataGaId: 'usage_healthcare',
		dataCy: 'select-usage-healthcafe-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: BillIcon,
		value: 'Paying Bills',
		ariaLabel: 'Select Paying bills',
		dataGaId: 'usage_paying-bills',
		dataCy: 'select-usage-bills-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: GamesIcon,
		value: 'Games',
		ariaLabel: 'Select Games',
		dataGaId: 'usage_games',
		dataCy: 'select-usage-games-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: VideoCallIcon,
		value: 'Video Calls',
		ariaLabel: 'Select Video Calls',
		dataGaId: 'usage_video-calls',
		dataCy: 'select-usage-video-calls-btn',
		selected: false,
		isExclusive: false,
	},
	{
		icon: MoreIcon,
		value: 'Other',
		ariaLabel: 'Select Other',
		dataGaId: 'usage_other',
		dataCy: 'select-usage-other-btn',
		selected: false,
		isExclusive: false,
	},
]

// when eligibilityProgramCode = 4 (Federal Housing), the API requires a publicHousingCode - selected by the user on FederalHousingView
export const federalHousingOptions = [
	{
		label: 'Housing Choice Voucher Program',
		dataCy: 'select-housing-housing-choice-voucher-program-btn',
		dataGaId: 'public-housing-code_1',
		value: '1',
	},
	{
		label: 'Project-based Rental Assistance',
		dataCy: 'select-housing-project-based-rental-assistance-btn',
		dataGaId: 'public-housing-code_2',
		value: '2',
	},
	{
		label: 'Public Housing',
		dataCy: 'select-housing-public-housing-btn',
		dataGaId: 'public-housing-code_3',
		value: '3',
	},
	{
		label:
			'Affordable Housing Programs for American Indians, Alaska Natives or Native Hawaiians',
		dataCy: 'select-housing-affordable-housing-programs-btn',
		dataGaId: 'public-housing-code_4',
		value: '4',
	},
]

// Tribal benefit options
export const tribalBenefitOptions = [
	{
		label: 'Bureau of Indian Affairs General Assistance',
		dataCy: 'select-tribal-bureau-indian-affairs-btn',
		dataGaId: 'tribal-benefit-code_1',
		value: 'E8',
	},
	{
		label: 'Tribal Head Start',
		dataCy: 'select-tribal-tribal-head-start-btn',
		dataGaId: 'tribal-benefit-code_2',
		value: 'E11',
	},
	{
		label: 'Tribal Temporary Assistance for Needy Families (Tribal TANF)',
		dataCy: 'select-tribal-tribal-temporary-assistance-btn',
		dataGaId: 'tribal-benefit-code_3',
		value: 'E9',
	},
	{
		label: 'Food Distribution Program on Indian Reservations',
		dataCy: 'select-tribal-food-distribution-program-btn',
		dataGaId: 'tribal-benefit-code_4',
		value: 'E10',
	},
]
