// Extracting the selected benefits
export const getSelectedBenefits = (benefits) => {
	return benefits.filter((item) => item.selected)
}

// Sorting benefits from highest to lowest priority
// Since the list of selected benefits is sorted by priority points, the
// first item will be the highest priority one
export const getPrioritizedBenefit = (benefits) => {
	const prioritizedBenefits = benefits.sort(
		(a, b) => a.qualificationPriority - b.qualificationPriority
	)
	return prioritizedBenefits[0]
}
