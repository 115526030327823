// react imports
import { useState, useEffect } from 'react'
// local imports
import {
	Button,
	ButtonGrid,
	Copy,
	Header,
	Layout,
	NeedHelp,
} from '../../components'
import { useTheme } from '@emotion/react'
import ClipboardIcon from '&assets/icons/clipboard.svg'
import { Box } from '@mui/material'
import { useSelection } from '&state/selection/context'
import { getMonthLabel } from '&utils/dropdownConstants'
import { UploadIDView } from '..'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { PathValues } from '&constants/enums/pathAndId'
import useNavigation from '&hooks/useNavigation'
import EditInfoMini from './EditInfoMini'
import useReCaptcha from '&hooks/useReCaptcha'
import useFetchEligibility from '&hooks/useFetchEligibility'
import config from '&config'

const SSNError = () => {
	// react hooks
	const [isPageLoading, setIsPageLoading] = useState(false)
	const [editMode, toggleEditMode] = useState(false)
	const [showSSN, toggleShowSSN] = useState(false)
	// third party hooks

	const theme = useTheme()
	// custom hooks
	const [{ name, dob, ssn }] = useSelection()
	const { goToView } = useNavigation()
	const { executeRecaptcha } = useReCaptcha()
	const {
		loading: eligibilityLoading,
		callEligibilityCheck,
		destination: destinationView,
	} = useFetchEligibility()
	const [{ pathName }] = useApplicationProcessContext()

	const { firstName, lastName } = name
	const { month, day, year } = dob

	const backgroundColor = theme.palette.secondary.main
	const socialMask = 'XXXX'

	// if the call is loading, set the page to load

	useEffect(() => {
		if (eligibilityLoading) setIsPageLoading(true)
	}, [eligibilityLoading])

	useEffect(() => {
		//listen for a destination, set in useFetchEligibility, then go to it
		if (destinationView && !eligibilityLoading) {
			goToView(destinationView)

			//reset page in case the same error loads again
			setIsPageLoading(false)
			toggleEditMode(false)
		}
	}, [destinationView, eligibilityLoading])

	useEffect(() => {
		if (editMode) {
			//shift focus to revealed content
			const firstInput = document.getElementById('given-name')
			firstInput.focus()
		}
	}, [editMode])

	const handleSubmit = async () => {
		//get new captcha token
		const token = await executeRecaptcha(
			config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
		)
		await callEligibilityCheck(token)
	}

	//only show progress bars if the path is not status check
	const showProgressBars = Boolean(pathName !== PathValues.STATUS_CHECK)

	return (
		<Layout
			title='SSN Error'
			topBarText={'Result'}
			showProgressBars={showProgressBars}
			isLoading={isPageLoading}
			{...backgroundColor}>
			<Header component='h2' sx={{ fontWeight: 800, fontSize: '31px', pb: 3 }}>
				{'Confirmation'}
			</Header>
			<Copy sx={{ mb: 3 }}>
				{`We couldn't match your name and SSN. Please confirm your legal name and the last four digits of your SSN.`}
			</Copy>
			{editMode ? (
				<EditInfoMini
					dataGaIdPrefix='ssn-error'
					handleSubmit={handleSubmit}
					toggleEditMode={toggleEditMode}
				/>
			) : (
				<div style={{ borderRadius: '10px', overflow: 'hidden' }}>
					<Box
						sx={{
							backgroundColor: 'almostWhite',
							p: 3,
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
							{'Basic Info'}
						</Header>
						<Copy bold sx={{ mb: 2 }}>
							{'Legal First Name'}
						</Copy>
						<Copy sx={{ mb: 2 }} className='weglot_exclude'>
							{firstName}
						</Copy>
						<Copy bold sx={{ mb: 2 }}>
							{'Legal Last Name'}
						</Copy>
						<Copy sx={{ mb: 2 }} className='weglot_exclude'>
							{lastName}
						</Copy>
						<Copy bold sx={{ mb: 2 }}>
							{'Date of Birth'}
						</Copy>
						<Copy sx={{ mb: 2 }}>
							{`${getMonthLabel(month)}`}
							<span className='weglot_exclude'>{`-${day}-${year}`}</span>
						</Copy>
					</Box>
					<Box
						sx={{
							backgroundColor: 'almostYellow',
							p: 3,
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Copy sx={{ fontWeight: 700, mb: 2 }}>
							{'Last four digits of SSN'}
						</Copy>
						<div style={{ display: 'flex' }}>
							<Copy sx={{ mb: 0 }} className='weglot_exclude'>{`XXX-XX-${
								showSSN ? ssn : socialMask
							}`}</Copy>
							<Button
								variant='text'
								aria-label={`${showSSN ? 'Hide' : 'Show'} SSN`}
								sx={{ ml: 2, lineHeight: '24px', fontWeight: '400' }}
								onClick={() => {
									toggleShowSSN(!showSSN)
								}}>
								{showSSN ? 'Hide' : 'Show'}
							</Button>
						</div>
					</Box>
				</div>
			)}
			{!editMode && (
				<ButtonGrid sx={{ my: 4 }}>
					<Button
						data-ga-id='ssn-error_edit-id'
						data-cy='ssn-error-edit-btn'
						isCondensed
						onClick={toggleEditMode}>
						{'Edit'}
					</Button>
				</ButtonGrid>
			)}
			<Button
				data-ga-id='ssn-error_proceed-upload-path'
				data-cy='ssn-error-proceed-btn'
				sx={{ mb: 5, fontWeight: '400' }}
				onClick={() => goToView(UploadIDView)}
				variant='text'>
				{'Or proceed without changes'}
			</Button>
			<NeedHelp />
		</Layout>
	)
}

export default SSNError

export const SSNErrorView = {
	component: SSNError,
	viewName: 'ssn-error',
	icon: ClipboardIcon,
	displayName: 'Result',
}
