// react imports

import { useCallback, useState, useRef, useEffect } from 'react'
// local imports
import { Button, ButtonGrid, Header } from '&components'
import { Box } from '@mui/material'
import { useSelection } from '&state/selection/context'
import { DOBInput, SSNInput, StringInput } from '&components/FormInputs'
import {
	validateDay,
	validateMonth,
	validateName,
	validateSSN,
	validateYear,
	validateAge,
} from '&utils/validation'
import { setSSNAction, setNameAndDOBAction } from '&state/selection/actions'

/**
 * a miniature version of EditInfoInput that only contains only the Name & DOB inputs and the SSN Input
 * @param {function} handleSubmit - function to submit after the data changes are saved
 * @param {function} toggleEditMode - function to toggle the edit mode
 * @param {string} dataGaIdPrefix - Prefix to append to data-cy properties of buttons inside this component
 */
const EditInfoMini = ({
	handleSubmit,
	toggleEditMode,
	sx,
	dataGaIdPrefix = '',
}) => {
	// we need to prefill the form if the user has already entered information before
	const [
		{
			name: { firstName: storedFirstName, lastName: storedLastName },
			dob: { month: storedMonth, day: storedDay, year: storedYear },
			ssn: storedSSN,
		},
		selectionDispatch,
	] = useSelection()

	const [formState, setFormState] = useState({
		firstName: storedFirstName,
		lastName: storedLastName,
		day: storedDay,
		month: storedMonth,
		year: storedYear,
		ssn: storedSSN,
		firstNameInvalid: false,
		lastNameInvalid: false,
		dayInvalid: false,
		monthInvalid: false,
		yearInvalid: false,
		ssnInvalid: false,
	})

	// state to control when the form submits after the context has saved the new data
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [areInputsValid, setAreInputsValid] = useState(false)
	const [hasInitialState, setHasInitialState] = useState(false)
	const hasStateMutated = useRef(false)

	useEffect(() => {
		if (hasSubmitted && areInputsValid && hasStateMutated) {
			handleSubmit()
			setHasSubmitted(false)
		}
	}, [hasSubmitted, areInputsValid, hasStateMutated])

	// when checking the dependency values, set initial state, then record the state has mutated once data is saved
	useEffect(() => {
		if (hasInitialState) {
			hasStateMutated.current = true
		} else {
			setHasInitialState(true)
		}
	}, [storedFirstName, storedLastName, storedSSN])

	const setFormValue = useCallback((key, value) => {
		setFormState((prevState) => ({
			...prevState,
			[key]: value,
		}))
	}, [])

	// function to validate each input, sets state and returns a isValid boolean
	const validateCleanField = (key, validationFunction) => {
		const value =
			typeof formState[key] === 'string'
				? formState[key].trim()
				: formState[key]
		const isValid = validationFunction(value)
		setFormValue(`${key}Invalid`, !isValid)

		// We set state anyway in case the value does NOT pass the validation, we still want it to be trimmed for the user
		formState[key] !== value && setFormValue(key, value)

		return { cleanedValue: value, isValid }
	}

	// function to validate appropriate age to make sure user is 13 or older
	const validateAgeField = (youngDOB, validationFunction) => {
		const isValid = validationFunction(youngDOB)
		setFormValue('ageInvalid', !isValid)
		return isValid
	}

	const saveAndSubmit = () => {
		const { cleanedValue: firstName, isValid: validFirstName } =
			validateCleanField('firstName', validateName)
		const { cleanedValue: lastName, isValid: validLastName } =
			validateCleanField('lastName', validateName)
		const { cleanedValue: day, isValid: validDay } = validateCleanField(
			'day',
			validateDay
		)
		const { cleanedValue: month, isValid: validMonth } = validateCleanField(
			'month',
			validateMonth
		)
		const { cleanedValue: year, isValid: validYear } = validateCleanField(
			'year',
			validateYear
		)
		const { cleanedValue: ssn, isValid: validSSN } = validateCleanField(
			'ssn',
			validateSSN
		)
		const isThirteenOrOlder = validateAgeField(
			{
				month: month,
				day: day,
				year: year,
			},
			validateAge
		)

		if (
			validFirstName &&
			validLastName &&
			validMonth &&
			validDay &&
			validYear &&
			validSSN &&
			isThirteenOrOlder
		) {
			setAreInputsValid(true)
			const normalizedDay = day.length === 1 ? `0${day}` : day

			selectionDispatch(setSSNAction(ssn))
			selectionDispatch(
				setNameAndDOBAction(firstName, lastName, normalizedDay, month, year)
			)
			setHasSubmitted(true)
		}
	}

	return (
		<Box sx={{ py: 2, ...sx }}>
			<Box role='form' sx={{ mx: -3, overflow: 'hidden' }}>
				<Box sx={{ p: 3, backgroundColor: 'almostWhite' }}>
					<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
						{`Basic Info`}
					</Header>
					{/* first name */}
					<StringInput
						isInvalid={formState.firstNameInvalid}
						isRequired
						onStringChange={(value) => setFormValue('firstName', value)}
						value={formState.firstName}
						stringLabel={'Legal First Name'}
						stringErrorText={
							'Please enter at least one letter for your first name'
						}
						id='given-name'
						data-cy='input-given-name'
						data-ga-id='input_first-name'
						autoComplete='given-name'
						name='given-name'
					/>
					{/* last name */}
					<StringInput
						isInvalid={formState.lastNameInvalid}
						isRequired
						onStringChange={(value) => setFormValue('lastName', value)}
						value={formState.lastName}
						stringLabel={'Legal Last Name or Family Name'}
						stringErrorText={
							'Please enter at least one letter for your last or family name'
						}
						id='family-name'
						data-cy='input-family-name'
						data-ga-id='input_last-name'
						autoComplete='family-name'
						name='family-name'
					/>
					{/* DOB */}
					<DOBInput
						isDayInvalid={formState.dayInvalid}
						isMonthInvalid={formState.monthInvalid}
						isYearInvalid={formState.yearInvalid}
						isTooYoung={formState.ageInvalid}
						onMonthChange={(value) => setFormValue('month', value)}
						onDayChange={(value) => setFormValue('day', value)}
						onYearChange={(value) => setFormValue('year', value)}
						dayValue={formState.day}
						monthValue={formState.month}
						yearValue={formState.year}
					/>
				</Box>
				<Box
					sx={{
						backgroundColor: 'almostYellow',
						display: 'flex',
						flexDirection: 'column',
						p: 3,
					}}>
					<SSNInput
						isInvalid={formState.ssnInvalid}
						onSSNChange={(value) => setFormValue('ssn', value)}
						isRequired
						value={formState.ssn}
						hideEncrypted
						labelSx={{ fontWeight: 700 }}
					/>
				</Box>
			</Box>
			<ButtonGrid sx={{ px: 3 }}>
				<Button
					data-ga-id={`${dataGaIdPrefix}_cancel-change`}
					isCondensed
					onClick={() => toggleEditMode(false)}
					variant='outlined'>
					{'Cancel'}
				</Button>
				<Button
					data-ga-id={`${dataGaIdPrefix}_submit-change`}
					onClick={saveAndSubmit}
					isCondensed>
					{`Submit`}
				</Button>
			</ButtonGrid>
		</Box>
	)
}

export default EditInfoMini
