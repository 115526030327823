import { createTheme } from '@mui/material/styles'
import { generateTheme } from './mui'

/**
 * This function is used to get the theme for a specific partner.
 *
 * @param {String} partnerName – The name of the partner.
 * @returns – The theme for the partner.
 */
export const getTheme = (partnerName) => {
	if (partnerName) {
		// convert the partner name to lowercase, just in case (pun not originally intended)
		partnerName = partnerName.toLowerCase()

		switch (partnerName) {
			//  just an example
			// case 'colorado': {
			// 	const { theme } = await import('../assets/branding/colorado')
			// 	return createTheme(theme)
			// }
			default: {
				return createTheme(generateTheme())
			}
		}
	} else {
		return createTheme(generateTheme())
	}
}
