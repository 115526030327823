import { Box } from '@mui/material'
import { Copy, IconBase } from '&components'
import BulbIcon from '&assets/icons/bulb.svg'

/**
 * this component is a box with a bulb icon to draw user's attention to the tip or message inside.
 *
 * @param {string} message - message to be displayed
 * @param {object} sx - additional styles to be passed to the Box component
 */
const TipBox = ({ message, sx, ...args }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				backgroundColor: 'almostYellow',
				borderRadius: 2,
				p: 3,
				pl: 2,
				...sx,
			}}
			{...args}>
			<IconBase
				aria-hidden='true'
				size='medium'
				sx={{ flexShrink: 0 }}
				icon={BulbIcon}
			/>
			<Copy sx={{ ml: 2, mb: 0 }}>{message}</Copy>
		</Box>
	)
}

export default TipBox
