import useNavigation from './hooks/useNavigation'
import HubSpotChatContainer from './components/HubSpotChatContainer'

const App = () => {
	const { getCurrentView } = useNavigation()

	const View = getCurrentView()

	return (
		<>
			<View />
			<HubSpotChatContainer />
		</>
	)
}

export default App
