import {
	GrowthBook,
	GrowthBookProvider as DefaultProvider,
} from '@growthbook/growthbook-react'
import { nanoid } from 'nanoid'
import { useContext, useEffect, useState, useRef } from 'react'
import config, { getConfigName } from '&config'
import { InitContext } from '../init/context'
import useFetch from '../../hooks/useFetch'
import {
	setGrowthBookFeatures,
	setGrowthBookUnavailable,
} from '../init/actions'

/**
 * This provider initializes an instance of GrowthBook by creating unique ids
 * for users, and using them to keep track of experiment results.
 * It gets the properties of all implemented features from GrowthBook.
 */
const GrowthBookProvider = ({ children }) => {
	const [visitorId, setVisitorId] = useState(localStorage.getItem('visitor_id'))
	const [{ growthBookFeatures }, initDispatch] = useContext(InitContext)
	const { response, error, executeFetch } = useFetch()
	const growthbookRef = useRef(null)

	useEffect(() => {
		executeFetch(config.featuresEndpoint)
	}, [])

	useEffect(() => {
		if (error || response?.error) {
			initDispatch(setGrowthBookUnavailable())
		} else if (response?.features) {
			initDispatch(setGrowthBookFeatures(response.features))
		}
	}, [error, response])

	useEffect(() => {
		if (!visitorId) {
			const new_visitor_id = nanoid()
			localStorage.setItem('visitor_id', new_visitor_id)
			setVisitorId(new_visitor_id)
		}
	}, [visitorId])

	useEffect(() => {
		if (growthbookRef.current && growthBookFeatures) {
			growthbookRef.current.setFeatures(growthBookFeatures)
		}
	}, [growthBookFeatures])

	useEffect(() => {
		const growthbook = new GrowthBook({
			attributes: {
				deviceId: visitorId,
			},
			trackingCallback: (experiment, result) => {
				if (getConfigName() !== 'prod') {
					console.log({
						experimentId: experiment.key,
						resultId: result.variationId,
					})
				}
			},
		})
		growthbookRef.current = growthbook
	}, [visitorId])

	return (
		<DefaultProvider growthbook={growthbookRef.current}>
			{children}
		</DefaultProvider>
	)
}

export default GrowthBookProvider
