import { createContext, useReducer } from 'react'
import initReducer from './reducer'

// the Init Context keeps track of the state of our Growthbook initialization
const InitContext = createContext()

const InitProvider = ({ children }) => {
	const initialState = {
		growthBookFeatures: null,
		isGBAvailable: null,
	}

	const [state, dispatch] = useReducer(initReducer, initialState)

	return (
		<InitContext.Provider value={[state, dispatch]}>
			{children}
		</InitContext.Provider>
	)
}

export { InitContext, InitProvider }
