// react imports

import { useTheme } from '@emotion/react'
import { useState } from 'react'
// local imports
import { Copy, Link } from '&components'
import {
	Box,
	useMediaQuery,
	IconButton,
	Tooltip,
	ClickAwayListener,
} from '@mui/material'
import IconBase from '&components/IconBase'
import WhatsApp from '&assets/icons/whats-app.svg'
import FacebookMessenger from '&assets/icons/facebook-messenger.svg'
import Snapchat from '&assets/icons/snapchat.svg'
import Text from '&assets/icons/texting.svg'
import Email from '&assets/icons/email.svg'
import config from '&config'

const { metaAppId } = config

const InviteBox = ({ sharingText, sharingSubject }) => {
	// State management to handle tooltip around snapchat button, delete when functional
	const [tooltipOpen, setTooltipOpen] = useState(false)

	const theme = useTheme()
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

	/* Facebook uses different endpoints to generate messages based on platform, we're targeting
	the website on desktop and the Messenger App on mobile */
	const getMessengerURL = () => {
		if (isDesktop) {
			return `//www.facebook.com/dialog/send?app_id=${metaAppId}&link=https://getacp.org&redirect_uri=https://getacp.org/`
		}
		return 'fb-messenger://share/?link=https://getacp.org&content=hello'
	}

	const handleTooltipClose = () => {
		setTooltipOpen(false)
	}

	const handleTooltipOpen = () => {
		setTooltipOpen(true)
	}

	return (
		<Box sx={{ pb: 4 }}>
			<div style={{ borderRadius: '10px', overflow: 'hidden' }}>
				<Box
					sx={{
						backgroundColor: 'almostWhite',
						p: 3,
						display: 'flex',
						flexDirection: 'column',
					}}>
					<Copy center>{'Invite your friends & family'}</Copy>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: 3,
						}}>
						<Link
							newTab
							outbound
							href={`https://wa.me/?text=${sharingText}`}
							data-action='share/whatsapp/share'
							aria-label={'Share on Facebook'}>
							<IconBase width='36px' height='36px' icon={WhatsApp} />
						</Link>
						<Link
							newTab
							outbound
							href={getMessengerURL()}
							aria-label={'Share on Messenger'}>
							<IconBase width='36px' height='36px' icon={FacebookMessenger} />
						</Link>
						{/* TODO: Make SnapChat share button functional */}
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<Tooltip
								placement='top'
								title="this button doesn't work yet. bug the dev team"
								PopperProps={{
									disablePortal: true,
								}}
								onClose={handleTooltipClose}
								open={tooltipOpen}
								disableFocusListener
								disableHoverListener
								disableTouchListener
								arrow>
								<IconButton
									onClick={handleTooltipOpen}
									sx={{ p: 0 }}
									aria-label={'Share on Snapchat'}>
									<IconBase width='36px' height='36px' icon={Snapchat} />
								</IconButton>
							</Tooltip>
						</ClickAwayListener>
						<Link href={`sms:?body=${sharingText}`} aria-label={'Share by SMS'}>
							<IconBase width='36px' height='36px' icon={Text} />
						</Link>
						<Link
							href={`mailto:?to=&body=${sharingText}&subject=${sharingSubject}`}
							aria-label={'Share by email'}>
							<IconBase width='36px' height='36px' icon={Email} />
						</Link>
					</Box>
				</Box>
			</div>
		</Box>
	)
}

export default InviteBox
