import {
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { IMaskMixin } from 'react-imask'
import { memo } from 'react'

// component to wrap MUI TextField with IMask for masking numbers
const IMaskInput = IMaskMixin(({ ...props }) => {
	return <TextField {...props} />
})

/*
 * Reusable component for number inputs (birth year, date, etc.). When multiple NumberInputs
 * are used on the same page, a id must be provided to differentiate between them for accessibility purposes.
 * We use IMask to mask the input to only allow numbers instead of MUI type='number' since the latter allows 'e'.
 *
 * @param {boolean} [isInvalid=false] – whether the number is invalid
 * @param {boolean} [isRequired=true] – whether the number is required
 * @param {function} onNumberChange – callback function to handle changes to the number
 * @param {string} numberLabel – label for the number input (translate in parent component)
 * @param {string} numberErrorText – error text for the number input (translate in parent component)
 * @param {string} [numberWidth='328px'] – width of the number input (day: 73px, year: 109px (default))
 * @param {number} [id=''] – (optional) a id to differentiate between multiple number inputs for accessibility purposes)
 * @param {string} [value] – (optional) a value to prefill the number input
 */
const NumberInput = ({
	isInvalid = false,
	isRequired = true,
	onNumberChange,
	numberLabel,
	numberErrorText,
	numberWidth = '109px',
	id = '',
	value = '',
}) => {
	const formStyles = {
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		height: '48px',
		'& input': {
			py: 1,
		},
		'& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
			{
				WebkitAppearance: 'none',
				margin: 0,
			},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		numberLabel && (
			<FormControl
				error={isInvalid}
				required={isRequired}
				variant='standard'
				sx={{ ...formStyles }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor={`number${id}-input`}>
					{numberLabel}
				</InputLabel>
				<IMaskInput
					id={`number${id}-input`}
					value={value}
					error={isInvalid}
					onChange={(e) => onNumberChange(e.target.value)}
					sx={{ width: numberWidth }}
					InputProps={{
						'aria-describedby': isInvalid
							? `number${id}-error-text`
							: undefined,
						sx: { ...inputRootStyles },
						inputProps: { 'aria-required': isRequired },
					}}
					mask={Number}
				/>
				{isInvalid && (
					<FormHelperText
						sx={{ ...messageStyles }}
						id={`number${id}-error-text`}>
						<ErrorOutlineIcon fontSize='small' />
						{numberErrorText}
					</FormHelperText>
				)}
			</FormControl>
		)
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(NumberInput, comparator)
