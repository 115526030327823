import { useState, useEffect, useContext } from 'react'
import { useFeature } from '@growthbook/growthbook-react'
import { InitContext } from '../state/init/context'

/**
 * This hook is used to fetch the result of experiments and return
 * their value, the className used to trigger a GA event, and data
 * properties passed to that event to populate GA data.
 *
 * @param {String} id – the id of the feature in Growthbook
 * @returns {Object} – an object containing the result/value of the
 *                     feature, the 'experiment' className to trigger the view event, and
 *                     data properties provided to Google Analytics
 */
const useExperiment = (id) => {
	const [{ isGBAvailable }] = useContext(InitContext)
	const [featureValue, setFeatureValue] = useState(null)
	const [featureClass, setFeatureClass] = useState('')
	const [featureProperties, setFeatureProperties] = useState({
		'data-experiment-id': id,
		'data-experiment-result': null,
	})

	const feature = useFeature(id)
	const value = feature?.value

	useEffect(() => {
		/*
     would this be better? it's clearer, but due to the way that == works with Boolean values, I
     try to avoid using === with them, too 

     if (isGBAvailable === false) return
     */
		if (!isGBAvailable && isGBAvailable !== null) return

		const className = value !== null ? 'experiment' : ''

		setFeatureValue(value)
		setFeatureClass(className)
		setFeatureProperties({
			...featureProperties,
			'data-experiment-result': value,
		})
	}, [value, isGBAvailable])

	return { featureValue, featureClass, featureProperties }
}

export default useExperiment
