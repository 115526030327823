const ApiStatus = {
	APP_NOT_FOUND: 'APP_NOT_FOUND',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETE: 'COMPLETE',
	PENDING_CERT: 'PENDING_CERT',
	PENDING_CONTINUED_ELIGIBILITY: 'PENDING_CONTINUED_ELIGIBILITY',
	PENDING_CONTINUED_ELIGIBILITY_BATCH: 'PENDING_CONTINUED_ELIGIBILITY_BATCH',
	PENDING_ELIGIBILITY: 'PENDING_ELIGIBILITY',
	PENDING_RECERTIFICATION_RESOLUTION: 'PENDING_RECERTIFICATION_RESOLUTION',
	PENDING_REVIEW: 'PENDING_REVIEW',
	PENDING_RESOLUTION: 'PENDING_RESOLUTION',
	BAD_REQUEST: 'BAD_REQUEST',
}

const NVCertifiableStatuses = [
	ApiStatus.PENDING_CERT,
	ApiStatus.PENDING_RESOLUTION,
]

const FailureCodes = {
	INVALID_ADDRESS: 'INVALID_ADDRESS',
	DUPLICATE_ADDRESS: 'DUPLICATE_ADDRESS',
	SUBSCRIBER_UNDER_18: 'SUBSCRIBER_UNDER_18',
	TPIV_FAIL_IDENTITY_NOT_FOUND: 'TPIV_FAIL_IDENTITY_NOT_FOUND',
	TPIV_FAIL_DOB: 'TPIV_FAIL_DOB',
	TPIV_FAIL_DECEASED: 'TPIV_FAIL_DECEASED',
	TPIV_FAIL_NAME_SSN4: 'TPIV_FAIL_NAME_SSN4',
	STATE_FED_FAIL: 'STATE_FED_FAIL',
}

const SSNErrorFailures = [
	FailureCodes.TPIV_FAIL_IDENTITY_NOT_FOUND,
	FailureCodes.TPIV_FAIL_NAME_SSN4,
]

const DocumentErrorFailures = [
	FailureCodes.TPIV_FAIL_DOB,
	FailureCodes.STATE_FED_FAIL,
]

const ErrorStatuses = {
	AMS_UNAVAILABLE: 'AMS_UNAVAILABLE',
	APP_CLOSED_OR_EXPIRED: 'APP_CLOSED_OR_EXPIRED',
	APPLICATION_LIMIT_EXCEEDED: 'APPLICATION_LIMIT_EXCEEDED',
	BQP_INFO_REQUIRED: 'BQP_INFO_REQUIRED',
	BQP_SUBSCRIBER_OVER_130: 'BQP_SUBSCRIBER_OVER_130',
	CARRIER_DOMAIN_NOT_FOUND: 'CARRIER_DOMAIN_NOT_FOUND',
	DOB_IN_FUTURE: 'DOB_IN_FUTURE',
	ELIG_CHECK_ID_NOT_FOUND: 'ELIG_CHECK_ID_NOT_FOUND',
	EXISTING_LIFELINE_APP: 'EXISTING_LIFELINE_APP',
	HEADERS_INVALID: 'HEADERS_INVALID',
	INCOMPLETE_BQP_INFORMATION: 'INCOMPLETE_BQP_INFORMATION',
	INCOMPLETE_CONTAlCT_ADDRESS: 'INCOMPLETE_CONTACT_ADDRESS',
	INVALID_ADDRESS_LINE: 'INVALID_ADDRESS_LINE',
	INVALID_CONSENT_IND: 'INVALID_CONSENT_IND',
	INVALID_ELIGIBILITY_CODE: 'INVALID_ELIGIBILITY_CODE',
	INVALID_ELIGIBILITY_CHECK_ID: 'INVALID_ELIGIBILITY_CHECK_ID',
	INVALID_EMAIL: 'INVALID_EMAIL',
	INVALID_FIELD_LENGTH: 'INVALID_FIELD_LENGTH',
	INVALID_FORMAT_DATE: 'INVALID_FORMAT_DATE',
	INVALID_NAME: 'INVALID_NAME',
	INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
	INVALID_PUBLIC_HOUSING_CODE: 'INVALID_PUBLIC_HOUSING_CODE',
	INVALID_REPID: 'INVALID_REPID',
	INVALID_NO_REP_VALUE: 'INVALID_NO_REP_VALUE',
	INVALID_SSN: 'INVALID_SSN',
	INVALID_TRIBAL_ID: 'INVALID_TRIBAL_ID',
	INVALID_ZIP_CODE: 'INVALID_ZIP_CODE',
	MALFORMED_REDIRECT_URL: 'MALFORMED_REDIRECT_URL',
	MISSING_REQUIRED_FIELD: 'MISSING_REQUIRED_FIELD',
	MISSING_SSN_OR_TRIBAL: 'MISSING_SSN_OR_TRIBAL',
	MULTIPLE_REPID_REPNOTASSISTED: 'MULTIPLED_REPID_REPNOTASSISTED',
	PUBLIC_HOUSING_CODE_REQUIRED: 'PUBLIC_HOUSING_CODE_REQUIRED',
	RAD_UNAVAILABLE: 'RAD_UNAVAILABLE',
	REPID_DEADLINE_MISSED: 'REPID_DEADLINE_MISSED',
	REPID_NOT_ACTIVE: 'REPID_NOT_ACTIVE',
	REPID_NOT_LINKED: 'REPID_NOT_LINKED',
	REQUEST_ALREADY_SENT: 'REQUEST_ALREADY_SENT',
	SSN_AND_TRIBAL: 'SSN_AND_TRIBAL',
	SSN_AND_TRIBAL_BQP: 'SSN_AND_TRIBAL_BQP',
	SUBSCRIBER_ADDRESS_CANNOT_BE_PO_BOX: 'SUBSCRIBER_ADDRESS_CANNOT_BE_PO_BOX',
	SUBSCRIBER_OVER_130: 'SUBSCRIBER_OVER_130',
}

export {
	ApiStatus,
	FailureCodes,
	NVCertifiableStatuses,
	DocumentErrorFailures,
	SSNErrorFailures,
	ErrorStatuses,
}
