// react imports

import { useTheme } from '@emotion/react'
import { useState } from 'react'
import { Box } from '@mui/material'
// local imports
import {
	BackButton,
	ButtonGrid,
	Copy,
	Header,
	Layout,
	NextButton,
} from '&components'
import MapIcon from '&assets/icons/map.svg'
import { ZipCodeInput } from '&components/FormInputs'
import { useSelection } from '&state/selection/context'
import { setZipCodeAction } from '&state/selection/actions'
import { validateZipcode } from '&utils/validation'
import useNavigation from '&hooks/useNavigation'
import { ConnectionView } from '../Connection'

const ZipCode = () => {
	const theme = useTheme()
	const [
		{
			address: { zipCode: storedZipCode },
		},
		selectionDispatch,
	] = useSelection()
	const { goBackward, goToView } = useNavigation()

	const [isInvalid, setIsInvalid] = useState(false)
	const [zipCode, setZipCode] = useState(storedZipCode)

	const backgroundColor = theme.palette.secondary.main

	const handleZipCode = () => {
		const isValidZip = validateZipcode(zipCode)
		setIsInvalid(!isValidZip)

		if (isValidZip) {
			//Pushing zipCode input event to datalayer for Google to log
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'inputSubmission',
				inputId: 'ZipCode',
				inputValue: zipCode,
			})

			selectionDispatch(setZipCodeAction(zipCode))
			goToView(ConnectionView)
		}
	}
	return (
		<Layout title='Zipcode' topBarText={'Qualification'} {...backgroundColor}>
			<Box sx={{ display: 'flex', justifyContent: 'center', pb: 4, pt: 3 }}>
				<MapIcon style={{ width: '95px' }} aria-hidden='true' />
			</Box>
			<Header component='h2' sx={{ fontWeight: 500, fontSize: 20 }}>
				{'What is your zip code?'}
			</Header>
			<Copy sx={{ py: 3 }}>{'The location where you will need internet.'}</Copy>
			<Box role='form'>
				<ZipCodeInput
					isInvalid={isInvalid}
					value={zipCode}
					isRequired
					onZipChange={setZipCode}
				/>
			</Box>
			<ButtonGrid sx={{ mt: '120px', mb: 0 }}>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<NextButton onClick={handleZipCode} isCondensed />
			</ButtonGrid>
		</Layout>
	)
}

export default ZipCode

export const ZipCodeView = { component: ZipCode, viewName: 'zip-code' }
