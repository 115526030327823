import { memo } from 'react'
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	FormHelperText,
	useTheme,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

/**
 * Reusable component for checkbox inputs. When multiple checkboxes
 * are used on the same page, a id must be provided to differentiate between them for accessibility purposes.
 *
 * @param {boolean} [isInvalid=false] – (optional) whether the value of the checkbox is invalid
 * @param {boolean} [isRequired=true] – (optional) whether the check is required
 * @param {function} onCheckboxChange – callback function to handle changes to the checkbox
 * @param {string} checkboxLabel – the label for the checkbox input (translate in parent component)
 * @param {string} [id=''] – (optional) a id to differentiate between multiple checkbox inputs for accessibility purposes)
 * @param {string} [checked=false] – (optional) the default checked value
 * @param {string} errorText – the error text for the checkbox input (translate in parent component)
 * @param {object} [labelSx] - (optional) styles for the label element
 * @param {object} [sx] - (optional) styles for the FormControl element
 */

const CheckboxInput = ({
	isInvalid = false,
	isRequired = true,
	onCheckboxChange,
	checkboxLabel,
	id = '',
	checked = false,
	errorText,
	labelSx,
	sx,
	...args
}) => {
	const theme = useTheme()

	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		<FormControl error={isInvalid} required={isRequired} sx={{ ...sx }}>
			<FormControlLabel
				sx={{
					ml: '-3px',
					mr: 0,
					'&:hover, &:has(:focus)': {
						'& svg path': {
							color: isInvalid ? 'customError.main' : 'secondary.main',
						},
						'& ::after': {
							boxShadow: isInvalid
								? `0px 0px 4px ${theme.palette.customError.main}}`
								: '0px 0px 4px #009296',
						},
					},
					'& .MuiButtonBase-root': { p: 0 },
					'& .MuiTypography-root': { fontSize: '1em', ...labelSx },
					'& .MuiFormControlLabel-asterisk': { display: 'none' },
				}}
				control={
					<Checkbox
						checked={checked}
						onChange={onCheckboxChange}
						inputProps={{
							'aria-label': 'Agree to terms',
							'aria-required': isRequired,
							...args,
						}}
						color='secondary'
						sx={{
							'& .MuiSvgIcon-root': {
								fontSize: '2em',
							},
							'& path': {
								color: isInvalid ? 'customError.main' : '#00000099',
							},
							'::after': {
								content: '""',
								position: 'absolute',
								borderRadius: '13%',
								width: '23px',
								height: '23px',
								boxShadow: isInvalid
									? `0px 0px 4px ${theme.palette.customError.main}}`
									: '',
							},
						}}
					/>
				}
				label={checkboxLabel}
			/>
			{isInvalid && (
				<FormHelperText
					role='alert'
					sx={{ ...messageStyles }}
					id={`${id}-error-text`}
					data-cy={`${id}-error-text`}>
					<ErrorOutlineIcon fontSize='small' />
					{errorText}
				</FormHelperText>
			)}
		</FormControl>
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.checked === prevProps.checked &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(CheckboxInput, comparator)
