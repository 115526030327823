// react imports
import { createContext, useContext, useReducer } from 'react'
// local imports
import hubSpotChatReducer from './reducer'
import { setWidgetLoadedAction } from './actions'

const HubSpotChatContext = createContext()

/**
 * This provider adds the HubSpot chat widget script to the <body> of the DOM, and wraps
 * relevant SDK functionality in react-friendly methods
 */
const HubSpotChatProvider = ({ children }) => {
	const initialState = {
		enabled: true, //this was originally controlled by growthbook, and can be tied to growthbook again if necessary
		loaded: false,
		open: false,
		widget: null,
	}
	const [state, dispatch] = useReducer(hubSpotChatReducer, initialState)

	/**
	 * Docs here: https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code
	 */
	const hubSpotScriptElement = document.createElement('script')
	hubSpotScriptElement.type = 'text/javascript'
	hubSpotScriptElement.src = `//js.hs-scripts.com/39487406.js`
	hubSpotScriptElement.async = true

	window.hsConversationsSettings = {
		inlineEmbedSelector: '#esh-hubspot-widget-container',
	}

	document.body.appendChild(hubSpotScriptElement)

	window.hsConversationsOnReady = [
		() => {
			dispatch(setWidgetLoadedAction(window.HubSpotConversations.widget))
		},
	]

	return (
		<HubSpotChatContext.Provider value={[state, dispatch]}>
			{children}
		</HubSpotChatContext.Provider>
	)
}

const useHubSpotChat = () => {
	const context = useContext(HubSpotChatContext)
	if (context === undefined) {
		throw new Error('useHubSpotChat must be used within a HubSpotChatProvider')
	}
	return context
}

export { HubSpotChatContext, HubSpotChatProvider, useHubSpotChat }
