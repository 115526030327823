/**
 * An SVG React component that is used as a decorative element on various pages.
 */
const Notice = ({ width, color, ...args }) => {
	return (
		<svg
			viewBox='0 0 16 16'
			width={width}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...args}>
			<circle cx='8' cy='8' r='6.5' stroke={color || '#C1561A'} />
			<circle
				cx='7.99984'
				cy='10.9166'
				r='0.583333'
				fill={color || '#C1561A'}
			/>
			<rect
				x='7.4165'
				y='4.5'
				width='1.16667'
				height='4.08333'
				fill={color || '#C1561A'}
			/>
		</svg>
	)
}

export default Notice
