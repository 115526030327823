import { memo, useState } from 'react'
import {
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
	Box,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { IMaskMixin } from 'react-imask'
import LockIcon from '&assets/icons/lock.svg'
import { Copy, IconBase, Button } from '&components'

// Component to wrap MUI TextField with IMask for masking TribalId
const IMaskInput = IMaskMixin(({ ...props }) => {
	return <TextField {...props} />
})

/*
 * Component for TribalId inputs
 *
 * @param {boolean} isInvalid – whether the TribalId is invalid
 * @param {boolean} isRequired – whether the TribalId is required
 * @param {function} onTribalIdChange – callback function to handle changes to the TribalId
 * @param {string} [value] – (optional) value of the TribalId input if controlled
 * @param {boolean} [hideEncrypted=false] – (optional) whether to hide the encrypted label
 */
const TribalIdInput = ({
	isInvalid = false,
	isRequired = true,
	onTribalIdChange,
	value = '',
	hideEncrypted = false,
}) => {
	const [showTribalId, setShowTribalId] = useState(false)

	const handleClickShowTribalId = () => setShowTribalId((show) => !show)

	const handleMouseDownTribalId = (event) => {
		event.preventDefault()
	}

	const formStyles = {
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
		'.input-decor': {
			color: isInvalid ? 'customError.main' : 'auto',
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		background: 'white',
		height: '48px',
		'& input': {
			py: 1,
		},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'flex-start',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		<FormControl
			error={isInvalid}
			required={isRequired}
			variant='standard'
			sx={{ ...formStyles }}>
			<Box sx={{ display: 'flex', gap: '5px' }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor='tribalId-input'>
					Tribal ID
				</InputLabel>
				{!hideEncrypted && (
					<Copy
						component='span'
						sx={{
							px: 1,
							borderRadius: 0.5,
							backgroundColor: 'secondary.veryLight',
							position: 'relative',
							bottom: '1em',
						}}>
						<IconBase
							size='small'
							icon={LockIcon}
							style={{ verticalAlign: '-0.1em' }}
							aria-label='Your data will be encrypted'
						/>
						{' Encrypted'}
					</Copy>
				)}
			</Box>
			<Box sx={{ display: 'flex', gap: '5px' }}>
				<IMaskInput
					id='tribalId-input'
					value={value}
					type={showTribalId ? 'text' : 'password'}
					error={isInvalid}
					onChange={(e) => onTribalIdChange(e.target.value)}
					sx={{ width: '185px' }}
					InputProps={{
						sx: {
							...inputRootStyles,
						},
						'aria-describedby': isInvalid ? 'TribalId-error-text' : undefined,
						inputProps: {
							'aria-required': isRequired,
							'data-cy': 'input-tribal-id',
							'data-ga-id': 'input_tribal',
						},
					}}
					mask={/^[\w\d\S]*$/}
				/>
				<Button
					variant='text'
					data-cy='show-id-button'
					aria-label={`${showTribalId ? 'Hide' : 'Show'} Tribal ID`}
					sx={{ lineHeight: '24px', fontWeight: '400' }}
					onClick={handleClickShowTribalId}
					onMouseDown={handleMouseDownTribalId}>
					{showTribalId ? 'Hide' : 'Show'}
				</Button>
			</Box>
			{isInvalid && (
				<FormHelperText
					role='alert'
					sx={{ ...messageStyles }}
					id='TribalId-error-text'>
					<ErrorOutlineIcon fontSize='small' />
					{'Please enter at least 2 characters for your Tribal ID'}
				</FormHelperText>
			)}
		</FormControl>
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(TribalIdInput, comparator)
