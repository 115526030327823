import { useEffect } from 'react'

import NavIconWrapper from '../NavIconWrapper'
import { Box } from '@mui/material'
import StepStatus from '&constants/enums/stepStatus'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { useNavigation } from '&hooks/useNavigation'
import ClipboardIcon from '&assets/icons/clipboard.svg'

/**
 * This component houses the navigation/icon progress bar for the application steps.
 * It is a grid of the NavIconWrapper component that shows steps either in the visited, current, or future status.
 */
const NavProgressBar = () => {
	const [{ currentStepIndex, steps }] = useApplicationProcessContext()
	const { goToView } = useNavigation()

	useEffect(() => {
		//Scrolling to the current nav element each time the index changes
		const currentStepObject = steps[currentStepIndex]
		const currentNavElement = document.getElementById(
			`nav-${currentStepObject?.viewName.toLowerCase()}`
		)
		currentNavElement?.scrollIntoView({ inline: 'center', block: 'nearest' })

		document.body.focus()
	}, [currentStepIndex])

	const getStepStatus = (isCompleted, index) => {
		if (index === currentStepIndex) {
			return StepStatus.CURRENT
		} else if (isCompleted) {
			return StepStatus.COMPLETED
		} else {
			return StepStatus.FUTURE
		}
	}

	const isStepDisabled = (isCompleted, index) => {
		// Is there a better way to state this?
		return !(isCompleted || steps[index - 1]?.isCompleted || index === 0)
	}

	return (
		<Box
			id='nav-container'
			data-cy='nav-progress-bar'
			role='navigation'
			aria-label={'Progress Navigation'}
			sx={{
				display: 'grid',
				gridAutoColumns: '50px',
				gridAutoFlow: 'column',
				overflowY: 'auto',
				columnGap: '36px',
				p: 3,
			}}>
			{steps.map((step, i) => (
				<NavIconWrapper
					key={i}
					id={`nav-${step.viewName.toLowerCase()}`}
					//using ClipboardIcon as a placeholder here for instances where this component is mounted without a step
					icon={step.icon || ClipboardIcon}
					text={step.displayName}
					status={getStepStatus(step.isCompleted, i)}
					ariaLabel={`Go to ${getStepStatus(step.isCompleted, i)} step ${
						step.displayName
					}`}
					onClick={() => goToView(step)}
					disabled={isStepDisabled(step.isCompleted, i)}
					data-ga-id={`navigation_${step.displayName}`}
				/>
			))}
		</Box>
	)
}

export default NavProgressBar
