import { Link as MuiLink, useTheme } from '@mui/material'

const Link = ({ newTab, outbound, children, sx, ...args }) => {
	const theme = useTheme()

	return (
		<MuiLink
			{...(newTab && !outbound && { rel: 'noopener noreferrer' })}
			target={newTab && '_blank'}
			tabIndex='0'
			color='veryDark'
			underline='hover'
			sx={{
				fontFamily: 'Lato',
				textDecoration: 'underline',
				fontWeight: 400,
				fontSize: '1em',
				cursor: 'pointer',
				'&:hover': {
					textDecoration: 'none',
				},
				'&:focus-visible': {
					outline: `2px solid ${theme.palette.secondary.dark}`,
					borderRadius: '2px',
				},
				...sx,
			}}
			{...args}>
			{children}
		</MuiLink>
	)
}

export default Link
