/**
 * this function follows the MUI docs for changing spacing (used for padding and margins)
 * dynamically.
 *
 * @param {Number} factor – the value passed in for margin or padding. for example, the factor
 * below would be 4.
 *    sx={{ m: 4 }}
 */
export const setSpacing = (factor) => {
	if (factor === 0) return 0
	else return Math.pow(2, factor + 1)
}
/**
 * this function generates a theme object for use in a ThemeProvider
 *
 * @prop {String} partnerName – the name of the partner
 * @prop {String} logo – path to the logo for the theme
 * @prop {Object} palette – an object containing the colors for the theme
 * @returns {Object} – an MUI theme object
 */
export const generateTheme = (
	{ partnerName, palette } = {
		partnerName: 'esh',
		palette: {},
	}
) => ({
	partnerName,
	spacing: setSpacing,
	shadows: Array(25).fill('none'),
	palette: {
		// almost black
		veryDark: '#2C2C2C',
		dark: '#4A4A4A',
		darker: '#3b3b3b',
		light: '#6e6e6e',
		lighter: '#929292',
		veryLight: '#B7b7b7',
		almostWhite: '#F6F5F1',
		almostYellow: '#FFF1D0',
		primary: {
			lighter: '#FED571',
			light: '#FDC742',
			main: '#FDB913',
			dark: '#CA940f',
		},
		secondary: {
			//teal
			veryLight: '#66BEC0',
			light: '#33A8AB',
			main: '#009296',
			dark: '#007578',
		},
		tertiary: {
			//burnt orange
			light: '#F5894D',
			main: '#F26B21',
			dark: '#C1561A',
		},
		quaternary: {
			//eggplant
			light: '#7F3F66',
			main: '#5F0F40',
			dark: '#4C0C33',
		},
		orange: {
			//orange
			light: '#F3AF4D',
			main: '#f09B21',
			dark: '#C07C1A',
		},
		customError: {
			main: '#A30101',
		},
		...palette,
	},
	typography: {
		fontFamily: 'Lato',
		fontSize: 16,
		lineHeight: '24px',
		allVariants: {
			color: '#2c2c2c',
		},
		h1: {
			fontSize: 31,
			fontWeight: 800,
			lineHeight: '150%',
		},
		h2: {
			fontSize: 25,
			fontWeight: 700,
		},
		h3: {
			fontSize: 20,
			fontWeight: 500,
		},
	},
	components: {
		MuiInputBase: {
			styleOverrides: {
				root: {
					'.MuiInputBase-input': {
						'&::placeholder': {
							color: '#929292',
							opacity: 1,
						},
					},
				},
			},
		},
	},
})
