import { Button } from '..'

/**
 * Back button to use in views. Besides data properties and inner text, args
 * should be no different than the ones used in Button component.
 * @param {object} args - Other props
 */
const BackButton = ({ ...args }) => {
	return (
		<Button data-ga-id='back-button' data-cy='back-btn' {...args}>
			{'Back'}
		</Button>
	)
}

export default BackButton
