export const decimalMap = [
	[
		[null, null, null, null, null, 'NY', 'PR', 'PR', 'VI', 'PR'],
		['MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'MA'],
		['MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'MA', 'RI', 'RI'],
		['NH', 'NH', 'NH', 'NH', 'NH', 'NH', 'NH', 'NH', 'NH', 'ME'],
		['ME', 'ME', 'ME', 'ME', 'ME', 'ME', 'ME', 'ME', 'ME', 'ME'],
		['VT', 'VT', 'VT', 'VT', 'VT', 'MA', 'VT', 'VT', 'VT', 'VT'],
		['CT', 'CT', 'CT', 'CT', 'CT', 'CT', 'CT', 'CT', 'CT', 'CT'],
		['NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ'],
		['NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ', 'NJ'],
		['AE', 'AE', 'AE', 'AE', 'AE', 'AE', 'AE', 'AE', 'AE', 'AE'],
	],
	[
		['NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY'],
		['NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY'],
		['NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY'],
		['NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY'],
		['NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY', 'NY'],
		['PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA'],
		['PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA'],
		['PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA'],
		['PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA'],
		['PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'PA', 'DE', 'DE', 'DE'],
	],
	[
		['DC', 'VA', 'DC', 'DC', 'DC', 'DC', 'MD', 'MD', 'MD', 'MD'],
		['MD', 'MD', 'MD', null, 'MD', 'MD', 'MD', 'MD', 'MD', 'MD'],
		['VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA'],
		['VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA'],
		['VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'VA', 'WV', 'WV', 'WV'],
		['WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV'],
		['WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV', 'WV', null],
		['NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC'],
		['NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC', 'NC'],
		['SC', 'SC', 'SC', 'SC', 'SC', 'SC', 'SC', 'SC', 'SC', 'SC'],
	],
	[
		['GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA'],
		['GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA', 'GA'],
		['FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL'],
		['FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL', 'FL'],
		['AA', 'FL', 'FL', null, 'FL', null, 'FL', 'FL', null, 'FL'],
		['AL', 'AL', 'AL', null, 'AL', 'AL', 'AL', 'AL', 'AL', 'AL'],
		['AL', 'AL', 'AL', 'AL', 'AL', 'AL', 'AL', 'AL', 'AL', 'AL'],
		['TN', 'TN', 'TN', 'TN', 'TN', 'TN', 'TN', 'TN', 'TN', 'TN'],
		['TN', 'TN', 'TN', 'TN', 'TN', 'TN', 'MS', 'MS', 'MS', 'MS'],
		['MS', 'MS', 'MS', 'MS', 'MS', 'MS', 'MS', 'MS', 'GA', 'GA'],
	],
	[
		['KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY'],
		['KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY'],
		['KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', 'KY', null, null],
		['OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH'],
		['OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH'],
		['OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH', 'OH'],
		['IN', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN'],
		['IN', 'KY', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN', 'IN'],
		['MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI'],
		['MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI', 'MI'],
	],
	[
		['IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA'],
		['IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA'],
		['IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', 'IA', null],
		['WI', 'WI', 'WI', null, 'WI', 'WI', null, 'WI', 'WI', 'WI'],
		['WI', 'WI', 'WI', 'WI', 'WI', 'WI', 'WI', 'WI', 'WI', 'WI'],
		['MN', 'MN', null, 'MN', 'MN', 'MN', 'MN', 'MN', 'MN', 'MN'],
		['MN', 'MN', 'MN', 'MN', 'MN', 'MN', 'MN', 'ND', null, null],
		['SD', 'SD', 'SD', 'SD', 'SD', 'SD', 'SD', 'SD', null, null],
		['ND', 'ND', 'ND', 'ND', 'ND', 'ND', 'ND', 'ND', 'ND', null],
		['MT', 'MT', 'MT', 'MT', 'MT', 'MT', 'MT', 'MT', 'MT', 'MT'],
	],
	[
		['IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL'],
		['IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL'],
		['IL', null, 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL', 'IL'],
		['MO', 'MO', null, 'MO', 'MO', 'MO', 'MO', 'MO', 'MO', 'MO'],
		['MO', 'MO', null, null, 'MO', 'MO', 'MO', 'MO', 'MO', 'MO'],
		['MO', 'MO', 'MO', 'MO', 'MO', 'MO', 'MO', 'MO', 'MO', null],
		['KS', 'KS', 'KS', null, 'KS', 'KS', 'KS', 'KS', 'KS', 'KS'],
		['KS', 'KS', 'KS', 'KS', 'KS', 'KS', 'KS', 'KS', 'KS', 'KS'],
		['NE', 'NE', null, 'NE', 'NE', 'NE', 'NE', 'NE', 'NE', 'NE'],
		['NE', 'NE', 'NE', 'NE', null, null, null, null, null, null],
	],
	[
		['LA', 'LA', null, 'LA', 'LA', 'LA', 'LA', 'LA', 'LA', null],
		['LA', 'LA', 'LA', 'LA', 'LA', null, 'AR', 'AR', 'AR', 'AR'],
		['AR', 'AR', 'AR', 'AR', 'AR', 'AR', 'AR', 'AR', 'AR', 'AR'],
		['OK', 'OK', null, 'TX', 'OK', 'OK', 'OK', 'OK', 'OK', 'OK'],
		['OK', 'OK', null, 'OK', 'OK', 'OK', 'OK', 'OK', 'OK', 'OK'],
		['TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX'],
		['TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX'],
		['TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX'],
		['TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX'],
		['TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX', 'TX'],
	],
	[
		['CO', 'CO', 'CO', 'CO', 'CO', 'CO', 'CO', 'CO', 'CO', 'CO'],
		['CO', 'CO', 'CO', 'CO', 'CO', 'CO', 'CO', null, null, null],
		['WY', 'WY', 'WY', 'WY', 'WY', 'WY', 'WY', 'WY', 'WY', 'WY'],
		['WY', 'WY', 'ID', 'ID', 'ID', 'ID', 'ID', 'ID', 'ID', null],
		['UT', 'UT', 'UT', 'UT', 'UT', 'UT', 'UT', 'UT', null, null],
		['AZ', 'AZ', 'AZ', 'AZ', null, 'AZ', 'AZ', 'AZ', null, 'AZ'],
		['AZ', null, null, 'AZ', 'AZ', 'AZ', null, null, null, null],
		['NM', 'NM', 'NM', 'NM', 'NM', 'NM', null, 'NM', 'NM', 'NM'],
		['NM', 'NM', 'NM', 'NM', 'NM', 'TX', null, null, null, 'NV'],
		['NV', 'NV', null, 'NV', 'NV', 'NV', null, 'NV', 'NV', null],
	],
	[
		['CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', null],
		['CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA'],
		['CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', null],
		['CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA'],
		['CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA'],
		['CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA', 'CA'],
		['CA', 'CA', 'AP', 'AP', 'AP', 'AP', 'AP', 'HI', 'HI', 'GU'],
		['OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR', 'OR'],
		['WA', 'WA', 'WA', 'WA', 'WA', 'WA', 'WA', null, 'WA', 'WA'],
		['WA', 'WA', 'WA', 'WA', 'WA', 'AK', 'AK', 'AK', 'AK', 'AK'],
	],
]

export const getStateFromZip = (zip) => {
	const first3 = zip.substring(0, 3)
	const regexPass = /^[0-9]+$/.test(first3) // check for digits only
	if (regexPass) return decimalMap[first3[0]][first3[1]][first3[2]]
	else return null
}
