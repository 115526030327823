// local imports
import { Box } from '@mui/material'
import {
	DropdownInput,
	StringInput,
	ZipCodeInput,
} from '&components/FormInputs'
import {
	getStateOrTerritoryList,
	getStateOrTerritoryObject,
} from '&utils/dropdownConstants'
import Header from '&components/Header'
import { useWeglot } from '&state/weglot/context'

/*
 * Reusable component for address input.
 *
 * @param {boolean} isStreetInvalid – whether the street is invalid
 * @param {boolean} isCityInvalid – whether the city/town is invalid
 * @param {boolean} isStateInvalid – whether the state/territory is invalid
 * @param {boolean} isZipInvalid – whether the zip code is invalid
 * @param {function} onStreetChange – callback function to handle changes to the street
 * @param {function} onAptChange – callback function to handle changes to the apt/unit
 * @param {function} onCityChange – callback function to handle changes to the city/town
 * @param {function} onStateChange – callback function to handle changes to the state/territory
 * @param {function} onZipChange – callback function to handle changes to the zip code
 * @param {string} [streetValue] – (optional) the value of the street input if the input is controlled
 * @param {string} [aptValue] – (optional) the value of the apt/unit input if the input is controlled
 * @param {string} [cityValue] – (optional) the value of the city/town input if the input is controlled
 * @param {string} [stateValue] – (optional) the value of the state input if the input is controlled
 * @param {string} [zipValue] – (optional) the value of the zip code input if the input is controlled
 * @param {boolean} [showTitle=false] - (optional) whether to show the "Address" title
 * @param {boolean} [addressRequired=false] - (optional) boolean to determine if the address section is required (adds * after title)
 * @param {object} [sx={}] – (optional) optional styles to pass to the addressInput container
 */
const AddressInput = ({
	isStreetInvalid,
	isCityInvalid,
	isStateInvalid,
	isZipInvalid,
	onStreetChange,
	onAptChange,
	onCityChange,
	onStateChange,
	onZipChange,
	streetValue,
	aptValue,
	cityValue,
	stateValue,
	zipValue,
	showTitle = false,
	addressRequired = false,
	sx = {},
	...args
}) => {
	const [{ currentLanguage }] = useWeglot()
	const stateOrTerritoryList = getStateOrTerritoryList(currentLanguage)

	return (
		<Box sx={{ ...sx }} {...args}>
			{showTitle && (
				<Header component='h3' sx={{ fontWeight: 700, mb: 3 }}>
					{'Address'}
					{addressRequired && '*'}
				</Header>
			)}
			<StringInput
				value={streetValue}
				isInvalid={isStreetInvalid}
				name='address-line1' // for autocomplete
				autoComplete='address-line1'
				isRequired
				onStringChange={onStreetChange}
				stringLabel={'Street'}
				stringErrorText={'Please enter a valid street address'}
				id='address-line1'
				data-cy='input-address-line1'
				data-ga-id='input_street'
				sx={{ display: 'block' }}
			/>
			<StringInput
				value={aptValue}
				stringLabel={'Apt, Unit, etc.'}
				isRequired={false}
				onStringChange={onAptChange}
				name='address-line2' // for autocomplete
				autoComplete='address-line2'
				sx={{ display: 'block' }}
				id='address-line2'
				data-cy='input-address-line2'
				data-ga-id='input_apt'
			/>
			<StringInput
				value={cityValue}
				isInvalid={isCityInvalid}
				isRequired
				onStringChange={onCityChange}
				stringLabel={'City or Town'}
				stringErrorText={'Please enter a valid city or town'}
				id='city'
				name='address-level2' // for autocomplete
				autoComplete='address-level2'
				data-cy='input-city'
				data-ga-id='input_city'
				sx={{ display: 'block' }}
			/>
			<DropdownInput
				isInvalid={isStateInvalid}
				isRequired
				onDropdownChange={onStateChange}
				dropdownLabel={'State or Territory'}
				dropdownErrorText={'Please select a state or territory'}
				options={stateOrTerritoryList}
				value={getStateOrTerritoryObject(stateValue, stateOrTerritoryList)}
				autocompleteArgs={{
					ListboxProps: { className: 'weglot_exclude' },
				}}
				id='state'
				data-cy='input-state'
				data-ga-id='input_state'
				name='address-level1' // for autocomplete
				autoComplete='address-level1'
				sx={{ display: 'block' }}
			/>
			<ZipCodeInput
				isInvalid={isZipInvalid}
				isRequired
				onZipChange={onZipChange}
				value={zipValue}
			/>
		</Box>
	)
}

export default AddressInput
