// react imports
import {
	createContext,
	useContext,
	useState,
	useEffect,
	useReducer,
} from 'react'
// local imports
import useExperiment from '&hooks/useExperiment'
import weglotReducer from './reducer'
import { setWeglotEnabledAction, setCurrentLanguage } from './actions'
import { LanguageCodes } from '&constants/enums/languages'

const WeglotContext = createContext()

/**
 * This provider adds the Weglot script to the <head> of the DOM, and sets state enabled/disabled
 * Weglot initialization docs: https://developers.weglot.com/javascript-api
 */
const WeglotProvider = ({ children }) => {
	const { featureValue: enableWeglot } = useExperiment('v2-enable-weglot')
	const [addedInitScript, setAddedInitScript] = useState(false)
	const initialState = {
		enabled: false,
		currentLanguage: LanguageCodes.EN,
	}

	const [state, dispatch] = useReducer(weglotReducer, initialState)

	const Weglot = window.Weglot

	// Dynamically load Weglot script based on the growthbook flag
	useEffect(() => {
		// we want to async load Weglot script, wait for it to load, then initialize it
		const addWeglot = async () => {
			const addWeglotScript = async () => {
				return new Promise((resolve, reject) => {
					const script = document.createElement('script')
					script.src = 'https://cdn.weglot.com/weglot.min.js'
					script.setAttribute('async', true)
					script.onload = () => {
						resolve()
					}
					script.onerror = () => {
						reject()
					}
					document.head.appendChild(script)
				})
			}
			const addWeglotInit = () => {
				const script = document.createElement('script')
				// initialize Weglot with the API key and extra definitions to account for aria-labels (please add to the list if you find missing ones)
				const inlineScript = document.createTextNode(`
						Weglot.initialize({
							api_key: 'wg_217a2d593a1040fde0b3a83e43bb51064',
							extra_definitions: [
								{
									type: 1,
									selector: "a, aside, button, canvas, dialog, div, embed, fieldset, figure, form, iframe, img, input, link, map, nav, object, search, section, span, svg, table, textarea, video",
									attribute: "aria-label",
								}
							],
							hide_switcher: true
						});
					`)
				script.appendChild(inlineScript)

				document.head.appendChild(script)
			}
			try {
				await addWeglotScript()
				addWeglotInit()
				setAddedInitScript(true)
			} catch (e) {
				console.error('Error loading Weglot:', e)
				dispatch(setWeglotEnabledAction(false))
			}
		}
		// only call the addWeglot function if the feature flag is enabled
		if (enableWeglot) {
			addWeglot()
		}
	}, [enableWeglot])

	useEffect(() => {
		/* When Weglot is initialized, we know for sure that it is enabled and we can 
		set the default language */
		if (addedInitScript) {
			Weglot.on('initialized', () => {
				dispatch(setWeglotEnabledAction(enableWeglot))
				dispatch(setCurrentLanguage(Weglot.getCurrentLang()))
			})
			// Every time we update the language, we store it in state
			Weglot.on('languageChanged', (newLang) => {
				dispatch(setCurrentLanguage(newLang))
			})
		}
	}, [addedInitScript])

	return (
		<WeglotContext.Provider value={[state, dispatch]}>
			{children}
		</WeglotContext.Provider>
	)
}

const useWeglot = () => {
	const context = useContext(WeglotContext)
	if (context === undefined) {
		throw new Error('useWeglot must be used within a WeglotProvider')
	}
	return context
}

export { WeglotContext, WeglotProvider, useWeglot }
