// react imports
import { useCallback, useState, useEffect, useRef } from 'react'
// local imports
import { Button, ButtonGrid, IconBase, Header, BackButton } from '&components'
import { Box } from '@mui/material'
import { useSelection } from '&state/selection/context'
import {
	AddressInput,
	DOBInput,
	EmailInput,
	PhoneInput,
	SSNInput,
	StringInput,
	TribalIdInput,
} from '&components/FormInputs'
import {
	validateDay,
	validateEmail,
	validateMonth,
	validateName,
	validatePhone,
	validateSSN,
	validateYear,
	validateZipcode,
	validateAge,
	validateTribalId,
} from '&utils/validation'
import { setSelectionStateAction } from '&state/selection/actions'
import EditIcon from '&assets/icons/edit.svg'
import useNavigation from '&hooks/useNavigation'
import { getIDType } from '&utils/identification'
import { IDTypes } from '&constants/enums/pathAndId'
import useExperiment from '&hooks/useExperiment'

/**
 * Reusable component for editing user info
 *
 * @param {function} toggleEditMode – (optional) callback function to toggle edit mode (used in the Review view)
 * @param {boolean} [isContained=false] – (optional) boolean to determine if the contact info section is visible and
 * how some other style properties are managed
 * @param {boolean} [showContact=true] – (optional) boolean to determine if the contact info section is visible
 * @param {string} nameTitle – (optional) the title for the name section of the form
 * @param {string} DOBTitleFontWeight – (optional) the font weight for the DOB title
 * @param {boolean} [addressRequired=false] – (optional) boolean to determine if the address section is required (adds * after title)
 * @param {string} [saveButtonText='Save'] – (optional) the text for the save button
 * @param {function} [submitFunction=undefined] – (optional) a function to be executed after saving changes
 * @param {any} inputFooter - (optional) A footer component to be displayed above the action buttons
 * @param {object} [sx={}] – (optional) optional styles to pass to the editInfoInput container
 */
const EditInfoInput = ({
	toggleEditMode = undefined,
	isContained = false,
	showContact = true,
	nameTitle,
	DOBTitleFontWeight,
	addressRequired = false,
	saveButtonText = 'Save',
	submitFunction = undefined,
	inputFooter,
	sx = {},
}) => {
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [areInputsValid, setAreInputsValid] = useState(false)
	const [hasInitialState, setHasInitialState] = useState(false)

	// we need to prefill the form if the user has already entered information before
	const [
		{
			name: { firstName: storedFirstName, lastName: storedLastName },
			dob: { month: storedMonth, day: storedDay, year: storedYear },
			phone: storedPhone,
			email: storedEmail,
			address: {
				street: storedStreet,
				aptUnit: storedAptUnit,
				city: storedCity,
				stateCode: storedStateCode,
				zipCode: storedZipCode,
			},
			ssn: storedSSN,
			tribalId: storedTribalId,
			alternateIdIndicator,
		},
		selectionDispatch,
	] = useSelection()
	const { goBackward } = useNavigation()

	const [formState, setFormState] = useState({
		firstName: storedFirstName,
		lastName: storedLastName,
		day: storedDay,
		month: storedMonth,
		year: storedYear,
		phone: storedPhone,
		email: storedEmail,
		street: storedStreet,
		aptUnit: storedAptUnit,
		city: storedCity,
		stateCode: storedStateCode,
		zipCode: storedZipCode,
		ssn: storedSSN,
		tribalId: storedTribalId,
		firstNameInvalid: false,
		lastNameInvalid: false,
		dayInvalid: false,
		monthInvalid: false,
		yearInvalid: false,
		ageInvalid: false,
		phoneInvalid: false,
		emailInvalid: false,
		streetInvalid: false,
		cityInvalid: false,
		stateCodeInvalid: false,
		zipCodeInvalid: false,
		ssnInvalid: false,
		tribalIdInvalid: false,
	})

	const hasStateMutated = useRef(false)

	const { featureValue: enableLifelineTribal } = useExperiment(
		'v2-enable-lifelinetribal'
	)

	useEffect(() => {
		if (hasSubmitted && areInputsValid && hasStateMutated) {
			if (submitFunction) submitFunction()
			if (toggleEditMode) toggleEditMode(false)
			setHasSubmitted(false)
		}
	}, [hasSubmitted, areInputsValid, hasStateMutated])

	const functionCallDeps = [
		storedFirstName,
		storedLastName,
		storedPhone,
		storedEmail,
		storedStreet,
		storedAptUnit,
		storedCity,
		storedStateCode,
		storedZipCode,
		storedSSN,
		storedTribalId,
	]

	useEffect(() => {
		if (hasInitialState) {
			hasStateMutated.current = true
		} else {
			setHasInitialState(true)
		}
	}, functionCallDeps)

	const currIdType = getIDType({
		ssn: storedSSN,
		tribalId: storedTribalId,
		alternateIdIndicator,
	})

	const setFormValue = useCallback((key, value) => {
		setFormState((prevState) => ({
			...prevState,
			[key]: value,
		}))
	}, [])

	/* function to validate and Clean each input, we return an object with two properties:
		1. cleanedValue: if the value happens to be a string, we trim the whitespaces at each end and return in
		2. isValid: boolean value of whether is valid or not 
	*/
	const validateCleanField = (key, validationFunction) => {
		const value =
			typeof formState[key] === 'string'
				? formState[key].trim()
				: formState[key]
		const isValid = validationFunction(value)
		setFormValue(`${key}Invalid`, !isValid)

		// We set state anyway in case the value does NOT pass the validation, we still want it to be trimmed for the user
		formState[key] !== value && setFormValue(key, value)

		return { cleanedValue: value, isValid }
	}

	// function to validate appropriate age to make sure user is 13 or older
	const validateAgeField = (youngDOB, validationFunction) => {
		const isValid = validationFunction(youngDOB)
		setFormValue('ageInvalid', !isValid)
		return isValid
	}

	const handleSave = () => {
		const { cleanedValue: firstName, isValid: validFirstName } =
			validateCleanField('firstName', validateName)
		const { cleanedValue: lastName, isValid: validLastName } =
			validateCleanField('lastName', validateName)
		const { cleanedValue: day, isValid: validDay } = validateCleanField(
			'day',
			validateDay
		)
		const { cleanedValue: month, isValid: validMonth } = validateCleanField(
			'month',
			validateMonth
		)
		const { cleanedValue: year, isValid: validYear } = validateCleanField(
			'year',
			validateYear
		)
		const { cleanedValue: phone, isValid: validPhone } = validateCleanField(
			'phone',
			validatePhone
		)
		const { cleanedValue: email, isValid: validEmail } = validateCleanField(
			'email',
			validateEmail
		)
		const { cleanedValue: street, isValid: validStreet } = validateCleanField(
			'street',
			(value) => Boolean(value)
		)
		const { cleanedValue: city, isValid: validCity } = validateCleanField(
			'city',
			(value) => Boolean(value)
		)
		const { cleanedValue: stateCode, isValid: validState } = validateCleanField(
			'stateCode',
			(value) => Boolean(value)
		)
		const { cleanedValue: zipCode, isValid: validZipCode } = validateCleanField(
			'zipCode',
			validateZipcode
		)
		const { cleanedValue: ssn, isValid: validSSN } = validateCleanField(
			'ssn',
			validateSSN
		)
		const { cleanedValue: tribalId, isValid: validTribalId } =
			validateCleanField('tribalId', validateTribalId)
		const isThirteenOrOlder = validateAgeField(
			{
				month: formState.month,
				day: formState.day,
				year: formState.year,
			},
			validateAge
		)
		const aptUnit = formState.aptUnit.trim()

		const validContact = validEmail || validPhone || !showContact

		// the user can store multiple id values on the Identification screen, we need to validate the one that is currently selected
		const getValidId = () => {
			let validId = false

			switch (currIdType) {
				case IDTypes.SSN:
					validId = validSSN
					break
				case IDTypes.TRIBAL:
					validId = validTribalId
					break
				case IDTypes.ID:
					validId = true
					break
				default:
					validId = false
			}

			return validId
		}
		const validId = getValidId()

		if (
			validFirstName &&
			validLastName &&
			validMonth &&
			validDay &&
			validYear &&
			validContact &&
			validStreet &&
			validCity &&
			validState &&
			validZipCode &&
			validId &&
			isThirteenOrOlder
		) {
			setAreInputsValid(true)
			const normalizedDay = day.length === 1 ? `0${day}` : day

			selectionDispatch(
				setSelectionStateAction({
					street: street,
					aptUnit: aptUnit,
					city: city,
					stateCode: stateCode,
					zipCode: zipCode,
					phone: validPhone ? phone : '',
					email: validEmail ? email : '',
					firstName: firstName,
					lastName: lastName,
					day: normalizedDay,
					month: month,
					year: year,
					ssn: validSSN ? ssn : '',
					tribalId: validTribalId ? tribalId : '',
				})
			)
			setHasSubmitted(true)
		}
	}

	const paddingStyles = isContained ? { pb: 0, p: 3 } : { pb: 4 }

	// used to reset too young age error so that the modal can be opened multiple times
	const resetError = () => setFormValue('ageInvalid', false)

	//TODO: simplify this when removing 'v2-enable-lifelinetribal'
	const usingSSN = Boolean(!enableLifelineTribal || currIdType === IDTypes.SSN)

	return (
		<Box sx={{ py: 2, ...sx }}>
			<div role='form' style={{ borderRadius: '10px', overflow: 'hidden' }}>
				<Box
					sx={{
						backgroundColor: isContained ? 'almostWhite' : 'transparent',
						...paddingStyles,
					}}>
					<div
						style={{
							display: 'inline-flex',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						<Header component='h3' sx={{ fontWeight: 700, mb: 3 }}>
							{nameTitle}
						</Header>
						{isContained && (
							<Button
								variant='text'
								onClick={handleSave}
								startIcon={<IconBase size='small' icon={EditIcon} />}
								sx={{
									width: '60px',
									height: '24px',
									fontWeight: 400,
									py: 0,
									px: 0,
									'&:hover, &:focus': {
										color: 'veryDark',
										backgroundColor: 'unset',
										textDecoration: 'underline',
									},
								}}>
								{'Save'}
							</Button>
						)}
					</div>
					{/* first name */}
					<StringInput
						isInvalid={formState.firstNameInvalid}
						isRequired
						onStringChange={(value) => setFormValue('firstName', value)}
						value={formState.firstName}
						stringLabel={'Legal First Name'}
						stringErrorText={
							'Please enter at least one letter for your first name'
						}
						id='given-name'
						data-cy='input-given-name'
						data-ga-id='input_first-name'
						autoComplete='given-name'
						name='given-name'
					/>
					{/* last name */}
					<StringInput
						isInvalid={formState.lastNameInvalid}
						isRequired
						onStringChange={(value) => setFormValue('lastName', value)}
						value={formState.lastName}
						stringLabel={'Legal Last Name or Family Name'}
						stringErrorText={
							'Please enter at least one letter for your last or family name'
						}
						id='family-name'
						data-cy='input-family-name'
						data-ga-id='input_last-name'
						autoComplete='family-name'
						name='family-name'
						sx={{ pb: isContained ? 3 : 4 }}
					/>
					{/* DOB */}
					<DOBInput
						isDayInvalid={formState.dayInvalid}
						isMonthInvalid={formState.monthInvalid}
						isYearInvalid={formState.yearInvalid}
						isTooYoung={formState.ageInvalid}
						onMonthChange={(value) => setFormValue('month', value)}
						onDayChange={(value) => setFormValue('day', value)}
						onYearChange={(value) => setFormValue('year', value)}
						dayValue={formState.day}
						monthValue={formState.month}
						yearValue={formState.year}
						resetError={resetError}
						sx={{ fontWeight: DOBTitleFontWeight }}
					/>
				</Box>
				{showContact && (
					<Box
						sx={{
							backgroundColor: 'almostYellow',
							p: 3,
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Header component='h3' sx={{ fontWeight: 700, mb: 3 }}>
							{'Contact'}
						</Header>
						<EmailInput
							isInvalid={formState.emailInvalid && formState.phoneInvalid}
							isRequired
							onEmailChange={(value) => setFormValue('email', value)}
							value={formState.email}
						/>
						<PhoneInput
							isInvalid={formState.phoneInvalid && formState.emailInvalid}
							isRequired
							onPhoneChange={(value) => setFormValue('phone', value)}
							value={formState.phone}
						/>
					</Box>
				)}
				<AddressInput
					showTitle
					addressRequired={addressRequired}
					isStreetInvalid={formState.streetInvalid}
					isCityInvalid={formState.cityInvalid}
					isStateInvalid={formState.stateCodeInvalid}
					isZipInvalid={formState.zipCodeInvalid}
					onStreetChange={(value) => setFormValue('street', value)}
					onAptChange={(value) => setFormValue('aptUnit', value)}
					onCityChange={(value) => setFormValue('city', value)}
					onStateChange={(value) => setFormValue('stateCode', value)}
					onZipChange={(value) => setFormValue('zipCode', value)}
					streetValue={formState.street}
					aptValue={formState.aptUnit}
					cityValue={formState.city}
					stateValue={formState.stateCode}
					zipValue={formState.zipCode}
					sx={{
						backgroundColor: isContained ? 'almostWhite' : 'transparent',
						display: 'flex',
						flexDirection: 'column',
						...paddingStyles,
					}}
				/>
				{/* Identification */}
				{!alternateIdIndicator && (
					<Box
						sx={{
							backgroundColor: isContained ? '#FFF1D0' : 'transparent',
							display: 'flex',
							flexDirection: 'column',
							...paddingStyles,
						}}>
						<Header component='h3' sx={{ fontWeight: 700, mb: 3 }}>
							{'Identification'}
						</Header>
						{usingSSN ? (
							<SSNInput
								isInvalid={formState.ssnInvalid}
								onSSNChange={(value) => setFormValue('ssn', value)}
								isRequired={true}
								value={formState.ssn}
								hideEncrypted
							/>
						) : (
							<TribalIdInput
								isInvalid={formState.tribalIdInvalid}
								onTribalIdChange={(value) => setFormValue('tribalId', value)}
								value={formState.tribalId}
								hideEncrypted
							/>
						)}
					</Box>
				)}
			</div>
			{inputFooter}
			<ButtonGrid sx={{ px: 3 }}>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<Button onClick={handleSave} isCondensed>
					{`${saveButtonText}`}
				</Button>
			</ButtonGrid>
		</Box>
	)
}

export default EditInfoInput
