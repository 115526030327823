import { Container } from '@mui/material'
import { useApplicationProcessContext } from '&state/applicationProcess/context'

/**
 * component for displaying a single chunk of the linear progress bar
 *
 * @prop {boolean} isCompleted – whether the chunk is completed or not
 */
const LinearChunk = ({ isCompleted = false }) => {
	return (
		<Container
			sx={{
				backgroundColor: isCompleted ? 'secondary.light' : 'almostWhite',
				height: '5px',
				width: '100%',
			}}
		/>
	)
}

/**
 * component for displaying a linear progress bar
 *
 * @prop {boolean} isCompleted – whether the progress bar is completed or not
 */
const LinearProgressBar = ({ isCompleted: isCompletedOverride = false }) => {
	const [{ currentStepIndex, steps }] = useApplicationProcessContext()

	const totalChunks = steps.length

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(6, 1fr)',
				gridTemplateRows: '1fr',
				gridColumnGap: '5px',
			}}>
			{[...Array(totalChunks)].map((_, i) => {
				const isCompleted =
					isCompletedOverride || (i <= currentStepIndex ? true : false)
				return <LinearChunk key={i} isCompleted={isCompleted} />
			})}
		</div>
	)
}

export default LinearProgressBar
