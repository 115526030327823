export const setApplicationStepsAction = (steps) => ({
	type: 'SET_APPLICATION_STEPS',
	payload: steps,
})

export const setCurrentApplicationStepAction = (step) => ({
	type: 'SET_CURRENT_APPLICATION_STEP',
	payload: step,
})

export const setPathAction = (path) => ({
	type: 'SET_PATH',
	payload: path,
})

export const setResultAction = (result) => ({
	type: 'SET_RESULT',
	payload: result,
})
