import { useNVResponse } from '&state/nationalVerifier/context'
import config from '&config'
import { setApplicationStatusAction } from '&state/nationalVerifier/actions'
import { ApiStatus } from '&constants/enums/api'
import useNavigation from '&hooks/useNavigation'
//views
import { ApprovedView } from '&views/Approved'
import { NotQualifiedView } from '&views/NotQualified'
import { UnderReviewView } from '&views/UnderReview'

/**
 * This hook is used to redirect the user to USAC's website based on the link in NVResponseContext
 */
const useNVRedirect = () => {
	const [{ uuid, link }, nvResponseDispatch] = useNVResponse()
	const { goToView } = useNavigation()

	const openNVCertifyLink = () => {
		// Open NV certify link in new tab
		const nvTab = window.open(link)

		// Start listening for events from the callback tab indicating a user has
		// finished the NV leg of their application
		const channel = new BroadcastChannel(config.nvCallbackBroadcastChannel)

		channel.onmessage = (message) => {
			if (
				message.data.type === config.nvRedirectMessageName &&
				message.data.uuid === uuid
			) {
				nvTab?.close()
				channel.close()
				nvResponseDispatch(
					setApplicationStatusAction(message.data.eligibilityStatus)
				)
				if (message.data.eligibilityStatus.status === ApiStatus.COMPLETE) {
					// if application is complete
					goToView(ApprovedView)
				} else if (
					message.data.eligibilityStatus.status === ApiStatus.IN_PROGRESS ||
					message.data.eligibilityStatus.status === ApiStatus.PENDING_REVIEW
				) {
					// if application is under review
					goToView(UnderReviewView)
				} else {
					goToView(NotQualifiedView)
				}
			}
		}
	}

	return {
		openNVCertifyLink,
	}
}

export default useNVRedirect
