import { Box } from '@mui/material'
import { useTheme } from '@emotion/react'

/**
 * This component is meant to be used to display important information at the top of the page.
 *
 * @prop {boolean} sticky - If true, the banner will stick to the top of the page
 * @prop {any} children - The contents of the Banner
 * @prop {any} args - Other arguments
 */
const Banner = ({ sticky = false, children, ...args }) => {
	const theme = useTheme()
	return (
		<Box
			data-cy='top-banner'
			sx={{
				position: sticky ? 'sticky' : 'relative',
				top: 0,
				p: 3,
				zIndex: 99,
				backgroundColor: 'almostWhite',
				borderTop: `solid 4px ${theme.palette.customError.main}`,
			}}
			{...args}>
			{children}
		</Box>
	)
}

export default Banner
