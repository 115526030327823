/**
 * ComposeComponents allows you to provide an array of components to be used in your app, rather
 * than having to write out each provider individually.
 *
 * The initial use case for this component was to combine application providers. This can
 * dramatically clean up applications with numerous providers.
 *
 * @param {array} components – an array of components to compose, in hierarchical order, with the
 * element in components[0] being the outermost parent, and so on
 * @param {React.Element} children – the child react component to render within our components
 */
export const ComposeComponents = ({ components = [], children = <></> }) => {
	return components.reverse().reduce((child, Component) => {
		return <Component>{child}</Component>
	}, children)
}
