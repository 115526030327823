// react imports
import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
// general imports
import {
	Button,
	Copy,
	Header,
	Layout,
	NeedHelp,
	EditAddress,
	IconBase,
	ButtonGrid,
} from '&components'
import { Box } from '@mui/material'
import { FailureCodes } from '&constants/enums/api'
import useNVRedirect from '&hooks/useNVRedirect'
import { useNVResponse } from '&state/nationalVerifier/context'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { PathValues } from '&constants/enums/pathAndId'
import getErrorDict from './errorDict'
import useReCaptcha from '&hooks/useReCaptcha'
import config from '&config'
//icons
import ClipboardIcon from '&assets/icons/clipboard.svg'
import LeavePageIcon from '&assets/icons/arrow45degree.svg'
import useNavigation from '&hooks/useNavigation'
import useFetchEligibility from '&hooks/useFetchEligibility'

// Button to continue on USAC's website
export const USACErrorButton = ({ nvLink }) => (
	<Button
		data-ga-id='continue-on-usacs-website'
		isCondensed
		role='link'
		data-cy='usac-link'
		endIcon={<IconBase size='small' icon={LeavePageIcon} />}
		onClick={nvLink}>
		Continue on USAC’s website
	</Button>
)

/**
 * Consolidated error component, which displays the appropriate error page based on the status code and errorDict.
 * Will not render until currentError is set
 */
const Error = () => {
	// react hooks
	const [currentError, setCurrentError] = useState('')
	const [isPageLoading, setIsPageLoading] = useState(false)
	// third party hooks
	const theme = useTheme()

	// custom hooks
	const { goToView } = useNavigation()
	const [{ failures, error }] = useNVResponse()
	const { openNVCertifyLink } = useNVRedirect()
	const { executeRecaptcha } = useReCaptcha()
	const [{ pathName }] = useApplicationProcessContext()
	const {
		loading: eligibilityLoading,
		callEligibilityCheck,
		destination: destinationView,
	} = useFetchEligibility()

	const backgroundColor = theme.palette.secondary.main

	useEffect(() => {
		// if the call is loading, set the page to load
		if (eligibilityLoading) setIsPageLoading(true)
	}, [eligibilityLoading])

	useEffect(() => {
		//listen for a destination, set in useFetchEligibility, then go to it
		if (destinationView && !eligibilityLoading) {
			goToView(destinationView)

			//reset page in case the error page loads again
			setIsPageLoading(false)
		}
	}, [destinationView, eligibilityLoading])

	useEffect(() => {
		if (error) {
			//set network error
			setCurrentError('network')
		} else if (failures?.length > 0) {
			//set Error based on failures in array, if array exists
			if (failures.length > 1) {
				setCurrentError('multiple')
			} else {
				setCurrentError(failures[0])
			}
		} else {
			//keep/set default error
			setCurrentError('default')
		}
	}, [failures, error])

	const errorDict = getErrorDict({ nvLink: openNVCertifyLink })
	const { ErrorImage, errorName, title, blurb, ErrorButton } =
		errorDict[currentError] || errorDict['default']

	//booleans
	//if the below get more complex, consider adding them to the ErrorDict

	//whether to show address component (uneditable) above the button
	const showAddress1 =
		Boolean(currentError === FailureCodes.INVALID_ADDRESS) || false
	//whether to show address component (editable) below the button
	const showAddress2 =
		Boolean(currentError === FailureCodes.DUPLICATE_ADDRESS) || false

	//only show progress bars if the path is not status check
	const showProgressBars = Boolean(pathName !== PathValues.STATUS_CHECK)

	// handles submit for the EditAddress component for DUPLICATE_ADDRESS error
	const handleSubmit = async () => {
		//get new captcha token
		const token = await executeRecaptcha(
			config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
		)
		await callEligibilityCheck(token)
	}

	return (
		currentError && (
			<Layout
				title={errorName}
				topBarText={'Result'}
				isLoading={isPageLoading}
				showProgressBars={showProgressBars}
				{...backgroundColor}>
				<Box sx={{ display: 'flex', justifyContent: 'center', pb: 4, pt: 3 }}>
					<ErrorImage
						aria-hidden='true'
						style={{ height: theme.spacing(5.6) }}
					/>
				</Box>
				<Header
					component='h2'
					data-cy={`${currentError.toLowerCase()}-header`}
					sx={{ fontSize: '31px', fontWeight: 800, lineHeight: '150%' }}>
					{title}
				</Header>
				<Copy data-cy='error-blurb' sx={{ mt: 3, mb: 0 }}>
					{blurb}
				</Copy>
				{showAddress1 && <EditAddress sx={{ my: 3 }} />}
				{ErrorButton && <ButtonGrid sx={{ mt: 4 }}>{ErrorButton}</ButtonGrid>}
				{showAddress2 && (
					<EditAddress
						isEditable
						onSubmit={handleSubmit}
						header={
							<Copy sx={{ mt: 5 }}>
								{`If you believe you entered the wrong address, you can edit and resubmit the application. `}
							</Copy>
						}
					/>
				)}
				<NeedHelp sx={{ mt: 'calc(30px + 64px)' }} />
			</Layout>
		)
	)
}

export default Error

export const ErrorView = {
	icon: ClipboardIcon,
	displayName: 'Result',
	component: Error,
	viewName: 'consolidated-error',
}
