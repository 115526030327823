// external providers
import { CssBaseline } from '@mui/material'
// providers we modify
import ThemeProvider from './state/theme/provider'
import GrowthBookProvider from './state/growthbook/provider'
// providers from local contexts
import { ApplicationProcessProvider } from './state/applicationProcess/context'
import { ComposeComponents as AppProviders } from './utils/reactUtils'
import { InitProvider } from './state/init/context'
import { NVProvider } from './state/nationalVerifier/context'
import { NavigationProvider } from './state/navigation/context'
import { SelectionProvider } from './state/selection/context'
import { ReCaptchaProvider } from './state/reCaptcha/provider'
import { HubSpotChatProvider } from './state/hubSpotChat/context'
import { WeglotProvider } from './state/weglot/context'

/**
 * This HoC encapsulates all of our providers for a cleaner App.js
 */
const Providers = ({ children }) => {
	const appProviders = [
		NavigationProvider,
		CssBaseline,
		InitProvider,
		ApplicationProcessProvider,
		SelectionProvider,
		GrowthBookProvider,
		ThemeProvider,
		NVProvider,
		ReCaptchaProvider,
		HubSpotChatProvider,
		WeglotProvider,
	]
	return <AppProviders components={appProviders}>{children}</AppProviders>
}

export default Providers
