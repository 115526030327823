import IconBase from '../IconBase'
import Copy from '../Copy'
import { alpha, IconButton, useTheme } from '@mui/material'
import Checkmark from '../../assets/icons/checkmark.svg'

/**
 * NavIconWrapper is the wrapper component for the icons in the navigation bar.
 * It renders the icon, background, and text, and styles them based on the status prop.
 * Please translate all passed text parameters.
 *
 * @param {object} icon - The icon to be rendered.
 * @param {string} description - The text to be rendered and used in the aria-label.
 * @param {function} [onCheck] - The function to be called when the item is checked.
 * @param {string} ariaLabel - The aria-label for the icon (translate in parent).
 * */

const MultiSelectButton = ({
	icon,
	value,
	onCheck = () => {},
	isChecked,
	ariaLabel,
	dataGaId,
	dataCy,
}) => {
	const theme = useTheme()
	const buttonStyles = {
		width: '110px',
		padding: '0',
		aspectRatio: '1',
		borderRadius: '100%',
		border: 'none',
		backgroundColor: 'almostWhite',
		'&[aria-checked=true]': {
			backgroundColor: 'primary.lighter',
		},
		'&[aria-checked=false]': {
			backgroundColor: 'almostWhite',
		},
		'&:hover, &:focus-visible': {
			outline: `solid 2px ${theme.palette.secondary.main}`,
			boxShadow: `0px 0px 10px ${alpha(theme.palette.secondary.main, 0.5)}`,
		},
	}

	const checkmarkStyles = {
		position: 'absolute',
		top: '1px',
		right: '0',
		width: '32px',
		height: '32px',
		pointerEvents: 'none',
	}

	const handleCheck = () => {
		onCheck(value, !isChecked)
	}

	return (
		<div
			style={{
				display: 'flex',
				gap: '8px',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<IconButton
				disableRipple
				role='checkbox'
				aria-label={ariaLabel}
				aria-checked={isChecked}
				data-ga-id={dataGaId}
				data-cy={dataCy}
				onClick={handleCheck}
				sx={{
					...buttonStyles,
				}}>
				<IconBase
					sx={{ pointerEvents: 'none' }}
					icon={icon}
					aria-hidden='true'
				/>
				{isChecked === true && (
					<IconBase
						icon={Checkmark}
						size='small'
						sx={checkmarkStyles}
						data-cy={`${dataCy}-checkmark`}
					/>
				)}
			</IconButton>
			{/* Hidden text so that screen readers get only one source of description */}
			<Copy aria-hidden='true' center sx={{ mb: 0, maxWidth: '120px' }}>
				{value}
			</Copy>
		</div>
	)
}

export default MultiSelectButton
