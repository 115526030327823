import { SET_WEGLOT_ENABLED, SET_CURRENT_LANGUAGE } from './actions'

/**
 * a reducer to reflect the state of Weglot
 */
const weglotReducer = (state, action) => {
	switch (action.type) {
		case SET_WEGLOT_ENABLED: {
			const { enabled } = action.payload

			return { ...state, enabled }
		}
		case SET_CURRENT_LANGUAGE: {
			const { language } = action.payload

			return { ...state, currentLanguage: language }
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default weglotReducer
