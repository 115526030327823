import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { getTheme } from '../../utils/branding'

/**
 * This provider handles the logic for setting the theme.
 * It stands on its own due to the need to encompass it
 * within the bounds of other providers, which might provide
 * necessary functions.
 */
export const ThemeProvider = ({ children }) => {
	const { partner } = useParams()
	const [theme, setTheme] = useState(getTheme(partner))

	useEffect(() => {
		setTheme(getTheme(partner))
	}, [partner])

	return (
		<>
			{/* don't render anything until we have a theme! */}
			{theme && <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>}
		</>
	)
}

export default ThemeProvider
