// React imports
import { createContext, useContext, useEffect, useReducer } from 'react'

// Local imports
import config from '&config'
import { setLoadedAction } from './actions'
import { GET_TOKEN_RECAPTCHA_DISABLED } from './fixtures'
import reCaptchaReducer from './reducer'

const ReCaptchaContext = createContext()

/**
 * This provider adds the ReCaptcha script to the <body> of the DOM, and wraps
 * relevant SDK functionality in react-friendly methods
 */
const ReCaptchaProvider = ({ children }) => {
	const initialState = {
		// TODO: figure out if it is possible to make a static/non-expiring dummy reCaptcha v3 token to return here
		getToken: GET_TOKEN_RECAPTCHA_DISABLED,
		lastToken: null,
		loaded: false,
		sdk: null,
		siteKey: config.reCaptcha.siteKey,
	}
	const [state, dispatch] = useReducer(reCaptchaReducer, initialState)

	useEffect(() => {
		// https://cloud.google.com/recaptcha-enterprise/docs/instrument-web-pages

		const reCaptchaStyles = document.createElement('style')
		reCaptchaStyles.textContent = '.grecaptcha-badge { visibility: hidden; }'
		document.head.appendChild(reCaptchaStyles)
		const reCaptchaScriptElement = document.createElement('script')
		reCaptchaScriptElement.type = 'text/javascript'
		reCaptchaScriptElement.src = `https://www.google.com/recaptcha/enterprise.js?render=${config.reCaptcha.siteKey}`
		document.body.appendChild(reCaptchaScriptElement)

		reCaptchaScriptElement.addEventListener('load', () => {
			window.grecaptcha.enterprise.ready(() => {
				dispatch(setLoadedAction(window.grecaptcha.enterprise))
			})
		})

		return () => {
			reCaptchaScriptElement.remove()
			reCaptchaStyles.remove()
		}
	}, [])

	return (
		<ReCaptchaContext.Provider value={[state, dispatch]}>
			{children}
		</ReCaptchaContext.Provider>
	)
}

const useReCaptchaContext = () => {
	const context = useContext(ReCaptchaContext)
	if (context === undefined) {
		throw new Error('useReCaptcha must be used within a ReCaptchaProvider')
	}
	return context
}

export { ReCaptchaContext, ReCaptchaProvider, useReCaptchaContext }
