import { PathValues } from '&constants/enums/pathAndId'

/**
 * NOTE 1: we do not have test data for the statuses: COMPLETE, IN_PROGRESS, PENDING_REVIEW
 * NOTE 2: avoid using test data that has alternateId: '1' as this will always direct you to the UploadIDView
 */

const demoSelections = [
	{
		label: 'COMPLETE - Approved',
		value: {
			firstName: 'JOHN',
			lastName: 'APPLESAUCE',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '02421',
			ssn: '3232',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'PENDING_CERT - API Feedback',
		value: {
			firstName: 'JOHN',
			lastName: 'SMITH',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'TPIV_FAIL_DOB - Upload ID',
		value: {
			firstName: 'ADAM',
			lastName: 'FASCINATING',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '0342',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'EXISTING_LIFELINE_APP - Lifeline',
		value: {
			firstName: 'Ada',
			lastName: 'Consumer',
			dobDay: '01',
			dobMonth: '01',
			dobYear: '1990',
			zip: '20009',
			ssn: '2222',
			phone: '9999999999',
			street: '123 Address St',
			aptUnit: '',
			city: 'Washington',
			state: 'DC',
			alternateId: '0',
			path: PathValues.SSN,
		},
	},
	{
		label: 'IDENTITY_NOT_FOUND - Error: SSN Error',
		value: {
			firstName: 'ADAM',
			lastName: 'JOYFUL',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'BAD_REQUEST - Error: Network Error',
		value: {
			firstName: 'JOHN',
			lastName: 'SMITH',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'MULTIPLE - Error: Multiple Errors',
		value: {
			firstName: 'NANCY',
			lastName: 'SMITH',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '128 ANIGF ST NOME HI 96701',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'INVALID_ADDRESS - Error: Invalid Address',
		value: {
			firstName: 'JOHN',
			lastName: 'SMITH',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '345 NOT AN ADDRESS',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'TPIV_FAIL_DECEASED - Error: Proof of Life',
		value: {
			firstName: 'ADAM',
			lastName: 'LUCID',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'DUPLICATE_ADDRESS - Error: Multiple Households',
		value: {
			firstName: 'NANCY',
			lastName: 'APPLE',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '1970',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '128 ANIGF ST NOME HI 96701',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
	{
		label: 'SUBSCRIBER_UNDER_18 - Error: Minor',
		value: {
			firstName: 'ANDY',
			lastName: 'APPLE',
			dobDay: '23',
			dobMonth: '11',
			dobYear: '2010',
			zip: '10038',
			ssn: '2222',
			phone: '9999999999',
			email: 'JOHNSMITH@EMAIL.COM',
			street: '12345 MADE UP LANE',
			aptUnit: '',
			city: 'LOS CANADA',
			state: 'MD',
			path: PathValues.SSN,
		},
	},
]

export default demoSelections
