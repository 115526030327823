// react imports
import { useEffect, useState } from 'react'
// Other imports
import { v4 as uuidv4 } from 'uuid'
// local imports
import {
	setApplicationStatusAction,
	setUUIDAction,
} from '&state/nationalVerifier/actions'
import { useNVResponse } from '&state/nationalVerifier/context'
import { useSelection } from '&state/selection/context'
import useFetch from './useFetch'
import { storeEligibilityID } from '&utils/localStorageUtils'
import {
	ApiStatus,
	NVCertifiableStatuses,
	DocumentErrorFailures,
	SSNErrorFailures,
	ErrorStatuses,
} from '&constants/enums/api'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { setPathAction } from '&state/applicationProcess/actions'
import { PathValues } from '&constants/enums/pathAndId'
import useExperiment from '&hooks/useExperiment'

// views
import {
	ApprovedView,
	ErrorView,
	UnderReviewView,
	UploadIDView,
	APIFeedbackView,
	SSNErrorView,
	AppNotFoundView,
	LifelineErrorView,
} from '&views'
import {
	getSelectedBenefits,
	getPrioritizedBenefit,
} from '&utils/qualificationPrioritization'
import { getToken } from '&utils/jwtAuth'

/**
 * This hook is used to fetch specifically eligibility data from the API.
 *
 * @param {string} authorization - Value of authorization header for eligibility request
 */
const useFetchEligibility = () => {
	const [
		{
			alternateIdIndicator,
			name,
			dob,
			address,
			ssn,
			consentGiven,
			benefits,
			publicHousingCode,
			email,
			tribalId,
		},
	] = useSelection()
	const { featureValue: enableVUDS } = useExperiment('v2-enable-vuds-rawdata')

	const { firstName, lastName } = name
	const { month, day, year } = dob
	const { street, aptUnit, city, stateCode, zipCode } = address

	const prioritizedBenefit = getPrioritizedBenefit(
		getSelectedBenefits(benefits)
	)

	const [, applicationProcessDispatch] = useApplicationProcessContext()
	const [nvState, nvDispatch] = useNVResponse()
	const { response, loading, error, executeFetch } = useFetch()
	const [destination, setDestination] = useState('')

	const callEligibilityCheck = async (reCaptcha) => {
		// if already loading do nothing
		if (loading) return

		let uuid = nvState.uuid

		if (!uuid) {
			uuid = uuidv4()
			nvDispatch(setUUIDAction(uuid))
		}

		let jwt
		try {
			nvDispatch({
				type: 'setResult',
				result: { loading: true, error: null, response: null },
			})
			jwt = await getToken()
		} catch (error) {
			nvDispatch({
				type: 'setResult',
				result: { loading: false, error, response: null },
			})
			setDestination(ErrorView)
			return
		}

		// Define the function that fetches the eligibility and sets it as the state variable.
		executeFetch('/eligibility', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Authorization: jwt,
				'x-getacp-recaptcha': reCaptcha,
				'Content-Type': 'application/json',
				'Access-Control-Request-Method': 'POST',
			},
			body: JSON.stringify({
				firstName,
				lastName,
				address: `${street} ${aptUnit}`,
				state: stateCode,
				city,
				zipCode,
				dob: `${year}-${month}-${day}`,
				ssn4: ssn,
				alternateId: alternateIdIndicator,
				eligibilityProgramCode: prioritizedBenefit?.code,
				tribalId: tribalId,
				consentInd: consentGiven ? 'Y' : 'N',
				publicHousingCode: publicHousingCode,
				// NV API throws a 500 when we send localhost in carrierUrl, so replace it with beta url
				carrierUrl: `${window.location.origin.replace(
					/http:\/\/localhost\:\d+/,
					'https://samwise-beta.getacp.org'
				)}/callback?uuid=${uuid}`,
				repNotAssisted: '1', //TODO: make dynamic?
				repId: '', //TODO: needs to be filled if repNotAssisted is missing
				contactEmail: email ? email : '',
				contactId: enableVUDS ? uuid : '',
			}),
		})
			// handle other responses (applicationStatus, UUID etc) as needed
			.catch((error) => {
				nvDispatch({
					type: 'setResult',
					result: { loading: false, error, response: null },
				})
			})
	}

	//store and set destination based on response
	useEffect(() => {
		// if there is no response or error, do nothing (yet)
		if (!response && !error) return

		// dispatch the action to update the state with the fetched data
		nvDispatch({
			type: 'setResult',
			result: { loading: false, error, response },
		})

		if (error) {
			//divert lifeline applicants from other errors
			setDestination(
				error.status === ErrorStatuses.EXISTING_LIFELINE_APP
					? LifelineErrorView
					: ErrorView
			)
		} else if (response) {
			//store response details
			nvDispatch(setApplicationStatusAction(response))

			storeEligibilityID(
				nvState.uuid,
				response.applicationId,
				response.eligibilityCheckId
			)

			if (alternateIdIndicator) {
				//if they need to upload an ID
				applicationProcessDispatch(setPathAction(PathValues.ID))
				setDestination(UploadIDView)
			} else if (response.failures?.length > 0) {
				//then check if there are any failures present

				//get destination based on failures
				const getFailureDestination = (responseArray) => {
					const hasSSNError = responseArray.some((failure) =>
						SSNErrorFailures.includes(failure)
					)
					const hasDocumentError = responseArray.some((failure) =>
						DocumentErrorFailures.includes(failure)
					)

					if (hasSSNError) return SSNErrorView
					else if (hasDocumentError) {
						applicationProcessDispatch(setPathAction(PathValues.ID))
						return UploadIDView
					} else return ErrorView
				}

				setDestination(getFailureDestination(response.failures))
			} else {
				//next check api status
				switch (response.status) {
					case ApiStatus.COMPLETE:
						setDestination(ApprovedView)
						break
					case ApiStatus.IN_PROGRESS:
						setDestination(UnderReviewView)
						break
					case ApiStatus.PENDING_REVIEW:
						setDestination(UnderReviewView)
						break
					case ApiStatus.BAD_REQUEST:
						setDestination(ErrorView)
						break
					case ApiStatus.APP_NOT_FOUND:
						setDestination(AppNotFoundView)
						break
					default:
						//if statuses require USAC certification (PENDING_CERT or PENDING_RESOLUTION)
						if (NVCertifiableStatuses.includes(response.status)) {
							//send user to USAC
							setDestination(APIFeedbackView)
						} else {
							//if we don't know what's going on
							setDestination(ErrorView)
						}
				}
			}
		}
	}, [response, error])

	return {
		loading,
		response: nvState.response,
		error: nvState.error,
		callEligibilityCheck,
		destination,
	}
}

export default useFetchEligibility
