// react imports

import { useTheme } from '@emotion/react'
import { createRef } from 'react'
import { useScreenshot, createFileName } from 'use-react-screenshot'
// local imports
import { Button, Copy, Header, Layout, InviteBox } from '&components'
import { Box } from '@mui/material'
import IconBase from '&components/IconBase'
import DownloadIcon from '&assets/icons/download.svg'
import CopyIcon from '&assets/icons/copy.svg'
import { useNVResponse } from '&state/nationalVerifier/context'
import useExperiment from '&hooks/useExperiment'
import Clock from '&assets/icons/clock.svg'

const UnderReview = () => {
	const theme = useTheme()
	const backgroundColor = theme.palette.secondary.main
	const ref = createRef(null)
	const [, takeScreenShot] = useScreenshot({
		type: 'image/jpeg',
		quality: 1.0,
	})

	const [{ applicationId }] = useNVResponse()

	const { featureValue: socialComponentValue } =
		useExperiment('social-component')

	const download = (
		image,
		{ name = 'ACPInstructions', extension = 'jpg' } = {}
	) => {
		const a = document.createElement('a')
		a.href = image
		a.download = createFileName(extension, name)
		a.click()
	}

	const downloadScreenshot = () => takeScreenShot(ref.current).then(download)

	/* TODO: Ask Shan/Marketing about the real texts that are going to be used.
	Maybe move them to their own file? */
	const sharingSubject = 'Learn about ACP!'
	const sharingText =
		'You are sharing this message in a different platform, visit https://beta.getacp.org for more info'

	return (
		<Layout title='Under Review' {...backgroundColor}>
			<div ref={ref} height='100%'>
				<Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
					<Clock style={{ width: '96px' }} aria-hidden='true' />
				</Box>
				<Header component='h1' center sx={{ pt: 3 }}>
					{'Under Review'}
				</Header>
				<Header component='h2' center sx={{ pt: 3 }}>
					{`Here is your application ID`}
				</Header>
				<Header component='h2' center sx={{ pt: 3 }} className='weglot_exclude'>
					{applicationId}
				</Header>
				<Box
					sx={{
						textAlign: 'center',
						pt: 3,
					}}>
					<Button
						data-ga-id='save-image'
						sx={{ alignSelf: 'center', maxWidth: 'fit-content' }}
						onClick={downloadScreenshot}
						isCondensed
						startIcon={
							<IconBase size='small' icon={DownloadIcon} aria-hidden='true' />
						}
						noFill
						variant='outlined'>
						{'Save Image'}
					</Button>
				</Box>
				<Box
					sx={{
						textAlign: 'center',
						pt: 3,
					}}>
					<Button
						data-ga-id='copy-code'
						sx={{ alignSelf: 'center', maxWidth: 'fit-content' }}
						isCondensed
						copyToClipboard
						copyContent={applicationId}
						startIcon={
							<IconBase size='small' icon={CopyIcon} aria-hidden='true' />
						}
						noFill
						aria-label={'copy code of application ID'}
						variant='outlined'>
						{'Copy Code'}
					</Button>
				</Box>
				<Box sx={{ px: 3, pt: 4, pb: 3 }}>
					<div style={{ borderRadius: '10px', overflow: 'hidden' }}>
						<Box
							sx={{
								backgroundColor: 'almostWhite',
								p: 3,
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Header
								component='h3'
								center
								sx={{ fontWeight: 700, fontSize: '1em' }}>
								{'Processing Time'}
							</Header>
							<Copy sx={{ p: 2, m: 0 }} center italic>
								{'1 to 3 days case by case'}
							</Copy>
							<Copy sx={{ pb: 1 }}>
								{
									'Your application is under review by the Universal Administration Company (the federal administrator of the ACP). We will reach out by phone or email once we have your application status or if there is a problem with your application.'
								}
							</Copy>
						</Box>
					</div>
				</Box>
				{socialComponentValue && (
					<InviteBox
						sharingText={sharingText}
						sharingSubject={sharingSubject}
					/>
				)}
			</div>
		</Layout>
	)
}

export default UnderReview

export const UnderReviewView = {
	component: UnderReview,
	viewName: 'under-review',
}
