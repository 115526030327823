import {
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { memo } from 'react'

/*
 * Reusable component for string inputs (first name, last name, street, apt/unit, etc.). When multiple StringInputs
 * are used on the same page, a id must be provided to differentiate between them for accessibility purposes.
 *
 * @param {boolean} [isInvalid=false] – whether the string is invalid
 * @param {boolean} [isRequired=true] – whether the string is required
 * @param {function} onStringChange – callback function to handle changes to the string
 * @param {string} stringLabel – the label for the string input (translate in parent component)
 * @param {string} stringErrorText – the error text for the string input (translate in parent component)
 * @param {string} [id=''] – (optional) a id to differentiate between multiple string inputs for accessibility purposes
 * @param {string} [value] – (optional) a value to prefill the string input
 */
const StringInput = ({
	isInvalid = false,
	isRequired = true,
	onStringChange,
	stringLabel,
	stringErrorText,
	id = '',
	value = '',
	sx = {},
	...args
}) => {
	const formStyles = {
		width: '100%',
		pb: 3,
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		background: 'white',
		height: '48px',
		'& input': {
			py: 1,
		},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		stringLabel && (
			<FormControl
				error={isInvalid}
				required={isRequired}
				variant='standard'
				sx={{ ...formStyles, ...sx }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor={id}>
					{stringLabel}
				</InputLabel>
				<TextField
					value={value}
					type='string'
					error={isInvalid}
					onChange={(e) => onStringChange(e.target.value)}
					sx={{ width: 'min(100%, 328px)' }}
					InputProps={{
						'aria-describedby': isInvalid ? `${id}-error-text` : undefined,
						sx: { ...inputRootStyles },
						inputProps: {
							id: id,
							'aria-required': isRequired,
							...args,
						},
					}}
				/>
				{isInvalid && (
					<FormHelperText
						role='alert'
						sx={{ ...messageStyles }}
						data-cy={`${id}-error-text`}
						id={`${id}-error-text`}>
						<ErrorOutlineIcon fontSize='small' />
						{stringErrorText}
					</FormHelperText>
				)}
			</FormControl>
		)
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(StringInput, comparator)
