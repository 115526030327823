import { Typography } from '@mui/material'

/*
 * Reusable component for copy, defaults to a paragraph element.  For different sizing/styling, first check if you should be using the
 * Header element with an H element from the theme that matches the styling you need. If not, pass in styling as an sx prop.
 *
 * @prop {String} children - text to be displayed
 * @prop {Boolean} [large=false] - whether or not to use the large styling
 * @prop {Boolean} [italic=false] - whether the text is italic
 * @prop {Boolean} [center=false] - whether or not to center the text
 * @prop {Boolean} [bold=false] - whether or not to bold the text
 * @prop {String} [component='p'] - HTML element to be used
 * @prop {Object} sx - styling object
 */
const Copy = ({
	children,
	large = false,
	italic = false,
	center = false,
	bold = false,
	component = 'p',
	sx,
	...args
}) => {
	const getFontWeight = () => {
		if (bold) {
			return 700
		} else if (large) {
			return 500
		} else {
			return 400
		}
	}
	return (
		<Typography
			component={component}
			sx={{
				fontSize: large ? '1.25em' : '1em',
				fontStyle: italic ? 'italic' : 'normal',
				fontWeight: getFontWeight(),
				textAlign: center ? 'center' : 'left',
				lineHeight: '1.5em',
				whiteSpace: 'pre-wrap',
				mb: 3,
				...sx,
			}}
			{...args}>
			{children}
		</Typography>
	)
}

export default Copy
