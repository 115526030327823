// MUI imports
import { AppBar as MuiAppBar, Container, alpha, useTheme } from '@mui/material'
// local imports
import LanguageSelector from '&components/LanguageSelector'
import SkipLink from '&components/SkipLink'
import LinearProgressBar from '&components/LinearProgressBar'
import ChatButton from '&components/ChatButton'
import DevToolBar from '&components/DevToolBar'
import config from '&utils/config'
import HeroAppBar from './HeroAppBar'
import PageTitle from './PageTitle'
import useNavigation from '&hooks/useNavigation'
import { useWeglot } from '&state/weglot/context'

/**
 * This component is the top AppBar for the application. It contains the chat toggling
 * feature, as well as the title.
 *
 * @param {string} [heroImage] - The image to be rendered as the TopBar background.
 * The following are PROVISIONAL props that should be replaced by logic that invokes the app's
 * context.
 * @param {string} [title='GetACP.ORG'] - The text to be rendered in the TopBar title.
 * @param {boolean} [showProgressBar=false] - A boolean that determines whether the progress bar is
 * rendered or not.
 * @param {boolean} [isProgressCompleted=false] - A boolean that determines whether the linear progress bar should show the step as complete
 * @param {boolean} [showWeglotButton=false] - A boolean that determines whether to render the language selector
 * @param {boolean} [disableSkipButton=false] - A boolean that determines whether or not the Skip Link is rendered
 */
const TopBar = ({
	title = 'GetACP.ORG',
	heroImage,
	showProgressBar = false,
	isProgressCompleted = false,
	showWeglotButton = false,
	disableSkipButton = false,
}) => {
	const { goToView } = useNavigation()
	const [{ enabled: enableWeglot }] = useWeglot()
	const theme = useTheme()

	const AppBar = ({ children, ...args }) =>
		heroImage ? (
			<HeroAppBar heroImage={heroImage} {...args}>
				{children}
			</HeroAppBar>
		) : (
			<MuiAppBar {...args}>{children}</MuiAppBar>
		)

	return (
		<AppBar position='static' color='inherit'>
			<Container
				maxWidth='500px'
				disableGutters
				sx={{
					p: 3,
					boxShadow: showProgressBar ? 'none' : '0 2px 7px rgba(0, 0, 0, 0.05)',
					backgroundColor:
						showWeglotButton && alpha(theme.palette.veryDark, 0.6),
				}}>
				{!disableSkipButton && <SkipLink>{'Skip to Content'}</SkipLink>}
				<nav
					aria-label={'Header Navigation'}
					style={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					{showWeglotButton && enableWeglot ? (
						<LanguageSelector hasBackdrop />
					) : (
						<PageTitle title={title} color={heroImage ? 'white' : 'veryDark'} />
					)}
					{config.devMode ? <DevToolBar goToView={goToView} /> : <></>}
					<ChatButton
						data-cy='chat-button-topbar'
						data-ga-id='live-chat_header'
						id='chat-button-topbar'
					/>
				</nav>
			</Container>
			{showProgressBar && (
				<LinearProgressBar isCompleted={isProgressCompleted} />
			)}
		</AppBar>
	)
}

export default TopBar
