import { getStateFromZip } from '../../utils/zipCode'

/**
 * This is a Redux-style reducer for the Selection Context.
 *
 * @param {React.state} state – the current Selection state
 * @param {Object} action - an action object
 * @returns {Object} – the new Selection state
 */
const selectionReducer = (state, action) => {
	switch (action.type) {
		case 'setZipCode': {
			return {
				...state,
				address: {
					...state.address,
					stateCode: getStateFromZip(action.zipCode),
					zipCode: action.zipCode,
				},
			}
		}
		case 'setConnection': {
			return {
				...state,
				isConnectedAtHome: action.selection,
			}
		}
		case 'setNameAndDOB': {
			return {
				...state,
				name: { firstName: action.firstName, lastName: action.lastName },
				dob: { month: action.month, day: action.day, year: action.year },
			}
		}
		case 'setContactInfo': {
			return {
				...state,
				phone: action.phone,
				email: action.email,
			}
		}
		case 'setAddress': {
			return {
				...state,
				address: {
					street: action.street,
					aptUnit: action.aptUnit,
					stateCode: action.state,
					city: action.city,
					zipCode: action.zipCode,
				},
			}
		}
		case 'setSSN': {
			return {
				...state,
				ssn: action.ssn,
				alternateIdIndicator: 0,
				tribalId: '',
			}
		}
		case 'setTribalId': {
			return {
				...state,
				ssn: '',
				tribalId: action.tribalId,
				alternateIdIndicator: 0,
			}
		}
		case 'setOptions': {
			/* Since we have a few datasets with the same structure and operation, we have
			one case that can access any of those datasets by its key and mutate them
			accordingly */

			let newDataset = state[action.dataset].map((item) =>
				item.value === action.key
					? { ...item, selected: action.selected }
					: item
			)

			const changedItem = newDataset.find((item) => item.value === action.key)

			/* If an exclusive option is checked, de-select all others */
			if (changedItem.isExclusive) {
				newDataset = newDataset
					.filter(Boolean)
					.map((item) =>
						item.value !== action.key ? { ...item, selected: false } : item
					)
			} else if (
				newDataset
					.filter((item) => item.selected)
					.some((item) => item.isExclusive)
			) {
				//If selected options is not exclusive, and there's an exclusive option selected, de-select it
				newDataset = newDataset
					.filter(Boolean)
					.map((item) =>
						item.isExclusive ? { ...item, selected: false } : item
					)
			}

			return {
				...state,
				[action.dataset]: newDataset,
			}
		}
		case 'setBenefitCode': {
			const benefits = state.benefits.map((item) =>
				item.id === action.id ? { ...item, code: action.code } : item
			)
			return {
				...state,
				benefits,
			}
		}
		case 'setConsentGiven': {
			return {
				...state,
				consentGiven: action.consentGiven,
			}
		}
		case 'setAlternateIdIndicator': {
			return {
				...state,
				alternateIdIndicator: action.alternateIdIndicator,
				ssn: action.alternateIdIndicator ? '' : state.ssn,
				tribalId: action.alternateIdIndicator ? '' : state.tribalId,
			}
		}
		case 'setFederalHousing': {
			return {
				...state,
				publicHousingCode: action.publicHousingCode,
			}
		}
		case 'setSelectionState': {
			const {
				firstName,
				lastName,
				month,
				day,
				year,
				street,
				aptUnit,
				stateCode,
				city,
				zipCode,
				email,
				phone,
				ssn,
				tribalId,
			} = action.newStateValues

			return {
				...state,
				ssn,
				tribalId,
				email,
				phone,
				name: {
					firstName: firstName,
					lastName: lastName,
				},
				dob: {
					month: month,
					day: day,
					year: year,
				},
				address: {
					street: street,
					aptUnit: aptUnit,
					stateCode: stateCode,
					city: city,
					zipCode: zipCode,
				},
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default selectionReducer
