// react imports
import { useEffect, useRef, useState } from 'react'

import Lottie from 'lottie-react'
// other imports
import { Box } from '@mui/material'
// local imports
import loadingAnimation from '&assets/animations/loading.json'
import { Copy } from '&components'
import config from '&config'
import useFetch from '&hooks/useFetch'
import useReCaptcha from '&hooks/useReCaptcha'
import { useReCaptchaContext } from '&state/reCaptcha/provider'
import { getEligibilityID } from '&utils/localStorageUtils'
import { getToken } from '&utils/jwtAuth'

const NVEligibilityCertifyCallback = () => {
	const { executeRecaptcha } = useReCaptcha()
	const [{ loaded }] = useReCaptchaContext()
	const [showError, setShowError] = useState(false)
	const [redirecting, setRedirecting] = useState(false)
	const statusCheckInvoked = useRef(false)

	/*
    Retrieve desired eligibility ID from localStorage using the key naming convention
    of 'eligibilityId:' + uuid, where uuid is an id generated prior to the eligibility
    check call and appended to the callback URL passed to the National Verifier
  */
	const uuid = new URLSearchParams(window.location.search).get('uuid')
	const eligibilityId = getEligibilityID(uuid)

	const { loading, error, response, executeFetch } = useFetch()

	document.title = 'Affordable Connectivity Program | Redirecting'

	// Wait for recaptcha to load
	useEffect(() => {
		if (eligibilityId && !statusCheckInvoked.current) {
			if (loaded) {
				executeRecaptcha(config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK)
					.then(
						(reCaptcha) =>
							new Promise((resolve) =>
								getToken().then((jwt) => resolve({ jwt, reCaptcha }))
							)
					)
					.then((tokens) =>
						executeFetch(`/eligibility/${eligibilityId}/status`, {
							method: 'GET',
							mode: 'cors',
							headers: {
								accept: 'application/json',
								Authorization: tokens.jwt,
								'x-getacp-recaptcha': tokens.reCaptcha,
							},
						})
					)
				statusCheckInvoked.current = true
			}
		}
	}, [loaded])

	useEffect(() => {
		if (response) {
			setRedirecting(true)
			// Broadcast message containing application status response from NV
			const channel = new BroadcastChannel(config.nvCallbackBroadcastChannel)
			const message = {
				type: config.nvRedirectMessageName,
				uuid,
				eligibilityStatus: response,
			}
			channel.postMessage(message)
			channel.close()
		}
		if (error) {
			setShowError(true)
		}
	}, [loading, response, error])

	useEffect(() => {
		if (!eligibilityId) {
			setShowError(true)
		}
	}, [])

	return (
		<div
			style={{
				margin: 'auto',
				width: '50%',
				marginTop: '25%',
			}}>
			{showError ? (
				<Copy large sx={{ textAlign: 'center' }}>
					{`Error checking application status.`}
				</Copy>
			) : (
				<>
					<Box sx={{ mt: 5 }}>
						<Lottie
							style={{ margin: 'auto', width: '96px' }}
							animationData={loadingAnimation}
							aria-hidden='true'
							data-visual-test='blackout'
						/>
					</Box>
					<Copy aria-live='polite' large sx={{ pt: 5, textAlign: 'center' }}>
						{redirecting
							? `Redirecting...`
							: `Retrieving application status...`}
					</Copy>
				</>
			)}
		</div>
	)
}

export default NVEligibilityCertifyCallback

export const NVEligibilityCertifyCallbackView = {
	component: NVEligibilityCertifyCallback,
	viewName: 'nv-eligibility-certify-callback',
}
