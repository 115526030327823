import { useWindowSize } from '&hooks/useWindowSize'

/**
 * An SVG React component that is used as decoration on the home page.
 */
const Wave = ({ sx }) => {
	const { width: windowWidth } = useWindowSize()

	const gutterSize = windowWidth < 500 ? 0 : windowWidth - 500
	const distanceToPage = 0.5 * gutterSize

	const pageSize = windowWidth > 500 ? 500 : windowWidth - distanceToPage

	const viewBox = `0 0 ${pageSize} 410`

	/*
	 * original width 360 height 365
	 * the pageSize here is multiplied by a factor based on the relationship with the original dimensions
	 * this is to ensure that the wave waves regardless of window size.
	 * We are currently not changing the height of the wave, but we could if we wanted to.
	 */
	return (
		<svg role='img' aria-hidden='true' viewBox={viewBox} style={{ ...sx }}>
			<path
				d={`M ${pageSize} 410
				V 22.0472
				C ${pageSize} 22.0472 ${pageSize * 0.92} 0 ${pageSize * 0.725} 0
				C ${pageSize * 0.54} 0 ${pageSize * 0.44} 42.8691 ${pageSize * 0.27} 42.8691
				C ${pageSize * 0.1} 42.8691 0 22.0472 0 22.0472
				V 410
				H 360Z`}
				fill='#C4E1E2'
			/>
		</svg>
	)
}

export default Wave
