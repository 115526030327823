import {
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { IMaskMixin } from 'react-imask'
import { memo } from 'react'

// component to wrap MUI TextField with IMask for masking zipcodes
const IMaskInput = IMaskMixin(({ ...props }) => {
	return <TextField {...props} />
})

/*
 * Reusable component for zipcode input.  Note: this component does not validate the zipcode.
 *
 * @param {boolean} isInvalid – whether the zipcode is invalid
 * @param {boolean} isRequired – whether the zipcode is required
 * @param {function} onZipChange – callback function to handle changes to the zipcode
 * @param {string} [value] – (optional) the zipcode value if the input is controlled
 */
const ZipCodeInput = ({
	isInvalid = false,
	isRequired = true,
	onZipChange,
	value = '',
}) => {
	const formStyles = {
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		background: 'white',
		height: '48px',
		'& input': {
			py: 1,
		},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		<FormControl
			error={isInvalid}
			required={isRequired}
			variant='standard'
			sx={{ ...formStyles }}>
			<InputLabel sx={{ ...labelStyles }} htmlFor='zipcode-input'>
				{'Zip Code'}
			</InputLabel>
			<IMaskInput
				id='zipcode-input'
				value={value}
				error={isInvalid}
				sx={{ width: '120px' }}
				onChange={(e) => onZipChange(e.target.value)}
				InputProps={{
					'aria-describedby': isInvalid ? 'zipcode-error-text' : undefined,
					sx: { ...inputRootStyles },
					inputProps: {
						'aria-required': isRequired,
						name: 'postal-code',
						'data-cy': 'input-postal-code',
						'data-ga-id': 'input_zip-code',
						autoComplete: 'postal-code',
					},
				}}
				mask={'00000'}
			/>
			{isInvalid && (
				<FormHelperText
					role='alert'
					sx={{ ...messageStyles }}
					id='zipcode-error-text'>
					<ErrorOutlineIcon fontSize='small' />
					{'Please enter a valid 5 digit zip code'}
				</FormHelperText>
			)}
		</FormControl>
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(ZipCodeInput, comparator)
