import { Typography } from '@mui/material'

/*
 * Reusable component for headers, defaults to an H2 element.  For different sizing/styling, check which H tag you should be using
 * from the theme. If not, pass in styling as an sx prop, or use the Copy component.
 *
 * @prop {String} children - text to be displayed
 * @prop {Boolean} [center='false'] - whether or not to center the text
 * @prop {String} component - HTML element to be used
 * @prop {Object} sx - styling object
 */
const Header = ({
	children,
	center = false,
	component = 'h2',
	sx,
	...args
}) => {
	return (
		<Typography
			component={component}
			variant={component}
			sx={{
				textAlign: center ? 'center' : 'left',
				...sx,
			}}
			{...args}>
			{children}
		</Typography>
	)
}

export default Header
