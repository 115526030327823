/**
 * An SVG React component that is used as decoration on the home page.
 */
const Wifi = ({ sx }) => {
	return (
		<svg
			role='img'
			aria-hidden='true'
			viewBox='0 0 96 96'
			width='96'
			height='96'
			style={{ ...sx }}
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_1984_35653)'>
				<path
					d='M20.3051 72.43C19.0663 71.1912 18.4813 69.7115 18.5501 67.9909C18.619 66.2703 19.3072 64.9282 20.6148 63.9647C24.4002 61.0741 28.6163 58.8208 33.2633 57.2048C37.9103 55.5888 42.8134 54.7794 47.9724 54.7767C53.203 54.7767 58.1418 55.6025 62.7888 57.2543C67.4358 58.9061 71.6506 61.2117 75.4332 64.1711C76.7408 65.1347 77.4126 66.4602 77.4484 68.1478C77.4841 69.8354 76.8812 71.2972 75.6397 72.5333C74.4697 73.7033 73.0409 74.3062 71.3533 74.342C69.6657 74.3777 68.1007 73.9125 66.6581 72.9462C63.974 71.088 61.0669 69.6592 57.9367 68.6598C54.8066 67.6605 51.4852 67.1622 47.9724 67.165C44.4624 67.165 41.1409 67.6812 38.0081 68.7135C34.8752 69.7459 32.0025 71.1568 29.3899 72.9462C27.8758 73.9786 26.2749 74.4438 24.5874 74.342C22.8998 74.2401 21.4724 73.6028 20.3051 72.43ZM2.75501 54.9831C1.585 53.8131 1 52.3843 1 50.6968C1 49.0092 1.61942 47.6162 2.85825 46.5178C8.98359 41.356 15.866 37.3119 23.5055 34.3855C31.1449 31.4591 39.3006 29.9973 47.9724 30C56.6442 30 64.7999 31.4632 72.4393 34.3896C80.0788 37.316 86.9612 41.3587 93.0866 46.5178C94.3254 47.619 94.9627 48.9954 94.9985 50.6472C95.0343 52.299 94.4314 53.7443 93.1898 54.9831C92.0198 56.1531 90.5924 56.7726 88.9076 56.8414C87.2228 56.9102 85.6907 56.394 84.3115 55.2928C79.2873 51.2322 73.6947 48.0663 67.5336 45.7951C61.3724 43.5239 54.8521 42.3883 47.9724 42.3883C41.09 42.3883 34.5696 43.5239 28.4112 45.7951C22.2529 48.0663 16.6602 51.2322 11.6333 55.2928C10.2568 56.394 8.72481 56.9102 7.03724 56.8414C5.34968 56.7726 3.92227 56.1531 2.75501 54.9831ZM47.9724 96.0711C45.6324 96.0711 43.6709 95.2796 42.0879 93.6967C40.505 92.1137 39.7135 90.1522 39.7135 87.8122C39.7135 85.4722 40.505 83.5107 42.0879 81.9277C43.6709 80.3448 45.6324 79.5533 47.9724 79.5533C50.3124 79.5533 52.2739 80.3448 53.8569 81.9277C55.4398 83.5107 56.2313 85.4722 56.2313 87.8122C56.2313 90.1522 55.4398 92.1137 53.8569 93.6967C52.2739 95.2796 50.3124 96.0711 47.9724 96.0711Z'
					fill='url(#paint0_linear_1984_35653)'
				/>
			</g>
			<defs>
				<linearGradient
					id='paint0_linear_1984_35653'
					x1='48'
					y1='30'
					x2='48'
					y2='96.0711'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#00585A' />
					<stop offset='0.494318' stopColor='#009296' />
					<stop offset='1' stopColor='#01AFB4' />
				</linearGradient>
				<clipPath id='clip0_1984_35653'>
					<rect width='96' height='96' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default Wifi
