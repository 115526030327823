// react imports

import { useTheme } from '@emotion/react'
// local imports
import { Copy, Header, Layout, Button } from '&components'
import ClipboardIcon from '&assets/icons/clipboard.svg'
import useNVRedirect from '&hooks/useNVRedirect'
import LeavePageIcon from '&assets/icons/arrow45degree.svg'
import IconBase from '&components/IconBase'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { PathValues } from '&constants/enums/pathAndId'

const APIFeedback = () => {
	const theme = useTheme()
	const { openNVCertifyLink } = useNVRedirect()
	const [{ pathName }] = useApplicationProcessContext()

	const backgroundColor = theme.palette.secondary.main

	//only show progress bars if the path is not status check
	const showProgressBars = Boolean(pathName !== PathValues.STATUS_CHECK)

	return (
		<Layout
			title='Certification'
			topBarText={'Result'}
			showProgressBars={showProgressBars}
			{...backgroundColor}>
			<Header sx={{ my: 3 }}>{'Great!'}</Header>
			<Copy sx={{ mb: 4 }}>
				{`Your application looks great! The final step is to sign the application on the Universal Service Administration Company’s website (the federal administrator of the ACP).`}
			</Copy>
			{/*
				TODO: Add back button/'get new link' button in case the user accidentally closes out of the
				nv tab after opening it, as the links expire after the first time they are opened.
			*/}
			<Button
				data-ga-id='sign-the-application'
				isCondensed
				endIcon={<IconBase size='small' icon={LeavePageIcon} />}
				data-cy='sign-application-btn'
				onClick={openNVCertifyLink}>
				{'Sign the Application'}
			</Button>
		</Layout>
	)
}

export default APIFeedback

export const APIFeedbackView = {
	icon: ClipboardIcon,
	displayName: 'Result',
	component: APIFeedback,
	viewName: 'feedback',
}
