// react imports
import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { useNavigation } from '&hooks/useNavigation'
// local imports
import {
	Button,
	Copy,
	Header,
	Layout,
	NeedHelp,
	Accordion,
	InfoDialog,
	ButtonGrid,
	ZoomedImage,
	IconBase,
	BackButton,
	TipBox,
} from '&components'
import { Box } from '@mui/material'
import LeavePageIcon from '&assets/icons/arrow45degree.svg'
import UploadIcon from '&assets/icons/upload.svg'
import { IdentificationView } from '../Identification'

const proofFHAImage = new URL(
	`../../assets/images/proof-fha.png?as=webp&width=1200`,
	import.meta.url
)

import useNVRedirect from '&hooks/useNVRedirect'
import { useSelection } from '&state/selection/context'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { PathValues } from '&constants/enums/pathAndId'

/**
 * Upload ID page for users to upload documentation to clarify their identity.
 * There are two versions of the this page:
 * 		- AlternateId = 0: User has already uploaded their SSN and NV needs documents for more clarification
 * 		- AlternateId = 1: User has chosen alternate ID, has NOT uploaded SSN and needs the option to return to SSN path
 */
const UploadID = () => {
	const theme = useTheme()
	const [showConfirm, setShowConfirm] = useState(false)
	const [backdropOpen, setBackdropOpen] = useState(false)
	const [variation, setVariation] = useState('')

	const { openNVCertifyLink } = useNVRedirect()
	const { goToView, handleModalNavigation, goBackward } = useNavigation()

	const [{ pathName }] = useApplicationProcessContext()
	const [{ alternateIdIndicator }] = useSelection()

	useEffect(() => {
		// if alternateId is 1 (truthy), the user has not submitted their ssn and we should display the alternate upload id page
		if (alternateIdIndicator) setVariation('upload')
		else setVariation('ssn')
	}, [])

	//only show progress bars if the path is not status check
	const showProgressBars = Boolean(pathName !== PathValues.STATUS_CHECK)

	const backgroundColor = theme.palette.secondary.main

	const handleBackdrop = (isOpen) => {
		setBackdropOpen(isOpen)
	}

	const setPopupState = (isOpen) => {
		setShowConfirm(isOpen)
		handleModalNavigation(isOpen)
	}

	const getCopy = (variant) => {
		return variant === 'ssn' ? (
			<>
				We couldn’t verify your SSN. Please upload a photo of the front of your{' '}
				<strong>ID</strong> and a dated <strong>benefit document</strong> to
				continue the application.
			</>
		) : (
			<>
				Please upload a photo of the front of your <strong>ID</strong> and a
				dated <strong>benefit document</strong> to continue the application.
			</>
		)
	}

	return (
		<>
			<Layout
				title='Upload ID'
				showProgressBars={showProgressBars}
				topBarText={'Result'}
				{...backgroundColor}>
				<Header component='h1' sx={{ mb: 3 }}>
					{'Upload'}
				</Header>
				<Copy data-cy='upload-id-blurb'>{getCopy(variation)}</Copy>
				<Accordion
					sx={{ mb: 3 }}
					id='panel1'
					dataCy='acceptable-ids-description-accordion'
					title={'What are acceptable IDs?'}
					dataGaId='id'>
					<Copy sx={{ fontStyle: 'italic' }}>
						{`Any Government ID issued by the United States or other country as long as it has your full name, date of birth, and is not expired.`}
					</Copy>
					<Copy
						component='ul'
						sx={{
							mb: 0,
							pl: 4,
							'& li:last-child > p': {
								mb: 0,
							},
						}}>
						<li>
							<Copy>{`Driver’s License`}</Copy>
						</li>
						<li>
							<Copy>{`Military ID`}</Copy>
						</li>
						<li>
							<Copy>{`Passport`}</Copy>
						</li>
						<li>
							<Copy>{`Tribal Identification Number`}</Copy>
						</li>
						<li>
							<Copy>{`Taxpayer Identification Number`}</Copy>
						</li>
						<li>
							<Copy>{`Birth Certificate`}</Copy>
						</li>
						<li>
							<Copy>{`Consular ID`}</Copy>
						</li>
						<li>
							<Copy>{`Other Government ID`}</Copy>
						</li>
					</Copy>
				</Accordion>
				<Accordion
					sx={{ mb: 4 }}
					id='panel2'
					dataCy='acceptable-ids-examples-accordion'
					dataGaId='benefit-docs'
					title={'Examples of benefit documents'}>
					<Copy>{`The document should include`}</Copy>
					<Copy
						component='ul'
						sx={{
							mb: 0,
							pl: 4,
							'& li:last-child > p': {
								mb: 4,
							},
						}}>
						<li>
							<Copy>{`your full name`}</Copy>
						</li>
						<li>
							<Copy>{`the name of the benefit program`}</Copy>
						</li>
						<li>
							<Copy>
								{`the name of the authority that issued the document`}
							</Copy>
						</li>
						<li>
							<Copy>
								{`an issue date within the last 12 months or a future expiration date`}
							</Copy>
						</li>
					</Copy>
					<Copy>{`Example of a Federal Housing benefit letter`}</Copy>
					<ZoomedImage
						imageTitle={`Proof of Federal Housing Assistance Example`}
						open={backdropOpen}
						handleBackdrop={handleBackdrop}>
						<img
							alt={`Proof of Federal Housing Assistance Example`}
							src={proofFHAImage}
							style={{
								width: '100%',
								maxHeight: '95vh',
							}}
						/>
					</ZoomedImage>
					<Copy sx={{ fontStyle: 'italic' }}>
						*{`Your benefit document may look different.`}
					</Copy>
				</Accordion>
				{variation === 'upload' && (
					<Button
						data-cy='use-ssn-instead-btn'
						data-ga-id='use-ssn-instead'
						onClick={() => goToView(IdentificationView)}
						sx={{ fontWeight: 400, mb: 4 }}
						variant='text'>
						{'Or use SSN instead'}
					</Button>
				)}
				<TipBox message='Uploaded images should be clear.'></TipBox>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						mt: 5,
						mb: 3,
					}}>
					<Button
						data-ga-id='upload-id-and-documents'
						sx={{ width: 'fit-content' }}
						isCondensed
						onClick={() => setPopupState(true)}>
						{'Upload ID & Documents'}
						<IconBase
							size='small'
							icon={LeavePageIcon}
							sx={{ marginLeft: theme.spacing(1) }}
						/>
					</Button>
					<BackButton
						sx={{ width: 'fit-content' }}
						onClick={() => goBackward()}
						isCondensed
						variant='outlined'
					/>
				</Box>
				<NeedHelp />
				<InfoDialog
					isOpen={showConfirm}
					title={
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
							<IconBase size='small' icon={LeavePageIcon} />
							{'Leaving Our Site'}
						</Box>
					}
					sx={{
						'& .MuiDialogActions-root': {
							justifyContent: 'flex-start',
							backgroundColor: 'almostWhite',
						},
						'& .MuiDialogContent-root': {
							p: 3,
							gap: 4,
						},
					}}
					actions={
						<ButtonGrid sx={{ m: 0 }}>
							<Button
								isCondensed
								onClick={() => setPopupState(false)}
								variant='outlined'>
								{'Cancel'}
							</Button>
							<Button isCondensed onClick={openNVCertifyLink}>
								{'Continue'}
							</Button>
						</ButtonGrid>
					}>
					{`We will direct you to the Universal Service Administration Company's website to upload your ID and documents.`}
				</InfoDialog>
			</Layout>
		</>
	)
}

export default UploadID

export const UploadIDView = {
	icon: UploadIcon,
	displayName: 'Upload',
	component: UploadID,
	viewName: 'upload-id',
}
