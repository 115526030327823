// react imports
import { useState } from 'react'

import { useTheme } from '@emotion/react'
// local imports
import {
	Header,
	Layout,
	MultiSelectButton,
	MultiSelectGrid,
	BackButton,
	ButtonGrid,
	NextButton,
} from '&components'
import useNavigation from '&hooks/useNavigation'
import { useSelection } from '&state/selection/context'
import { setOptions } from '&state/selection/actions'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { setPathAction } from '&state/applicationProcess/actions'
import { clamp } from '&utils/css'
import {
	getSelectedBenefits,
	getPrioritizedBenefit,
} from '&utils/qualificationPrioritization'
import { NameDOBView } from '../NameDOB'
import { NotQualifiedView } from '../NotQualified'
import { LifelineErrorView } from '../LifelineError'
import { PathValues } from '&constants/enums/pathAndId'

const InternetUsage = () => {
	const [isInvalid, setIsInvalid] = useState(false)

	const { goBackward, goToView } = useNavigation()
	const [, applicationProcessDispatch] = useApplicationProcessContext()
	const [{ usage: usageOptions, benefits }, selectionDispatch] = useSelection()
	const theme = useTheme()

	const backgroundColor = theme.palette.secondary.main

	const handleNext = () => {
		const selectedBenefits = getSelectedBenefits(benefits)
		const hasItemsSelected =
			usageOptions.filter((item) => item.selected).length > 0

		setIsInvalid(!hasItemsSelected)

		if (hasItemsSelected) {
			const prioritizedBenefit = getPrioritizedBenefit(selectedBenefits)

			if (prioritizedBenefit?.id === 'none') {
				// If the user does not receive any benefits, we take them to the "Not Qualified" screen
				goToView(NotQualifiedView)
			} else if (prioritizedBenefit?.id === 'lifeline') {
				// If the user selected lifeline as any of their benefits, take them to "Lifeline" screen
				goToView(LifelineErrorView)
			} else {
				// currently, we're heading towards the happy path! we'll add more later.
				applicationProcessDispatch(setPathAction(PathValues.SSN))
				goToView(NameDOBView)
			}
		}
	}

	const checkFunction = (value, isChecked) => {
		const checkedKey = usageOptions.find((item) => item.value === value).value

		selectionDispatch(setOptions('usage', checkedKey, isChecked))
	}

	const resetError = () => setIsInvalid(false)

	return (
		<Layout
			title={'Internet Usage'}
			topBarText={'Qualification'}
			t
			{...backgroundColor}>
			<Header
				sx={{
					fontSize: clamp('1.25em'),
					mb: theme.spacing(3),
					fontWeight: '500',
				}}>
				{'How do you or would you use the internet at home?'}
			</Header>
			<MultiSelectGrid
				error={isInvalid}
				resetError={resetError}
				checkFunction={checkFunction}
				label={'Select all that apply to you'}
				sx={{ mb: theme.spacing(5) }}>
				{usageOptions.map((item, index) => (
					<MultiSelectButton
						key={index}
						isChecked={item.selected}
						icon={item.icon}
						value={item.value}
						ariaLabel={item.ariaLabel}
						dataGaId={item.dataGaId}
						dataCy={item.dataCy}
					/>
				))}
			</MultiSelectGrid>
			<ButtonGrid>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<NextButton
					sx={{
						maxWidth: 'min-content',
						fontSize: '1em',
					}}
					onClick={handleNext}
					isCondensed
				/>
			</ButtonGrid>
		</Layout>
	)
}

export default InternetUsage

export const InternetUsageView = {
	component: InternetUsage,
	viewName: 'internet-usage',
}
