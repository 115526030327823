// react imports
import { useEffect, useState } from 'react'
import { Box, Divider, IconButton } from '@mui/material'
import { useTheme } from '@emotion/react'
// local imports
import {
	Button,
	Header,
	Layout,
	IconBase,
	ButtonGrid,
	Copy,
	Link,
	ChatButton,
	ImageSlider,
	Banner,
	InfoDialog,
} from '&components'
import config from '&config'
import useNavigation from '&hooks/useNavigation'
import { BenefitsView } from '../Benefits'
import { StatusCheckView } from '../StatusCheck'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { setPathAction } from '&state/applicationProcess/actions'
import { PathValues } from '&constants/enums/pathAndId'
import useExperiment from '&hooks/useExperiment'
// icons
import Checkmark from '&assets/icons/checkmark.svg'
import GovernmentIcon from '&assets/icons/government.svg'
import ClockOutlined from '&assets/icons/components/ClockOutlined'
import Curve from '&assets/images/components/Curve'
import Wave from '&assets/images/components/Wave'
import Wifi from '&assets/images/components/Wifi'
import Step1 from '&assets/icons/step1.svg'
import Step2 from '&assets/icons/step2.svg'
import Step3 from '&assets/icons/step3.svg'
import More from '&assets/icons/more.svg'
import FeatherCircleIcon from '&assets/icons/featherCircle.svg'
import information from '&assets/icons/information.svg'
import alertIcon from '&assets/icons/alert.svg'
import LeavePageIcon from '&assets/icons/arrow45degree.svg'

//Small teal divider
const SmallDivider = ({ theme }) => (
	<hr
		aria-hidden='true'
		style={{
			width: '80px',
			borderColor: theme.palette.secondary.main,
			margin: '65px auto',
		}}
	/>
)

const Welcome = () => {
	const { goToView, resetIndices, handleModalNavigation } = useNavigation()
	const theme = useTheme()
	const [, applicationProcessDispatch] = useApplicationProcessContext()
	const { featureValue: housesEnrolled } = useExperiment('households-enrolled')
	const { featureValue: enableLifelineTribal } = useExperiment(
		'v2-enable-lifelinetribal'
	)

	const [showTribalDialog, setShowTribalDialog] = useState(false)

	const enableApplications = () => !config.disableApplications

	useEffect(() => {
		resetIndices()
	}, [])

	const backgroundColor = theme.palette.secondary.main
	const isProjectWaves = window.location.hostname === 'getacp.org'

	// importing images this way allows Parcel to optimize them for the web
	// TODO: update image max-width(s) when the max width of the page changes - waiting on design
	const welcomeHero = new URL(
		`../../assets/images/hero-large.png?as=webp&width=750`,
		import.meta.url
	)
	// carousel images
	const diaDeMuertos = new URL(
		'../../assets/images/oakland.png?as=webp&width=500',
		import.meta.url
	)
	const nycSignup = new URL(
		'../../assets/images/nyc.png?as=webp&width=500',
		import.meta.url
	)
	const charlotte1 = new URL(
		'../../assets/images/charlotte1.png?as=webp&width=500',
		import.meta.url
	)
	// logos
	const medicaidLogo = new URL(
		'../../assets/icons/medicaid.png?as=webp&width=100',
		import.meta.url
	)
	const fedHousingLogo = new URL(
		'../../assets/icons/fedhousing-logo.png?as=webp&width=100',
		import.meta.url
	)
	// staff images
	const alejandra = new URL(
		'../../assets/images/alejandra.png?as=webp&width=100',
		import.meta.url
	)
	const dyvonn = new URL(
		'../../assets/images/dyvonn.png?as=webp&width=100',
		import.meta.url
	)
	const sara = new URL(
		'../../assets/images/sara.png?as=webp&width=100',
		import.meta.url
	)
	const maribel = new URL(
		'../../assets/images/maribel.png?as=webp&width=100',
		import.meta.url
	)

	const slides = [
		{
			image: charlotte1,
			title: 'ACP Sign Up Event',
			location: 'Charlotte, NC',
		},
		{
			image: diaDeMuertos,
			title: 'Building ACP Awareness',
			location: 'Oakland, CA',
		},
		{
			image: nycSignup,
			title: 'Expert Support',
			location: 'New York',
		},
	]

	const grayBoxStyling = {
		px: 3,
		pt: 4,
		pb: 3,
		backgroundColor: 'almostWhite',
		borderRadius: 6,
	}

	const freeInternetStyling = {
		fontFamily: 'Lato',
		p: 3,
		mt: 4,
		pt: '45px',
		backgroundColor: '#fff',
		borderRadius: 6,
	}

	const whatYouMayGetStyling = {
		...grayBoxStyling,
		fontFamily: 'Lato',
		mt: enableApplications() ? 4 : 5,
		'& > ul': {
			p: 0,
			listStyle: 'none',
			display: 'flex',
			flexDirection: 'column',
			gap: 3,
			'& > li': {
				display: 'flex',
				alignItems: 'flex-start',
				gap: 2,
				'& > svg': {
					mt: '3px', //Manual adjustment to align it with text
				},
			},
		},
	}

	const handleStatusCheck = () => {
		applicationProcessDispatch(setPathAction(PathValues.STATUS_CHECK))
		goToView(StatusCheckView)
	}

	const setTribalDialogState = (isOpen) => {
		setShowTribalDialog(isOpen)
		handleModalNavigation(isOpen)
	}

	// These are the contents of the Pause Banner for before and after Feb 8th
	const pauseBannerContents = (
		<>
			<Copy
				sx={{
					fontWeight: 700,
					display: 'flex',
					gap: 2,
					alignItems: 'center',
				}}>
				<IconBase icon={alertIcon} size='medium' />
				{'ACP ENROLLMENT ON HOLD'}
			</Copy>
			<Copy>{`Enrollment for the ACP is currently on hold. If you are receiving the ACP, you will continue to receive the benefit until the program ends. Feel free to provide your email, and we'll notify you on any updates about the ACP.`}</Copy>
			<ButtonGrid center sx={{ m: 0 }}>
				<Button
					data-cy='pause-notify-btn'
					data-ga-id='get-notified'
					component={Link}
					href='https://www.educationsuperhighway.org/keep-me-updated/'
					newTab
					outbound
					color='customError'
					sx={{
						color: 'white',
					}}>
					{'Get Notified'}
				</Button>
			</ButtonGrid>
			<ButtonGrid center sx={{ mt: 3, mb: 0 }}>
				<Link
					newTab
					href='https://www.dontdisconnectus.org/'
					data-ga-id='advocacy-acp'
					sx={{
						textDecoration: 'none',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: 'fit-content',
						gap: 2,
						borderBottom: `solid 1px ${theme.palette.veryDark}`,
						'&:hover': {
							borderBottom: 'solid 1px transparent', // Keeping the border to avoid layout shifting
							textDecoration: 'none',
						},
					}}>
					{'Save ACP'}
					<IconBase size='small' icon={LeavePageIcon} />
				</Link>
			</ButtonGrid>
		</>
	)

	return (
		<Layout
			useMiniFooter={false}
			title='Apply Now'
			heroImage={welcomeHero}
			disableSkipButton
			showTopWeglotButton
			{...backgroundColor}
			banner={
				config.disableApplications && (
					<Banner sticky>{pauseBannerContents}</Banner>
				)
			}>
			<Curve
				sx={{
					zIndex: -1,
					display: 'block',
					position: 'absolute',
					top: -75,
					left: 0,
					width: '100%',
				}}
			/>

			{/* Free Internet */}
			<Box sx={freeInternetStyling}>
				<Wifi
					sx={{
						position: 'absolute',
						top: 0,
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				/>
				<Header component='h1' center data-cy='welcome-page-main-title'>
					{'Free Home Internet'}
				</Header>
				<Copy sx={{ pt: 3 }}>
					{
						'The Affordable Connectivity Program (ACP) is a government funded program. You may qualify for free internet if you receive government benefits.'
					}
				</Copy>
			</Box>
			{enableApplications() && (
				<ButtonGrid center sx={{ mt: 1 }}>
					<Button
						data-cy='start-application-btn'
						data-ga-id='see-if-you-qualify-1'
						sx={{ mt: 4 }}
						onClick={() => goToView(BenefitsView)}>
						{'See If You Qualify'}
					</Button>
				</ButtonGrid>
			)}
			<Copy center sx={!enableApplications() && { mt: 3 }}>
				<span
					style={{
						color: '#fff',
						background: theme.palette.secondary.dark,
						fontWeight: 700,
					}}>
					{/* keep most up-to-date numbers in growthbook, the string is backup is gb is down */}
					{housesEnrolled || '21,826,157'}
				</span>
				{' households enrolled'}
			</Copy>

			{/* What you may get */}
			<Box sx={whatYouMayGetStyling}>
				<Header component='h2' sx={{ fontWeight: 500, fontSize: 20 }}>
					{'What you may get'}
				</Header>
				<ul>
					<li>
						<IconBase aria-hidden='true' icon={Checkmark} size='small' />
						{'$30/month discount'}
					</li>
					<li>
						<IconBase
							aria-hidden='true'
							icon={Checkmark}
							size='small'
							sx={{ flexShrink: '0' }}
						/>
						<Copy sx={{ mb: 0 }}>
							{'$75/month discount on qualifying tribal lands'}
							{enableLifelineTribal && (
								<IconButton
									size='small'
									sx={{
										p: 0,
										pb: '10px',
										ml: '2px',
									}}
									aria-label='More info on tribal lands'
									data-cy='tribal-lands-info-btn'
									data-ga-id='tribal-lands-modal'
									onClick={() => setTribalDialogState(true)}>
									<IconBase
										icon={information}
										size='medium'
										color={theme.palette.veryDark}
										sx={{ pointerEvents: 'none' }}
									/>
								</IconButton>
							)}
						</Copy>
					</li>
					<li>
						<IconBase aria-hidden='true' icon={Checkmark} size='small' />
						{'High speed internet'}
					</li>
				</ul>
				<Divider aria-hidden='true' sx={{ borderColor: 'veryLight', mb: 3 }} />
				<Header component='h3'>{'About the program'}</Header>
				<ul style={{ listStyle: 'none' }}>
					<li>
						<IconBase aria-hidden='true' icon={GovernmentIcon} size='small' />
						{'Government funded'}
					</li>
					<li>
						<IconBase
							aria-hidden='true'
							icon={ClockOutlined}
							defId='welcome-clock-1'
							size='small'
						/>
						{'Apply easily on a phone or computer'}
					</li>
				</ul>
			</Box>

			{/* Easy to apply */}
			<Header component='h2' center sx={{ mt: 5 }}>
				{'Easy to apply'}
			</Header>
			<Copy
				sx={{
					display: 'flex',
					mt: 2,
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<IconBase
					aria-hidden='true'
					icon={ClockOutlined}
					defId='welcome-clock-2'
					size='small'
					sx={{ marginRight: '9px' }}
				/>
				{'5-8 minutes to apply'}
			</Copy>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}>
				<Box>
					<IconBase
						aria-hidden='true'
						sx={{ width: '40px', margin: '0px 25px 16px 15px ' }}
						icon={Step1}
					/>
					<Copy center sx={{ mb: 1 }}>
						{'Step 1'}
					</Copy>
					<Copy center sx={{ fontWeight: 700 }}>
						{'QUALIFY'}
					</Copy>
				</Box>
				<span aria-hidden='true' style={{ marginTop: '9px' }}>
					&#5171;
				</span>
				<Box>
					<IconBase
						aria-hidden='true'
						sx={{ width: '40px', margin: '0px 25px 16px 25px' }}
						icon={Step2}
					/>
					<Copy center sx={{ mb: 1 }}>
						{'Step 2'}
					</Copy>
					<Copy center sx={{ fontWeight: 700 }}>
						{'APPLY'}
					</Copy>
				</Box>
				<span aria-hidden='true' style={{ marginTop: '9px' }}>
					&#5171;
				</span>
				<Box>
					<IconBase
						aria-hidden='true'
						sx={{ width: '40px', margin: '0px 15px 16px 25px ' }}
						icon={Step3}
					/>
					<Copy center sx={{ mb: 1 }}>
						{'Step 3'}
					</Copy>
					<Copy center sx={{ fontWeight: 700 }}>
						{'CONNECT'}
					</Copy>
				</Box>
			</Box>
			{enableApplications() && (
				<ButtonGrid center sx={{ mt: 3 }}>
					<Button
						data-ga-id='see-if-you-qualify-2'
						onClick={() => goToView(BenefitsView)}>
						{'See If You Qualify'}
					</Button>
				</ButtonGrid>
			)}

			{/* Status Check */}
			{enableApplications() && (
				<Box sx={{ ...grayBoxStyling, my: 5 }}>
					<Header component='h3' center>
						{'Already applied?'}
					</Header>
					<ButtonGrid center sx={{ mt: 4 }}>
						<Button
							isCondensed
							data-cy='welcome-status-check-btn'
							data-ga-id='check-application-status'
							onClick={handleStatusCheck}
							variant='outlined'>
							{'Check Application Status'}
						</Button>
					</ButtonGrid>
				</Box>
			)}

			{/* Who we are */}
			<Header component='h2' center sx={!enableApplications() && { mt: 5 }}>
				{'Who we are...'}
			</Header>
			<Copy sx={{ mt: 3 }}>
				{isProjectWaves ? (
					'The nonprofits EducationSuperHighway and Project Waves have partnered to connect families to discounted internet through the Affordable Connectivity Program (ACP).'
				) : (
					<>
						{'EducationSuperHighway is a '}
						<strong>{'national non-profit. '}</strong>
						{`We are a proud partner of the Federal Communications Commission's Your Home Your Internet Pilot Program.`}
					</>
				)}
			</Copy>
			<ImageSlider slides={slides} sx={{ margin: 'auto' }} />
			<Link
				newTab
				href='https://www.educationsuperhighway.org/'
				data-ga-id='learn-more'
				sx={{ display: 'block', textAlign: 'center', mt: 4 }}>
				{'Learn more about us'}
			</Link>
			<SmallDivider theme={theme} />

			{/* You may qualify if... */}
			<Header component='h2' center>
				{'You may qualify if you use...'}
			</Header>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: 3,
				}}>
				<Box sx={{ width: '80px', m: 2 }}>
					<IconButton
						data-ga-id='see-if-you-qualify-3'
						aria-disabled={!enableApplications()}
						disableRipple={!enableApplications()}
						onClick={() => {
							if (!enableApplications()) return
							goToView(BenefitsView)
						}}
						sx={{
							width: '80px',
							height: '80px',
							borderRadius: '50%',
							backgroundColor: '#F6f5f1',
							p: 0,
							mb: 1,
							cursor: enableApplications() ? 'pointer' : 'auto',
						}}>
						<img
							alt={'Federal Housing logo'}
							width='57'
							height='57'
							src={fedHousingLogo}
						/>
					</IconButton>
					<Copy center sx={{ mt: 3, mb: 1 }}>
						{'Federal Housing'}
					</Copy>
				</Box>
				<Box sx={{ width: '80px', m: 2 }}>
					<IconButton
						data-ga-id='see-if-you-qualify-3'
						aria-disabled={!enableApplications()}
						disableRipple={!enableApplications()}
						onClick={() => {
							if (!enableApplications()) return
							goToView(BenefitsView)
						}}
						sx={{
							width: '80px',
							height: '80px',
							borderRadius: '50%',
							backgroundColor: '#F6f5f1',
							p: 0,
							mb: 1,
							cursor: enableApplications() ? 'pointer' : 'auto',
						}}>
						<img
							alt={'Medicaid logo'}
							width='57'
							height='57'
							src={medicaidLogo}
						/>
					</IconButton>
					<Copy center sx={{ mt: 3, mb: 1 }}>
						{'Medicaid'}
					</Copy>
				</Box>
				<Box sx={{ width: '80px', m: 2 }}>
					<IconButton
						data-ga-id='see-if-you-qualify-3'
						aria-label={'See more eligibility options'}
						aria-disabled={!enableApplications()}
						disableRipple={!enableApplications()}
						onClick={() => {
							if (!enableApplications()) return
							goToView(BenefitsView)
						}}
						sx={{
							width: '80px',
							height: '80px',
							borderRadius: '50%',
							backgroundColor: '#F6f5f1',
							p: 0,
							mb: 1,
							cursor: enableApplications() ? 'pointer' : 'auto',
						}}>
						<IconBase icon={More} />
					</IconButton>
					<Copy center sx={{ mt: 3, mb: 1 }}>
						{'And More'}
					</Copy>
				</Box>
			</Box>
			<Wave
				sx={{
					zIndex: -1,
					display: 'block',
					position: 'absolute',
					left: 0,
					width: '100%',
				}}
			/>

			{/* Have Questions? */}
			<Header component='h2' center sx={{ mt: '96px' }}>
				{'Have questions?'}
			</Header>
			<Copy center sx={{ fontWeight: 300 }}>
				{'Chat with our specialists.'}
			</Copy>
			<Copy center sx={{ mb: 0 }}>
				{'Monday - Friday'}
			</Copy>
			<Copy center>{'10am - 8pm Eastern Time'}</Copy>
			<Box
				sx={{
					display: 'grid',
					m: '32px auto',
					width: 'fit-content',
					gridTemplateColumns: 'repeat(4, 50px)',
					gridColumnGap: '8px',
				}}>
				<img
					src={alejandra}
					alt={'One of our specialists, Alejandra'}
					width='50'
					height='50'
				/>
				<img
					src={dyvonn}
					alt={'One of our specialists, Dyvonn'}
					width='50'
					height='50'
				/>
				<img
					src={sara}
					alt={'One of our specialists, Sara'}
					width='50'
					height='50'
				/>
				<img
					src={maribel}
					alt={'One of our specialists, Maribel'}
					width='50'
					height='50'
				/>
			</Box>
			<ButtonGrid center sx={{ mt: 0, pb: 2, mb: 0 }}>
				<ChatButton id='chat-button-welcome' data-ga-id='live-chat' />
			</ButtonGrid>
			<InfoDialog
				data-cy='tribal-lands-modal'
				isOpen={showTribalDialog}
				setDialogState={setTribalDialogState}
				scroll='paper'
				showTopCloseButton
				showScrollGradient
				titleSize='16px'
				title={
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<IconBase size='medium' icon={FeatherCircleIcon} width='28' />
						{'Qualifying Tribal Lands'}
					</Box>
				}
				sx={{
					'& .MuiDialogActions-root': {
						justifyContent: 'flex-start',
						backgroundColor: 'almostWhite',
					},
					'& .MuiDialogContent-root': {
						p: 3,
						gap: 4,
					},
				}}>
				{`Your internet company can help determine whether your address is on Tribal lands. For informational purposes, a `}
				<Link
					newTab
					href='https://www.affordableconnectivity.gov/wp-content/uploads/acp/documents/fcc_tribal_lands_map.pdf'
					sx={{
						display: 'inline-block',
						mb: 0,
					}}>
					{'nationwide map'}
				</Link>
				{` is available showing Tribal lands eligible for enhanced support. The ACP uses the same definition for Tribal lands as the FCC’s Lifeline program (as defined in 47 CFR §54.400 (e)). Tribal lands shown on the nationwide map include:`}
				<Copy
					component='ul'
					sx={{
						mb: 0,
						pl: 4,
						'& li:last-child > p': {
							mb: 3,
						},
					}}>
					<li>
						<Copy
							sx={{
								mb: 0,
							}}>{`Any federally recognized Indian tribe’s reservation, pueblo, or colony,`}</Copy>
					</li>
					<li>
						<Copy
							sx={{
								mb: 0,
							}}>{`Former reservations in Oklahoma,`}</Copy>
					</li>
					<li>
						<Copy
							sx={{
								mb: 0,
							}}>{`Alaska Native regions established pursuant to the Alaska Native Claims Settlement Act,`}</Copy>
					</li>
					<li>
						<Copy
							sx={{
								mb: 0,
							}}>{`Indian allotments,`}</Copy>
					</li>
					<li>
						<Copy
							sx={{
								mb: 0,
							}}>{`Hawaiian Home Lands and`}</Copy>
					</li>
					<li>
						<Copy
							sx={{
								mb: 0,
							}}>{`Any land approved as Tribal for the purposes of the Lifeline program or the ACP by the FCC’s Office of Native Affairs and Policy and Wireline Competition Bureau.`}</Copy>
					</li>
				</Copy>
				<Link
					newTab
					href='https://www.affordableconnectivity.gov/do-i-qualify/enhanced-tribal-benefit/#:~:text=Your%20internet%20company%20can%20help,CFR%20%C2%A754.400%20(e)).'
					sx={{
						display: 'block',
						mb: 4,
						fontStyle: 'italic',
					}}>
					{'Refer to Qualifying Tribal Land Guidelines'}
				</Link>
			</InfoDialog>
		</Layout>
	)
}

export default Welcome

export const WelcomeView = { component: Welcome, viewName: 'welcome' }
