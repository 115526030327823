// React imports
import { useState } from 'react'
// MUI imports
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	useTheme,
} from '@mui/material'
// Our imports
import Button from '&components/Button'
import IconBase from '&components/IconBase'
import ToolboxIcon from '&assets/icons/toolbox.svg'
import { clamp } from '&utils/css'
import { DropdownInput } from '&components/FormInputs'
import viewsList from '&constants/dropdownOptions/viewsList'
import demoSelections from '&constants/dropdownOptions/demoSelections'
import { useSelection } from '&state/selection/context'
import {
	setSSNAction,
	setZipCodeAction,
	setNameAndDOBAction,
	setContactInfoAction,
	setAddressAction,
	setOptions,
	setFederalHousingAction,
} from '&state/selection/actions'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { setPathAction } from '&state/applicationProcess/actions'

const DevToolBar = ({ goToView }) => {
	//TODO: figure out why we can't use useNavigation here without causing an infinite rerender
	const theme = useTheme()

	const [dialogOpen, setDialogOpen] = useState(false)
	const [targetView, setTargetView] = useState()
	const [userDataSelection, setUserDataSelection] = useState()

	const [, selectionDispatch] = useSelection()
	const [, applicationProcessDispatch] = useApplicationProcessContext()

	const handleDialogOpen = (open = true) => {
		setDialogOpen(open)
	}

	const handleUserDataSelectionChange = (selection) => {
		console.log(selection)
		setUserDataSelection(selection)
	}

	const handleSubmit = () => {
		selectionDispatch(setSSNAction(userDataSelection.ssn))
		selectionDispatch(setZipCodeAction(userDataSelection.zip))
		selectionDispatch(
			setNameAndDOBAction(
				userDataSelection.firstName,
				userDataSelection.lastName,
				userDataSelection.dobDay,
				userDataSelection.dobMonth,
				userDataSelection.dobYear
			)
		)
		selectionDispatch(
			setContactInfoAction(userDataSelection.phone, userDataSelection.email)
		)
		selectionDispatch(
			setAddressAction(
				userDataSelection.street,
				userDataSelection.aptUnit,
				userDataSelection.city,
				userDataSelection.state,
				userDataSelection.zip
			)
		)
		applicationProcessDispatch(setPathAction(userDataSelection.path))
		//so far all test data has had federal housing as the benefit, if this changes this will need to be updated
		selectionDispatch(setOptions('benefits', 'Federal Housing', true))
		selectionDispatch(setFederalHousingAction('1'))

		goToView(targetView)
	}

	return (
		<>
			<Button
				className='weglot_exclude'
				variant='contained'
				id='dev-toolbar'
				isCondensed
				onClick={handleDialogOpen}
				startIcon={<IconBase size='small' icon={ToolboxIcon} />}
				color='primary'
				sx={{
					maxWidth: 'fit-content',
					fontSize: '1em',
				}}>
				Dev Tools
			</Button>
			<Dialog
				className='weglot_exclude'
				fullScreen={false}
				open={Boolean(dialogOpen)}
				onClose={() => handleDialogOpen(false)}
				role='main'
				PaperProps={{ 'aria-modal': dialogOpen }}
				aria-labelledby='responsive-dialog-title'
				maxWidth={'false'}
				sx={{
					'&.MuiPaper-root, .MuiDialog-paper': {
						margin: 0,
						maxWidth: `calc(100% - ${theme.spacing(4)})`,
					},
					py: '40px',
				}}
				scroll='body'>
				<DialogActions
					sx={{
						display: 'flex',
						justifyContent: 'center',
						px: '24px',
						pt: 3,
					}}>
					<DialogTitle
						id='responsive-dialog-title'
						sx={{
							width: 'fit-content',
							whiteSpace: 'pre-wrap',
							fontSize: clamp('24pt'),
							fontWeight: 600,
							p: 0,
						}}>
						Dev Tools
					</DialogTitle>
				</DialogActions>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1em',
						pt: 0,
					}}>
					<DropdownInput
						id='devbar-selected-user-data'
						options={demoSelections}
						value={userDataSelection?.value}
						onDropdownChange={(value) => {
							handleUserDataSelectionChange(value)
						}}
						dropdownLabel='Select test user'
						sx={{ display: 'block' }}
						autocompleteArgs={{ ListboxProps: { className: 'weglot_exclude' } }}
					/>
					<DropdownInput
						id='devbar-target-view'
						options={viewsList}
						value={targetView?.value}
						onDropdownChange={(value) => {
							setTargetView(value)
						}}
						dropdownLabel='Select view'
						sx={{ display: 'block' }}
						autocompleteArgs={{ ListboxProps: { className: 'weglot_exclude' } }}
					/>
					<Button
						isCondensed
						onClick={handleSubmit}
						sx={{ alignSelf: 'right' }}>
						Go to View
					</Button>
					<Button
						isCondensed
						onClick={() => handleDialogOpen(false)}
						sx={{ alignSelf: 'center', maxWidth: 'min-content' }}>
						Close
					</Button>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default DevToolBar
