import { AppBar as MuiAppBar } from '@mui/material'
import { useWindowSize } from '&hooks/useWindowSize'

/**
 * This component is separated from the original AppBar component because it requires a listener to
 * rerender the component when the window is resized.  We do not want to add this listener to every page.
 */
const HeroAppBar = ({ heroImage, children, ...args }) => {
	const { height, width } = useWindowSize()

	//if editing the shape of the curve, it may be helpful to look at an svg path editor like https://yqnn.github.io/svg-path-editor/
	const elHeight = height * 0.35
	const elWidth = width > 500 ? 500 : width

	const arcHeight = elHeight * 0.2
	const heroHeight = elHeight + arcHeight
	const arcLift = elWidth / 3

	return (
		<MuiAppBar
			sx={{
				background: `url(${heroImage}) center bottom`,
				backgroundSize: 'cover',
				height: heroHeight,
				clipPath: `path('m 0 0 v ${elHeight} c ${arcLift} ${arcHeight} ${
					arcLift * 2
				} ${arcHeight} ${elWidth} 0 V 0 Z')`,
			}}
			{...args}>
			{children}
		</MuiAppBar>
	)
}

export default HeroAppBar
