// react imports

import { useTheme } from '@emotion/react'
import { useState } from 'react'
// local imports
import {
	ButtonGrid,
	Copy,
	Header,
	Layout,
	NextButton,
	BackButton,
} from '&components'
import { CheckboxInput, EmailInput, PhoneInput } from '&components/FormInputs'
import { Box } from '@mui/material'
import {
	validateEmail,
	validatePhone,
	trimAndValidate,
} from '&utils/validation'
import config from '&config'
import { useSelection } from '&state/selection/context'
import { setContactInfoAction } from '&state/selection/actions'
import useNavigation from '&hooks/useNavigation'
import { AddressView } from '../Address'
import PhoneIcon from '&assets/icons/telephone.svg'
import Link from '&components/Link'
import useFetchContactData from '&hooks/useFetchContactData'
import useReCaptcha from '&hooks/useReCaptcha'
import useExperiment from '&hooks/useExperiment'

const ContactInfo = () => {
	const theme = useTheme()
	const { featureValue: enableVUDS } = useExperiment('v2-enable-vuds-rawdata')

	const [{ email: storedEmail, phone: storedPhone, name }, selectionDispatch] =
		useSelection()
	const { goBackward, goToView } = useNavigation()

	const { firstName, lastName } = name

	const [email, setEmail] = useState(storedEmail)
	const [emailInvalid, setEmailInvalid] = useState(false)
	const [phone, setPhone] = useState(storedPhone)
	const [phoneInvalid, setPhoneInvalid] = useState(false)
	const [consentGiven, setConsentGiven] = useState(false)
	const [consentInvalid, setConsentInvalid] = useState(false)

	const { storeContactData } = useFetchContactData()
	const { executeRecaptcha } = useReCaptcha()

	const backgroundColor = theme.palette.secondary.main

	const validateConsent = (consent) => {
		if (consent) {
			setConsentInvalid(false)
			return true
		} else {
			setConsentInvalid(true)
			return false
		}
	}

	const handleSubmit = async () => {
		const { cleanedValue: cleanedEmail, isValid: validEmail } = trimAndValidate(
			email,
			validateEmail,
			setEmailInvalid,
			setEmail
		)
		const { cleanedValue: cleanedPhone, isValid: validPhone } = trimAndValidate(
			phone,
			validatePhone,
			setPhoneInvalid,
			setPhone
		)
		const validConsent = validateConsent(consentGiven)

		if ((validEmail || validPhone) && validConsent) {
			selectionDispatch(
				setContactInfoAction(
					validPhone ? cleanedPhone : '',
					validEmail ? cleanedEmail : ''
				)
			)

			// Sending contact data to DB only if VUDS is enabled in growthbook
			if (enableVUDS) {
				const token = await executeRecaptcha(
					config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
				)
				storeContactData(token, { firstName, lastName, email, phone })
			}
			goToView(AddressView)
		}
	}

	return (
		<Layout
			title='Contact Info'
			topBarText={'Application'}
			showProgressBars
			noPadding
			{...backgroundColor}>
			<Box sx={{ p: 3 }}>
				<Header
					component='h2'
					sx={{ fontWeight: 800, fontSize: 31, lineHeight: '150%' }}>
					{'Contact Info'}
				</Header>
				<Copy large sx={{ pt: 3, mb: 4 }}>
					{'We may reach out to you about your application status.'}
				</Copy>
				<Box role='form'>
					<EmailInput
						isInvalid={emailInvalid && phoneInvalid}
						isRequired
						onEmailChange={setEmail}
						value={email}
						name='email'
						autoComplete='email'
					/>
					<Copy>{'OR'}</Copy>
					<PhoneInput
						isInvalid={phoneInvalid && emailInvalid}
						isRequired
						onPhoneChange={setPhone}
						value={phone}
						name='phone'
						autoComplete='tel'
					/>
					<CheckboxInput
						isRequired
						isInvalid={consentInvalid}
						onCheckboxChange={() => setConsentGiven(!consentGiven)}
						checked={consentGiven}
						errorText={`Please check the “I agree” box to proceed.`}
						labelSx={{ pl: '3px' }}
						data-cy={'select-agree-terms-box'}
						checkboxLabel={
							<Copy component='span' sx={{ display: 'inline-block', mb: 0 }}>
								{`By checking this box, I agree to receive  text messages about the ACP. Reply STOP to cancel, HELP for help. Msg & Data rates may apply. By clicking “Next”, you agree to EducationSuperHighway’s`}{' '}
								<Link
									newTab
									href='https://www.educationsuperhighway.org/terms-of-service/'>
									{'Terms of Service'}
								</Link>
								{'. '}
								{'See our Privacy Policy'}{' '}
								<Link
									newTab
									aria-label={'See our Privacy Policy here'}
									href='https://www.educationsuperhighway.org/privacy-policy/'>
									{'here'}
								</Link>
								{'.'}
							</Copy>
						}
						id='consent'
						sx={{
							pt: 4,
							'& .MuiFormControlLabel-root': {
								alignItems: 'flex-start',
							},
						}}
					/>
				</Box>
				<ButtonGrid sx={{ my: 5 }}>
					<BackButton isCondensed onClick={goBackward} variant='outlined' />
					<NextButton onClick={handleSubmit} isCondensed />
				</ButtonGrid>
			</Box>
		</Layout>
	)
}

export default ContactInfo

export const ContactInfoView = {
	displayName: 'Contact',
	icon: PhoneIcon,
	component: ContactInfo,
	viewName: 'contact-info',
}
