import {
	Autocomplete,
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { memo } from 'react'

/**
 * Reusable component for dropdown inputs (state or territory, etc.) When multiple DropdownInputs
 * are used on the same page, a id must be provided to differentiate between them for accessibility purposes.
 *
 * @param {boolean} [isInvalid=false] – whether the dropdown is invalid
 * @param {boolean} [isRequired=true] – whether the dropdown is required
 * @param {function} onDropdownChange – callback function to handle changes to the dropdown
 * @param {string} dropdownLabel – the label for the dropdown input (translate in parent component)
 * @param {string} dropdownErrorText – the error text for the dropdown input (translate in parent component)
 * @param {string} [dropdownWidth='328px'] – the width of the dropdown input (state or territory: 328px (default), month: 130px)
 * @param {array} options – the list of options for the dropdown
 * @param {number} [id=''] – (optional) a id to differentiate between multiple dropdown inputs for accessibility purposes)
 * @param {string} [value] – (optional) the value of the dropdown input if controlled
 * @param {object} autocompleteArgs - (optional) arguments for autocomplete component
 */
const DropdownInput = ({
	isInvalid = false,
	isRequired = true,
	onDropdownChange,
	dropdownLabel,
	dropdownErrorText,
	dropdownWidth = '328px',
	options,
	id = '',
	value = undefined,
	sx = {},
	autocompleteArgs,
	...args
}) => {
	const formStyles = {
		pb: 3,
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		background: 'white',
		height: '48px',
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'& fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'rgba(0, 0, 0, 0.23)',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		dropdownLabel && (
			<FormControl
				error={isInvalid}
				required={isRequired}
				variant='standard'
				sx={{ ...formStyles, ...sx }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor={`dropdown${id}-input`}>
					{dropdownLabel}
				</InputLabel>
				<Autocomplete
					disableClearable
					id={`dropdown${id}-input`}
					value={value}
					options={options}
					onChange={(_, v) => {
						const { value } = v
						onDropdownChange(value)
					}}
					sx={{
						'& .MuiInputBase-root': {
							height: '48px',
							width: dropdownWidth,
							...inputRootStyles,
						},
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							inputProps={{
								...params.inputProps,
								'aria-required': isRequired,
								'aria-describedby': isInvalid
									? `dropdown${id}-error-text`
									: undefined,
								style: { padding: '0 0 0 5px' },
								...args,
							}}
						/>
					)}
					{...autocompleteArgs}
				/>
				{isInvalid && (
					<FormHelperText
						role='alert'
						sx={{ ...messageStyles }}
						id={`dropdown${id}-error-text`}>
						<ErrorOutlineIcon fontSize='small' />
						{dropdownErrorText}
					</FormHelperText>
				)}
			</FormControl>
		)
	)
}

// memoization comparator so the component only rerenders when the value or isInvalid prop changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(DropdownInput, comparator)
