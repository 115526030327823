/**
 * This action is for setting the selection made on the ZipCode step
 *
 * @param {String} zipCode - the zip code selection made on the ZipCode step
 * @param {String} stateCode - the resulting stateCode selection made on the ZipCode step
 */
export const setZipCodeAction = (zipCode, stateCode) => ({
	type: 'setZipCode',
	zipCode,
	stateCode,
})

/**
 * This action is for setting the isConnectedAtHome selection made on the Connection step
 *
 * @param {Boolean} isConnectedAtHome - the resulting isConnectedAtHome selection made on the Connection step
 */
export const setConnectionAction = (isConnectedAtHome) => ({
	type: 'setConnection',
	isConnectedAtHome,
})

/**
 * This action is for setting the first, last name, and dob selection made on the Name & DOB step
 *
 * @param {string} firstName - the user's first name
 * @param {string} lastName - the user's last name
 * @param {string} day - the user's day of birth
 * @param {string} month - the user's month of birth
 * @param {string} year - the user's year of birth
 */
export const setNameAndDOBAction = (firstName, lastName, day, month, year) => ({
	type: 'setNameAndDOB',
	firstName,
	lastName,
	day,
	month,
	year,
})

/**
 * This action is for setting the email and/or phone number selection made on the Contact Info step
 *
 * @param {string} phone - the user's phone number
 * @param {string} email - the user's email address
 */
export const setContactInfoAction = (phone, email) => ({
	type: 'setContactInfo',
	phone,
	email,
})

/**
 * This action is for setting the address selection made on the Address step
 *
 * @param {string} street - the user's street address
 * @param {string} aptUnit - the user's apartment or unit number
 * @param {string} city - the user's city
 * @param {string} state - the user's state (will update existing stateCode)
 * @param {string} zipCode - the user's zip code (will update existing zipCode)
 */
export const setAddressAction = (street, aptUnit, city, state, zipCode) => ({
	type: 'setAddress',
	street,
	aptUnit,
	city,
	state,
	zipCode,
})

/**
 * This action is for setting the ssn selection made on the identification step,
 * also clears other identification fields
 *
 * @param {string} ssn - the last four digits of user's ssn
 */
export const setSSNAction = (ssn) => ({
	type: 'setSSN',
	ssn,
})

/**
 * This action is for setting the tribalId selection made on the identification step,
 * also clears other identification fields
 *
 * @param {string} tribalId - the user's tribalId
 */
export const setTribalIdAction = (tribalId) => ({
	type: 'setTribalId',
	tribalId,
})

/**
 * This action is for setting the Benefit options shown in the Benefits view
 *
 * @param {string} dataset - the set of options to mutate
 * @param {string} key - the Benefit key
 * @param {string} selected - the selection value from the Checkbox
 */
export const setOptions = (dataset, key, selected) => ({
	type: 'setOptions',
	dataset,
	key,
	selected,
})

/**
 * This action is for setting the consentGiven boolean
 *
 * @param {boolean} consentGiven - whether the user has consented to send their information to USAC
 */
export const setConsentGiven = (consentGiven) => ({
	type: 'setConsentGiven',
	consentGiven,
})

/**
 * This action is for setting the Alternate ID Indicator. This value will be passed along
 * in the eligibility request body as 'alternateId', with a value of 0 indicating that the request
 * should use SSN for eligibility verification, and a value of 1 indicating that the requester intends
 * to provide and alternate ID. Also clears other identification fields
 *
 * @param {(0|1)} alternateIdIndicator whether SSN/Tribal (0) or an alternate ID (1) should be used for eligibility verification
 */
export const setAlternateIdIndicator = (alternateIdIndicator) => ({
	type: 'setAlternateIdIndicator',
	alternateIdIndicator,
})

/**
 * This action is for setting the Federal Housing selection shown in the Federal Housing view
 *
 * @param {string} key - the publicHousingCode selected by the user
 *
 */
export const setFederalHousingAction = (publicHousingCode) => ({
	type: 'setFederalHousing',
	publicHousingCode,
})

/**
 * This action is for setting all the user related values with a single object
 *
 * @param {string} newStateValues - the publicHousingCode selected by the user
 *
 */
export const setSelectionStateAction = (newStateValues) => ({
	type: 'setSelectionState',
	newStateValues,
})

/**
 * This action is for setting/changing the code of a benefit option
 *
 * @param {string} id - the id of the Benefit
 * @param {string} code - the new code for the benefit
 *
 */
export const setBenefitCode = (id, code) => ({
	type: 'setBenefitCode',
	id,
	code,
})
