/**
 * This action sets the result of the GrowthBook feature API call.
 */
export const setGrowthBookFeatures = (features) => ({
	type: 'SET_GB_FEATURES',
	features,
})

export const setGrowthBookUnavailable = () => ({
	type: 'SET_GB_UNAVAILABLE',
})
