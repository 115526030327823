import { BenefitsView } from '&views/Benefits'
import { ForgotIDView } from '&views/ForgotID'
import { InternetUsageView } from '&views/InternetUsage'
import { ReviewView } from '&views/Review'
import { IdentificationView } from '&views/Identification'
import { NameDOBView } from '&views/NameDOB'

export default [
	{ label: 'Review', value: ReviewView },
	{ label: 'Benefits', value: BenefitsView },
	{ label: 'Internet Usage', value: InternetUsageView },
	{ label: 'Name & DOB', value: NameDOBView },
	{ label: 'Identification', value: IdentificationView },
	{ label: 'Forgot ID', value: ForgotIDView },
]
