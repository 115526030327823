// react imports
import { useEffect, useState } from 'react'
//local imports
import { AddressInput } from '&components/FormInputs'
import { Header, Copy, Button } from '&components'
import { Box, FormHelperText } from '@mui/material'
import { useSelection } from '&state/selection/context'
import { validateZipcode, trimAndValidate } from '&utils/validation'
import { setAddressAction } from '&state/selection/actions'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

/**
 * This component houses the EditAddress component.  It can both display the saved address and allow users to submit changes to the address.
 * @param {function} onSubmit - The function that will be called when the user submits the address changes (called after validation)
 * @param {boolean} [isEditable=false] - (optional) Whether the address should be editable or not
 * @param {any} header - (optional) A component to display above the inputs
 * @param {object} sx - The style object that will be passed to the parent Box component
 */
const EditAddress = ({ onSubmit, isEditable = false, sx = {}, header }) => {
	const [
		{
			address: {
				street: storedStreet,
				aptUnit: storedAptUnit,
				city: storedCity,
				stateCode: storedStateCode,
				zipCode: storedZipCode,
			},
		},
		selectionDispatch,
	] = useSelection()

	const [editMode, setEditMode] = useState(false)
	const [street, setStreet] = useState(storedStreet)
	const [streetInvalid, setStreetInvalid] = useState(false)
	const [aptUnit, setAptUnit] = useState(storedAptUnit)
	const [city, setCity] = useState(storedCity)
	const [cityInvalid, setCityInvalid] = useState(false)
	const [stateCode, setStateCode] = useState(storedStateCode)
	const [stateInvalid, setStateInvalid] = useState(false)
	const [zipCode, setZipCode] = useState(storedZipCode)
	const [zipCodeInvalid, setZipCodeInvalid] = useState(false)
	const [sameAddressError, setSameAddressError] = useState(false)

	useEffect(() => {
		if (editMode) {
			//shift focus to revealed content
			const firstInput = document.getElementById('address-line1')
			firstInput.focus()
		}
	}, [editMode])

	const isAddressDefined =
		storedCity && storedStateCode && storedStreet && storedZipCode

	// checks address in state against address in context and returns true if there are any differences
	const hasAddressChanged = () => {
		const sameStreet = street === storedStreet
		const sameAptUnit = aptUnit === storedAptUnit
		const sameCity = city === storedCity
		const sameState = stateCode === storedStateCode
		const sameZipCode = zipCode === storedZipCode

		return !(sameStreet && sameAptUnit && sameCity && sameState && sameZipCode)
	}

	const handleSubmit = () => {
		const { cleanedValue: cleanedStreet, isValid: validStreet } =
			trimAndValidate(
				street,
				(value) => Boolean(value),
				setStreetInvalid,
				setStreet
			)
		const { cleanedValue: cleanedCity, isValid: validCity } = trimAndValidate(
			city,
			(value) => Boolean(value),
			setCityInvalid,
			setCity
		)
		const { cleanedValue: cleanedStateCode, isValid: validState } =
			trimAndValidate(
				stateCode,
				(value) => Boolean(value),
				setStateInvalid,
				setStateCode
			)
		const { cleanedValue: cleanedZipCode, isValid: validZipCode } =
			trimAndValidate(zipCode, validateZipcode, setZipCodeInvalid, setZipCode)
		const cleanedAptUnit = aptUnit.trim()

		if (validStreet && validCity && validState && validZipCode) {
			const addressChanged = hasAddressChanged()
			if (addressChanged) {
				//store and submit new address
				selectionDispatch(
					setAddressAction(
						cleanedStreet,
						cleanedAptUnit,
						cleanedCity,
						cleanedStateCode,
						cleanedZipCode
					)
				)
				onSubmit()
			} else {
				// show error message
				setSameAddressError(true)
			}
		}
	}

	return (
		isAddressDefined && (
			<>
				{header}
				<Box role='form'>
					<Box
						data-cy='edit-address-component'
						sx={{
							p: 3,
							mx: editMode ? -3 : 0,
							borderRadius: editMode ? '0px' : '8px',
							backgroundColor: 'almostWhite',
							...sx,
						}}>
						{editMode ? (
							<AddressInput
								isStreetInvalid={streetInvalid}
								isCityInvalid={cityInvalid}
								isStateInvalid={stateInvalid}
								isZipInvalid={zipCodeInvalid}
								onStreetChange={setStreet}
								onAptChange={setAptUnit}
								onCityChange={setCity}
								onStateChange={setStateCode}
								onZipChange={setZipCode}
								streetValue={street}
								aptValue={aptUnit}
								cityValue={city}
								stateValue={stateCode}
								zipValue={zipCode}
								showTitle
								aria-describedby={`edit-address-error-text`}
							/>
						) : (
							<>
								<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
									{'Address'}
								</Header>
								<Copy
									sx={{ mb: 0 }}
									className='weglot_exclude'>{`${street} ${aptUnit}`}</Copy>
								<Copy
									sx={{ mb: 2 }}
									className='weglot_exclude'>{`${city}, ${stateCode} ${zipCode}`}</Copy>
							</>
						)}
					</Box>
					{sameAddressError && (
						<FormHelperText
							sx={{
								color: 'customError.main',
								display: 'flex',
								alignItems: 'center',
								'& > svg': { pr: 0.5 },
								mt: 3,
							}}
							id='edit-address-error-text'>
							<ErrorOutlineIcon fontSize='small' />
							{
								'It looks like the address submitted has not changed, please edit your address.'
							}
						</FormHelperText>
					)}
				</Box>
				{isEditable && (
					<Box sx={{ py: 4 }}>
						{editMode ? (
							<Button
								data-ga-id='address-error_submit-change'
								isCondensed
								onClick={handleSubmit}>
								{'Submit'}
							</Button>
						) : (
							<Button
								data-cy='edit-button'
								data-ga-id='address-error_edit-address'
								isCondensed
								onClick={() => setEditMode(true)}>
								{'Edit Address'}
							</Button>
						)}
					</Box>
				)}
			</>
		)
	)
}

export default EditAddress
