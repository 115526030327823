/**
 * IconBase is a wrapper component for icons. It takes an icon as a prop and renders it in the size specified.
 *
 * @param {object} icon - The icon to be rendered.
 * @param {string} size [d=Large]- The size of the icon. Can be 'small', 'medium', or 'large'.
 * @param {object} [sx] - Any styles to be applied to the icon.
 */
const IconBase = ({ icon: Icon, size = 'large', sx, ...args }) => {
	const getSize = () => {
		let actualSize
		switch (size) {
			case 'small':
				actualSize = '16px'
				break
			case 'medium':
				actualSize = '24px'
				break
			default:
				actualSize = '56px'
		}
		return actualSize
	}

	return <Icon width={getSize()} style={{ ...sx }} {...args} />
}

export default IconBase
