import { useState, useEffect, useRef } from 'react'

import {
	Layout,
	Button,
	ButtonGrid,
	Header,
	NeedHelp,
	BackButton,
} from '&components'
import { trimAndValidate, validateApplicationID } from '&utils/validation'
import { StringInput } from '&components/FormInputs'
import { Box } from '@mui/material'
import { useTheme } from '@emotion/react'
import { getEligibilityIdByApplicationId } from '&utils/localStorageUtils'
import useNavigation from '&hooks/useNavigation'
import useFetchStatusCheck from '&hooks/useFetchStatusCheck'
import useReCaptcha from '&hooks/useReCaptcha'
import SearchIDIcon from '&assets/icons/searchID.svg'
import config from '&config'
import { ForgotIDView } from '../ForgotID'
import { IDNotFoundView } from '../IDNotFound'
import { TempStatusCheckView } from '../TempStatusCheck'
import useExperiment from '&hooks/useExperiment'
import { getToken } from '&utils/jwtAuth'

const StatusCheck = () => {
	const hasFetched = useRef(false)
	const [applicationID, setApplicationID] = useState('')
	const [isInvalid, setIsInvalid] = useState(false)
	const [isPageLoading, setIsPageLoading] = useState(false)
	const { goBackward, goToView } = useNavigation()

	const theme = useTheme()
	const {
		loading: statusCheckLoading,
		callStatusCheck,
		destination: destinationView,
	} = useFetchStatusCheck()
	const { executeRecaptcha } = useReCaptcha()
	const { featureValue: tempStatusCheckPath } = useExperiment(
		'v2-temp-status-check'
	)

	const backgroundColor = theme.palette.secondary.main

	useEffect(() => {
		// if the call is loading, set the page to load
		if (statusCheckLoading) setIsPageLoading(true)
	}, [statusCheckLoading])

	useEffect(() => {
		//listen for a destination, set in useFetchStatusCheck, then go to it
		if (destinationView && !statusCheckLoading) {
			goToView(destinationView)
		}
	}, [destinationView, statusCheckLoading])

	const handleSubmit = async () => {
		const { cleanedValue: cleanedAppId, isValid: validAppId } = trimAndValidate(
			applicationID,
			validateApplicationID,
			setIsInvalid,
			setApplicationID
		)
		if (validAppId) {
			setIsInvalid(false)
			const eligibilityId = getEligibilityIdByApplicationId(cleanedAppId)
			if (eligibilityId) {
				//	IF FOUND: Make the Status Check call.
				if (!hasFetched.current) {
					//get new captcha token
					const token = await executeRecaptcha(
						config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
					)
					const jwt = await getToken()
					callStatusCheck(token, jwt, eligibilityId)
					hasFetched.current = true
				}
			} else {
				//  If the feature flag for the temporary status check path is on, go to the temp status check view
				if (tempStatusCheckPath) {
					goToView(TempStatusCheckView)
				} else {
					//	IF ELIGIBILITY ID NOT FOUND: Go to the Information Input form for Status Check.
					goToView(IDNotFoundView)
				}
			}
		}
	}

	const handleForgotId = () => {
		//  If the feature flag for the temporary status check path is on, go to the temp status check view
		if (tempStatusCheckPath) {
			goToView(TempStatusCheckView)
		} else {
			goToView(ForgotIDView)
		}
	}

	return (
		<Layout
			title='Status Check'
			isLoading={isPageLoading}
			topBarText={'Status Check'}
			{...backgroundColor}>
			<Box sx={{ display: 'flex', justifyContent: 'center', pb: 4, pt: 3 }}>
				<SearchIDIcon style={{ width: '95px' }} />
			</Box>
			<Header
				component='h2'
				sx={{ fontWeight: 800, fontSize: 31, lineHeight: '150%', mb: 3 }}>
				{'Status Check'}
			</Header>
			<Header component='h3' sx={{ mb: 4 }}>
				{'Enter your application ID'}
			</Header>
			<StringInput
				isInvalid={isInvalid}
				value={applicationID}
				onStringChange={setApplicationID}
				stringLabel={'ID Example: B85789-99208'}
				stringErrorText={'Please enter a valid application ID'}
				id='status-id'
				data-cy='input-status-id'
				sx={{ '& .MuiFormLabel-asterisk': { display: 'none' }, pb: 0, mb: 4 }}
				isRequired
			/>
			<Button
				variant='text'
				data-cy='forgot-id-btn'
				sx={{ fontWeight: '400' }}
				onClick={handleForgotId}>
				{`Forgot your application ID?`}
			</Button>
			<ButtonGrid sx={{ mt: 5, mb: 6 }}>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<Button onClick={handleSubmit} data-ga-id='next-button' isCondensed>
					{'Submit'}
				</Button>
			</ButtonGrid>
			<NeedHelp />
		</Layout>
	)
}

export default StatusCheck

export const StatusCheckView = {
	component: StatusCheck,
	viewName: 'status-check',
}
