import { useWindowSize } from '&hooks/useWindowSize'

/**
 * An SVG React component that is used as decoration on the home page.
 */
const Curve = ({ sx }) => {
	const { height: pageHeight, width: windowWidth } = useWindowSize()

	const gutterSize = windowWidth < 500 ? 0 : windowWidth - 500
	const distanceToPage = 0.5 * gutterSize

	const pageSize = windowWidth > 500 ? 500 : windowWidth - distanceToPage

	const viewBox = `0 0 ${pageSize} 600`

	//this should mimic the curve in HeroAppBar (elHeight * 0.2)
	const arcHeight = pageHeight * 0.35 * 0.2
	//this should mimic the curve in HeroAppBar
	const arcLift = pageSize / 3

	return (
		<svg role='img' aria-hidden='true' viewBox={viewBox} style={{ ...sx }}>
			<path
				d={`M 0 0 C ${arcLift} ${arcHeight} ${
					arcLift * 2
				} ${arcHeight} ${pageSize} 0 V 600 H 0 V 0 Z`}
				fill='#C4E1E2'
			/>
		</svg>
	)
}

export default Curve
