import config from '&config'

export const getToken = async () => {
	const response = await window.fetch(config.apiBaseUrl + '/auth', {
		method: 'POST',
	})
	if (response.status >= 400) {
		throw new Error('Error generating auth token')
	}
	const json = await response.json()
	if (json && json.token) {
		return json.token
	} else {
		throw new Error(
			'Missing token in /auth response ' + JSON.stringify(response)
		)
	}
}
