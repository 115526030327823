// react imports

import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { useState } from 'react'
// local imports
import {
	BackButton,
	ButtonGrid,
	Copy,
	Header,
	Layout,
	NextButton,
} from '&components'
import HouseIcon from '&assets/icons/house.svg'
import useNavigation from '&hooks/useNavigation'
import { ZipCodeView } from '../ZipCode'
import RadioBtnInput from '&components/FormInputs/RadioBtnInput'
import { useSelection } from '&state/selection/context'
import { setFederalHousingAction } from '&state/selection/actions'
import { federalHousingOptions } from '&constants/enums/options'

const FederalHousing = () => {
	const theme = useTheme()
	const { goBackward, goToView } = useNavigation()
	const [{ publicHousingCode }, selectionDispatch] = useSelection()
	const [isInvalid, setIsInvalid] = useState(false)

	const backgroundColor = theme.palette.secondary.main

	const handleChange = (value) => {
		selectionDispatch(setFederalHousingAction(value))
	}

	const handleNext = () => {
		if (publicHousingCode) {
			goToView(ZipCodeView)
		} else {
			setIsInvalid(true)
		}
	}

	return (
		<Layout
			title='Federal Housing'
			topBarText={'Intake Questions'}
			{...backgroundColor}>
			<Box sx={{ display: 'flex', justifyContent: 'center', pb: 4, pt: 3 }}>
				<HouseIcon style={{ width: '95px' }} />
			</Box>
			<Header component='h2' sx={{ fontWeight: 500, fontSize: 20 }}>
				{'What Federal Housing program do you participate in?'}
			</Header>
			<Copy sx={{ py: 3 }}>{'Select the one that applies to you'}</Copy>
			<RadioBtnInput
				isInvalid={isInvalid}
				options={federalHousingOptions}
				value={publicHousingCode}
				radioGroupLabel='Federal Housing types'
				errorText='Please select one before proceeding'
				sx={{ ml: theme.spacing(1) }}
				onRadioChange={handleChange}
			/>
			<ButtonGrid sx={{ mt: 5, mb: 0 }}>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<NextButton onClick={handleNext} isCondensed />
			</ButtonGrid>
		</Layout>
	)
}

export default FederalHousing

export const FederalHousingView = {
	component: FederalHousing,
	viewName: 'federal-housing',
}
