import { Backdrop } from '@mui/material'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import { useEffect } from 'react'

/**
 * This component is meant to render and image in two different states:
 * normal and zoomed in. The normal state renders the image inside of
 * a button component, this button is tasked with activating the backdrop
 * in which the Zoomed in image will be displayed. The Zoomed in state
 * renders said backdrop.
 * @param {boolean} open - Value that determines if the image is zoomed in, passed down from parent
 * @param {string} imageTitle - Title of the image, added to the aria label of the button element.
 * @param {function} handleBackdrop - A function to control the open state of the image.
 * @param {any} children - The image to display.
 */
const ZoomedImage = ({ open, imageTitle, handleBackdrop, children }) => {
	useEffect(() => {
		// Listening for key presses, enabling the user to exit zoomed in mode.
		const listenForClose = (event) => {
			event.preventDefault()
			if (event.key === 'Escape') handleBackdrop(false)
		}
		if (open) {
			window.addEventListener('keydown', listenForClose)
		}

		return () => window.removeEventListener('keydown', listenForClose)
	}, [open])

	return (
		<>
			<button
				style={{ padding: 0, margin: 0, border: 'none', cursor: 'pointer' }}
				aria-label={`Open detailed view of ${imageTitle}`}
				onClick={() => handleBackdrop(true)}>
				{children}
			</button>
			<Backdrop
				sx={{
					color: '#fff',
					zIndex: (theme) => theme.zIndex.drawer + 1,
					'& img': { zIndex: (theme) => theme.zIndex.drawer + 2 },
				}}
				open={open}
				onClick={() => handleBackdrop(false)}>
				<TransformWrapper>
					<TransformComponent>{children}</TransformComponent>
				</TransformWrapper>
			</Backdrop>
		</>
	)
}

export default ZoomedImage
