/*
 we define our action types as constants to help avoid typos and make it clear what our possible
 actions are.
 */
export const SET_CHAT_CHAT_ENABLED = 'SET_CHAT_CHAT_ENABLED'
export const SET_CHAT_WINDOW_OPEN = 'SET_CHAT_WINDOW_OPEN'
export const SET_WIDGET_LOADED = 'SET_WIDGET_LOADED'

/**
 * an action to set chat as enabled or disabled
 *
 * @param {boolean} enabled – Whether or not the chat functionality is enabled
 */
export const setChatEnabledAction = (enabled = true) => ({
	type: SET_CHAT_CHAT_ENABLED,
	payload: { enabled },
})

/**
 * an action to open/close the chat widget
 *
 * @param {boolean} open – Whether or not the widget should be open
 */
export const setChatWindowOpenAction = (open = true) => ({
	type: SET_CHAT_WINDOW_OPEN,
	payload: { open },
})

/**
 * an action to indicate whether or not the HubSpot chat widget is loaded
 *
 * @param {Object} widget – Widget instance from window object
 */
export const setWidgetLoadedAction = (widget = null) => ({
	type: SET_WIDGET_LOADED,
	payload: { loaded: Boolean(widget), widget },
})
