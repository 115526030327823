// react imports
import { useState } from 'react'
import { useTheme } from '@emotion/react'
// local imports
import {
	Header,
	Layout,
	MultiSelectButton,
	MultiSelectGrid,
	ButtonGrid,
	NextButton,
	BackButton,
} from '&components'
import useNavigation from '&hooks/useNavigation'
import { clamp } from '&utils/css'
import { useSelection } from '&state/selection/context'
import { setConnectionAction, setOptions } from '&state/selection/actions'
import { InternetUsageView } from '../InternetUsage'

const Connection = () => {
	const { goToView, goBackward } = useNavigation()
	const theme = useTheme()
	const [{ connection: connectionOptions }, selectionDispatch] = useSelection()
	const [isInvalid, setIsInvalid] = useState(false)

	const backgroundColor = theme.palette.secondary.main

	const handleNext = () => {
		const hasItemsSelected =
			connectionOptions.filter((item) => item.selected).length > 0

		setIsInvalid(!hasItemsSelected)

		if (hasItemsSelected) {
			// We only store the isConnectedAtHome selection once the user clicks next
			const isConnectedAtHome = connectionOptions.some(
				(item) => item.value === 'Home Wi-Fi' && item.selected
			)

			//if user is not connected, fire facebook pixel event
			if (!isConnectedAtHome) {
				// eslint-disable-next-line no-undef
				if (fbq) {
					// eslint-disable-next-line no-undef
					fbq('trackCustom', 'Unconnected')
				}
			}

			selectionDispatch(setConnectionAction(isConnectedAtHome))
			goToView(InternetUsageView)
		}
	}

	const checkFunction = (value, isChecked) => {
		const checkedItem = connectionOptions.find((item) => item.value === value)

		selectionDispatch(setOptions('connection', checkedItem.value, isChecked))
	}

	const resetError = () => setIsInvalid(false)

	return (
		<Layout
			title={'Connection'}
			topBarText={'Qualification'}
			{...backgroundColor}>
			<Header
				sx={{
					fontSize: clamp('1.25em'),
					mb: theme.spacing(3),
					fontWeight: '500',
				}}>
				{'How do you connect to internet at home?'}
			</Header>
			<MultiSelectGrid
				error={isInvalid}
				resetError={resetError}
				checkFunction={checkFunction}
				label={'Select all that apply to you'}
				sx={{ mb: theme.spacing(5) }}>
				{connectionOptions.map((item, index) => (
					<MultiSelectButton
						key={index}
						isChecked={item.selected}
						icon={item.icon}
						value={item.value}
						ariaLabel={item.ariaLabel}
						dataGaId={item.dataGaId}
						dataCy={item.dataCy}
					/>
				))}
			</MultiSelectGrid>
			<ButtonGrid>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<NextButton onClick={handleNext} isCondensed />
			</ButtonGrid>
		</Layout>
	)
}

export default Connection

export const ConnectionView = { component: Connection, viewName: 'connection' }
