// react imports

import Lottie from 'lottie-react'
// local imports
import { Header, Layout, Copy, InviteBox } from '../../components'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import useExperiment from '&hooks/useExperiment'
//media
import OneIcon from '&assets/icons/1.svg'
import TwoIcon from '&assets/icons/2.svg'
import ThreeIcon from '&assets/icons/3.svg'
import congratsAnimation from '&assets/animations/congrats1.json'

const LifelineError = () => {
	const theme = useTheme()

	const { featureValue: socialComponentValue } =
		useExperiment('social-component')

	const backgroundColor = theme.palette.secondary.main

	/* TODO: Ask Shan/Marketing about the real texts that are going to be used.
	Maybe move them to their own file? */
	const sharingSubject = 'Learn about ACP!'
	const sharingText =
		'You are sharing this message in a different platform, visit https://beta.getacp.org for more info'

	return (
		<Layout title='Lifeline' topBarText='Result' {...backgroundColor}>
			<Lottie
				style={{ margin: 'auto', width: '160px' }}
				animationData={congratsAnimation}
				aria-hidden='true'
				data-visual-test='blackout'
			/>
			<Header component='h1' sx={{ pt: 3 }}>
				{'Congrats!'}
			</Header>
			<Copy large sx={{ pt: 3, mb: 4 }}>
				{`You are eligible. You can enroll in the ACP program using your Lifeline benefit.`}
			</Copy>
			<Box
				sx={{
					backgroundColor: 'almostWhite',
					p: 3,
					display: 'flex',
					flexDirection: 'column',
					borderRadius: '10px',
					mb: 3,
				}}>
				<Header component='h2' sx={{ fontSize: '20px', fontWeight: 500 }}>
					{'How to use your Lifeline benefit?'}
				</Header>
				<ol
					style={{
						listStyle: 'none',
						paddingInlineStart: '0px',
					}}>
					<li style={{ paddingBottom: '16px', display: 'flex' }}>
						<OneIcon aria-label='One' style={{ flexShrink: 0 }} />
						<Copy sx={{ flexShrink: 1, ml: 2, mb: 0 }}>
							{'Call your new or existing Internet Service Provider.'}
						</Copy>
					</li>
					<li style={{ paddingBottom: '16px', display: 'flex' }}>
						<TwoIcon aria-label='Two' style={{ flexShrink: 0 }} />
						<Copy sx={{ flexShrink: 1, ml: 2, mb: 0 }}>
							{
								'Let them know you receive Lifeline and want to apply the ACP benefit.'
							}
						</Copy>
					</li>
					<li style={{ paddingBottom: '16px', display: 'flex' }}>
						<ThreeIcon aria-label='Three' style={{ flexShrink: 0 }} />
						<Copy sx={{ flexShrink: 1, ml: 2, mb: 0 }}>
							{
								'The discount will be applied to your existing or new plan. Ask about specific ACP plans that may be cheaper than your current plan.'
							}
						</Copy>
					</li>
				</ol>
				<Copy italic sx={{ mb: 0 }}>
					{`If your Internet Service Provider can't help, reach out to the Lifeline Hotline at (877) 384-2575 for additional help.`}
				</Copy>
			</Box>
			{socialComponentValue && (
				<InviteBox sharingSubject={sharingSubject} sharingText={sharingText} />
			)}
		</Layout>
	)
}

export default LifelineError

export const LifelineErrorView = {
	component: LifelineError,
	viewName: 'lifeline-error',
}
