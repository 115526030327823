import { paths } from '../../constants/pathSteps'

export const applicationProcessReducer = (state = {}, action) => {
	switch (action.type) {
		case 'SET_APPLICATION_STEPS': {
			return {
				...state,
				currentStepIndex: 0,
				steps: action.payload,
			}
		}
		case 'SET_CURRENT_APPLICATION_STEP': {
			const steps = state.steps || []
			if (steps.find((step) => step.viewName === action.payload)) {
				const nextStepIndex = steps.findIndex(
					(step) => step.viewName === action.payload
				)
				if (nextStepIndex > state.currentStepIndex) {
					steps[state.currentStepIndex].isCompleted = true
				}
				return {
					...state,
					steps,
					currentStepIndex: nextStepIndex,
				}
			}
			return state
		}
		case 'SET_RESULT': {
			// saves the user's result to the last step in the steps array
			const { steps } = state
			const isStepCompleted = !(action.payload.viewName === 'upload-id')
			steps[steps.length - 1] = {
				...action.payload,
				isCompleted: isStepCompleted,
			}

			return {
				...state,
				steps,
			}
		}
		case 'SET_PATH': {
			/* Here, we update the logic of path progress as well as the pathname the user
			currently finds themselves on */

			const currentStepIndex =
				state.currentStepIndex < 0 ? 0 : state.currentStepIndex
			/* We get two different arrays, one with the already completed steps, and
			one with those to replace from the new path, then we combine them. */
			const completedSteps = state.steps.slice(0, state.currentStepIndex)
			const updatedSteps = paths[action.payload].slice(currentStepIndex)
			const steps = [...completedSteps, ...updatedSteps]

			return {
				...state,
				pathName: action.payload,
				currentStepIndex,
				steps,
			}
		}
		default:
			return state
	}
}

export default applicationProcessReducer
