const enMonthList = [
	{ label: 'January', value: '01' },
	{ label: 'February', value: '02' },
	{ label: 'March', value: '03' },
	{ label: 'April', value: '04' },
	{ label: 'May', value: '05' },
	{ label: 'June', value: '06' },
	{ label: 'July', value: '07' },
	{ label: 'August', value: '08' },
	{ label: 'September', value: '09' },
	{ label: 'October', value: '10' },
	{ label: 'November', value: '11' },
	{ label: 'December', value: '12' },
]

const esMonthList = [
	{ label: 'Enero', value: '01' },
	{ label: 'Febrero', value: '02' },
	{ label: 'Marzo', value: '03' },
	{ label: 'Abril', value: '04' },
	{ label: 'Mayo', value: '05' },
	{ label: 'Junio', value: '06' },
	{ label: 'Julio', value: '07' },
	{ label: 'Agosto', value: '08' },
	{ label: 'Septiembre', value: '09' },
	{ label: 'Octubre', value: '10' },
	{ label: 'Noviembre', value: '11' },
	{ label: 'Diciembre', value: '12' },
]

export { enMonthList, esMonthList }
