// react imports

// local imports
import { Header, Layout } from '../../components'
import { useTheme } from '@emotion/react'

const ViewTemplate = () => {
	const theme = useTheme()

	const backgroundColor = theme.palette.secondary.main

	return (
		<Layout title='title' topBarText='title' {...backgroundColor}>
			<Header>{'This is just a template'}</Header>
		</Layout>
	)
}

export default ViewTemplate

export const ViewTemplateView = {
	component: ViewTemplate,
	viewName: 'view-template',
	//add icon and displayName if shown in the NavProgressBar
}
