import {
	SET_CHAT_CHAT_ENABLED,
	SET_CHAT_WINDOW_OPEN,
	SET_WIDGET_LOADED,
} from './actions'

/**
 * a reducer to reflect the state of the HubSpot chat widget
 */
const hubSpotChatReducer = (state, action) => {
	switch (action.type) {
		case SET_CHAT_CHAT_ENABLED: {
			const { enabled } = action.payload

			return { ...state, enabled }
		}
		case SET_CHAT_WINDOW_OPEN: {
			const { open } = action.payload

			return { ...state, open }
		}
		case SET_WIDGET_LOADED: {
			const { loaded } = action.payload

			return {
				...state,
				loaded,
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default hubSpotChatReducer
