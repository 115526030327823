// react imports
import { useTheme } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
// other external dependencies
import { Box } from '@mui/material'
// local imports
import EditIcon from '&assets/icons/edit.svg'
import ReviewIcon from '&assets/icons/magnifying-glass.svg'
import {
	Button,
	Copy,
	Header,
	Layout,
	IconBase,
	ButtonGrid,
	BackButton,
} from '&components'
import { CheckboxInput, EditInfoInput } from '&components/FormInputs'
import config from '&config'
import { setConsentGiven } from '&state/selection/actions'
import { useSelection } from '&state/selection/context'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { PathValues, IDTypes } from '&constants/enums/pathAndId'
import { getMonthLabel } from '&utils/dropdownConstants'
import { getIDType } from '&utils/identification'
//hooks
import useExperiment from '&hooks/useExperiment'
import useFetchContactData from '&hooks/useFetchContactData'
import useNavigation from '&hooks/useNavigation'
import useReCaptcha from '&hooks/useReCaptcha'
import useFetchEligibility from '&hooks/useFetchEligibility'

const Review = () => {
	// react hooks
	const [editMode, toggleEditMode] = useState(false)
	const [showId, toggleShowId] = useState(false)
	const [consentInvalid, setConsentInvalid] = useState(false)
	const [isPageLoading, setIsPageLoading] = useState(false)
	// third party hooks
	const theme = useTheme()
	// custom hooks
	const { goBackward, goToView } = useNavigation()
	const [
		{
			alternateIdIndicator,
			name,
			dob,
			phone,
			email,
			address,
			ssn,
			tribalId,
			consentGiven,
		},
		selectionDispatch,
	] = useSelection()
	const { executeRecaptcha } = useReCaptcha()
	const {
		loading: eligibilityLoading,
		callEligibilityCheck,
		destination: destinationView,
	} = useFetchEligibility()
	const [{ pathName }] = useApplicationProcessContext()
	const { featureValue: enableLifelineTribal } = useExperiment(
		'v2-enable-lifelinetribal'
	)
	const { featureValue: enableVUDS } = useExperiment('v2-enable-vuds-rawdata')

	const backgroundColor = theme.palette.secondary.main

	const { firstName, lastName } = name
	const { month, day, year } = dob
	const { street, aptUnit, city, stateCode, zipCode } = address

	const { storeContactData } = useFetchContactData()

	const hasFetched = useRef(false)
	const currIdType = getIDType({ ssn, tribalId, alternateIdIndicator })

	useEffect(() => {
		// if the call is loading, set the page to load
		if (eligibilityLoading) setIsPageLoading(true)
	}, [eligibilityLoading])

	useEffect(() => {
		//listen for a destination, set in useFetchEligibility, then go to it
		if (destinationView && !eligibilityLoading) {
			goToView(destinationView)
		}
	}, [destinationView, eligibilityLoading])

	useEffect(() => {
		if (editMode) {
			//shift focus to revealed content
			const firstInput = document.getElementById('given-name')
			firstInput.focus()
		}
	}, [editMode])

	const handleConsent = () => {
		selectionDispatch(setConsentGiven(!consentGiven))
	}

	const handleSubmit = async () => {
		const isValidConsent = consentGiven
		setConsentInvalid(!isValidConsent)

		if (isValidConsent) {
			if (!hasFetched.current) {
				//get new captcha token
				const token = await executeRecaptcha(
					config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
				)
				await callEligibilityCheck(token)
				hasFetched.current = true

				// Sending contact data to DB
				if (enableVUDS) {
					const reviewSaveToken = await executeRecaptcha(
						config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
					)
					storeContactData(reviewSaveToken, {
						firstName,
						lastName,
						email,
						phone,
					})
				}
			}
		}
	}
	//only show progress bars if the path is not status check
	const showProgressBars = Boolean(pathName !== PathValues.STATUS_CHECK)

	//TODO: simplify this when removing 'v2-enable-lifelinetribal'
	const usingSSN = Boolean(!enableLifelineTribal || currIdType === IDTypes.SSN)

	//we want the number of xs in the obscured tribal id string to match what a user has entered
	const getTribalXString = () =>
		tribalId ? 'X'.repeat(tribalId.length) : 'XXXXXXXX'

	const displayedSSN = `XXX-XX-${showId ? ssn : 'XXXX'}`
	const displayedTribal = showId ? tribalId : getTribalXString()

	return (
		<Layout
			title='Review'
			topBarText={'Application'}
			showProgressBars={showProgressBars}
			isLoading={isPageLoading}
			{...backgroundColor}>
			<Header
				component='h2'
				sx={{ fontWeight: 800, fontSize: 31, lineHeight: '150%', pb: 3 }}>
				{'Does this look correct?'}
			</Header>
			{editMode ? (
				<EditInfoInput
					toggleEditMode={toggleEditMode}
					isContained
					nameTitle='Basic Info'
					sx={{ mx: -3 }}
				/>
			) : (
				<Box sx={{ py: 2 }}>
					<div style={{ borderRadius: '10px', overflow: 'hidden' }}>
						<Box
							sx={{
								backgroundColor: 'almostWhite',
								p: 3,
								display: 'flex',
								flexDirection: 'column',
							}}>
							<div
								style={{
									display: 'inline-flex',
									justifyContent: 'space-between',
									width: '100%',
								}}>
								<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
									{'Basic Info'}
								</Header>
								<Button
									variant='text'
									data-cy='review-edit-button'
									data-ga-id='edit'
									onClick={() => toggleEditMode(true)}
									aria-label={'Edit information'}
									startIcon={
										<IconBase size='small' icon={EditIcon} aria-hidden='true' />
									}
									sx={{
										width: '60px',
										height: '24px',
										fontWeight: 400,
										py: 0,
										px: 0,
										'&:hover, &:focus': {
											color: 'veryDark',
											backgroundColor: 'unset',
											textDecoration: 'underline',
										},
									}}>
									{'Edit'}
								</Button>
							</div>
							<Copy sx={{ fontWeight: 700, mb: 2 }}>{'Legal First Name'}</Copy>
							<Copy
								sx={{ mb: 2 }}
								className='weglot_exclude'>{`${firstName}`}</Copy>
							<Copy sx={{ fontWeight: 700, mb: 2 }}>{'Legal Last Name'}</Copy>
							<Copy
								sx={{ mb: 2 }}
								className='weglot_exclude'>{`${lastName}`}</Copy>
							<Copy sx={{ fontWeight: 700, mb: 2 }}>{'Date of Birth'}</Copy>
							<Copy sx={{ mb: 2 }}>
								{`${getMonthLabel(month)}`}
								<span className='weglot_exclude'>{`-${day}-${year}`}</span>
							</Copy>
						</Box>
						<Box
							sx={{
								backgroundColor: 'almostYellow',
								p: 3,
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
								{'Contact'}
							</Header>
							{email && (
								<Copy
									sx={{ mb: 2 }}
									className='weglot_exclude'>{`${email}`}</Copy>
							)}
							{phone && (
								<Copy
									sx={{ mb: 2 }}
									className='weglot_exclude'>{`${phone}`}</Copy>
							)}
						</Box>
						<Box
							sx={{
								backgroundColor: 'almostWhite',
								p: 3,
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
								{'Address'}
							</Header>
							<Copy
								sx={{ mb: 0 }}
								className='weglot_exclude'>{`${street} ${aptUnit}`}</Copy>
							<Copy
								sx={{ mb: 2 }}
								className='weglot_exclude'>{`${city}, ${stateCode} ${zipCode}`}</Copy>
						</Box>
						{!alternateIdIndicator && (
							<Box
								sx={{
									backgroundColor: 'almostYellow',
									p: 3,
									flexDirection: 'column',
								}}>
								<Header component='h3' sx={{ fontWeight: 700, mb: 2 }}>
									{'Identification'}
								</Header>
								<Copy sx={{ fontWeight: 700, mb: 2 }}>
									{usingSSN ? 'Last Four Digits of SSN' : 'Tribal ID'}
								</Copy>
								<div style={{ display: 'flex' }}>
									<Copy
										sx={{ mb: 0 }}
										data-cy='selected-id-number'
										className='weglot_exclude'>
										{usingSSN ? displayedSSN : displayedTribal}
									</Copy>
									<Button
										variant='text'
										aria-label={`${showId ? 'Hide' : 'Show'} ${
											usingSSN ? 'SSN' : 'Tribal ID'
										}`}
										sx={{ ml: 1, lineHeight: '24px', fontWeight: '400' }}
										data-cy='show-id-button'
										onClick={() => {
											toggleShowId(!showId)
										}}>
										{showId ? 'Hide' : 'Show'}
									</Button>
								</div>
							</Box>
						)}
					</div>
					<Box sx={{ pt: 3 }}>
						<Copy sx={{ mb: 0 }}>
							{`By clicking "Submit," your information will be shared with the Universal Service Administrative Company (the federal administrator of the ACP), a government agency that helps communities and people across the U.S. stay connected to the information, resources, and care they need. We or USAC may follow up about your application using the phone number or email provided.`}
						</Copy>
						<CheckboxInput
							isRequired
							isInvalid={consentInvalid}
							onCheckboxChange={handleConsent}
							checked={consentGiven}
							errorText={`Please check the “I agree” box to proceed.`}
							checkboxLabel={`I understand and agree.`}
							id='consent'
							sx={{ py: 3 }}
							data-ga-id='checkbox_agree-submit-to-usac'
							data-cy={'select-agree-terms-box'}
						/>
					</Box>
					<ButtonGrid sx={{ mt: 5 }}>
						<BackButton isCondensed onClick={goBackward} variant='outlined' />
						<Button
							onClick={handleSubmit}
							data-ga-id='submit-application'
							data-cy='submit-application-btn'
							isCondensed>
							{'Submit'}
						</Button>
					</ButtonGrid>
				</Box>
			)}
		</Layout>
	)
}

export default Review
export const ReviewView = {
	icon: ReviewIcon,
	displayName: 'Review',
	component: Review,
	viewName: 'review',
}
