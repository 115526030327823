// react imports

// local imports
import {
	Button,
	Copy,
	Header,
	IconBase,
	Layout,
	NeedHelp,
} from '../../components'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import Pineapple from '&assets/icons/pineapple.svg'
import LeavePageIcon from '&assets/icons/arrow45degree.svg'
import useNavigation from '&hooks/useNavigation'
import { BenefitsView } from '&views/Benefits'

const AppNotFound = () => {
	const theme = useTheme()
	const { goToView } = useNavigation()

	const backgroundColor = theme.palette.secondary.main

	return (
		<Layout
			title='Application Not Found'
			topBarText='Status Check'
			{...backgroundColor}>
			<Box sx={{ display: 'flex', justifyContent: 'center', pb: 4, pt: 3 }}>
				<Pineapple style={{ height: theme.spacing(5.6) }} />
			</Box>
			<Header component='h1'>{'Sorry...'}</Header>
			<Copy data-cy='app-not-found-blurb' sx={{ mt: 3, mb: 0 }}>
				{
					'Your application was not found. It may have expired. Please submit a new application. If you think this is an error, please contact the Universal Service Administration Company (USAC, the federal administrator of the ACP).'
				}
			</Copy>
			<Box sx={{ mt: 4 }}>
				<Button
					data-ga-id='start-new-app'
					sx={{ mb: 3 }}
					isCondensed
					onClick={() => goToView(BenefitsView)}>
					{'Start a new application'}
				</Button>
				<Button
					data-ga-id='usac-apply'
					isCondensed
					variant='outlined'
					endIcon={<IconBase size='small' icon={LeavePageIcon} />}
					onClick={() => window.open('https://getinternet.gov/apply')}>
					{'Go to USAC’s website'}
				</Button>
			</Box>
			<NeedHelp sx={{ mt: 'calc(30px + 64px)' }} />
		</Layout>
	)
}

export default AppNotFound

export const AppNotFoundView = {
	component: AppNotFound,
	viewName: 'app-not-found',
}
