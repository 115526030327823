// react imports
import { useState } from 'react'
import { useTheme } from '@emotion/react'
// local imports
import {
	Header,
	Layout,
	Copy,
	MultiSelectButton,
	MultiSelectGrid,
	ButtonGrid,
	NextButton,
	BackButton,
	ChatButton,
} from '&components'
import { useSelection } from '&state/selection/context'
import { setOptions } from '&state/selection/actions'
import useNavigation from '&hooks/useNavigation'
import { ZipCodeView } from '../ZipCode'
import {
	getSelectedBenefits,
	getPrioritizedBenefit,
} from '&utils/qualificationPrioritization'
import useExperiment from '&hooks/useExperiment'
import { FederalHousingView } from '../FederalHousing'
import { TribalBenefitView } from '../TribalBenefit'

const Benefits = () => {
	const [{ benefits }, selectionDispatch] = useSelection()
	const [isInvalid, setIsInvalid] = useState(false)
	const { goBackward, goToView } = useNavigation()
	const { featureValue: enableLifelineTribal } = useExperiment(
		'v2-enable-lifelinetribal'
	)

	const theme = useTheme()

	const handleNext = () => {
		const selectedBenefits = getSelectedBenefits(benefits)
		const hasItemsSelected = selectedBenefits.length > 0

		setIsInvalid(!hasItemsSelected)

		if (hasItemsSelected) {
			const prioritizedBenefit = getPrioritizedBenefit(selectedBenefits)

			if (prioritizedBenefit?.id === 'federalHousing') {
				goToView(FederalHousingView)
			} else if (prioritizedBenefit?.id === 'tribal') {
				goToView(TribalBenefitView)
			} else {
				goToView(ZipCodeView)
			}
		}
	}

	const checkFunction = (value, isChecked) => {
		const checkedKey = benefits.find((item) => item.value === value).value

		selectionDispatch(setOptions('benefits', checkedKey, isChecked))
	}

	const resetError = () => setIsInvalid(false)

	const backgroundColor = theme.palette.secondary.main

	return (
		<Layout
			topBarText={'Qualification'}
			title={'Benefits'}
			{...backgroundColor}>
			<Header
				sx={{
					fontSize: '1.25em',
					mb: theme.spacing(3),
					fontWeight: '500',
				}}>
				{'You are eligible if you receive any of these benefits.'}
			</Header>
			<MultiSelectGrid
				error={isInvalid}
				resetError={resetError}
				checkFunction={checkFunction}
				label={'Select all that apply to you. Don’t worry if you miss one.'}
				sx={{ mb: theme.spacing(5) }}>
				{benefits
					.filter(
						// include all options if enableLifelineTribal true,
						// otherwise only include those except lifeline, tribal
						(el) =>
							enableLifelineTribal || !['tribal', 'lifeline'].includes(el?.id)
					)
					.map((item, index) => (
						<MultiSelectButton
							key={index}
							isChecked={item.selected}
							icon={item.icon}
							value={item.value}
							ariaLabel={item.ariaLabel}
							dataGaId={item.dataGaId}
							dataCy={item.dataCy}
						/>
					))}
			</MultiSelectGrid>
			<Copy sx={{ mb: theme.spacing(5) }}>
				{`Have questions?`}{' '}
				<ChatButton linkOnly data-ga-id='live-chat_eligibility' />
			</Copy>
			<ButtonGrid>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<NextButton
					onClick={handleNext}
					sx={{
						maxWidth: 'min-content',
						fontSize: '1em',
					}}
					isCondensed
				/>
			</ButtonGrid>
		</Layout>
	)
}

export default Benefits

export const BenefitsView = { component: Benefits, viewName: 'benefits' }
