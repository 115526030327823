import { useLayoutEffect, useState } from 'react'

/**
 * A custom hook to listen for window size changes.
 *
 * @returns {object} - An object containing the window width and height.
 */
export const useWindowSize = () => {
	// TODO? add in dynamicViewport and largeViewport
	const [size, setSize] = useState({ height: 0, width: 0 })

	useLayoutEffect(() => {
		function updateSize() {
			const layoutBox = document.getElementById('layout-box')

			if (layoutBox instanceof HTMLElement) {
				let { height, width } = window.getComputedStyle(layoutBox)
				height = parseInt(height)
				width = parseInt(width)
				setSize({
					height,
					width,
				})
			}
		}

		window.addEventListener('resize', updateSize)
		updateSize()

		return () => window.removeEventListener('resize', updateSize)
	}, [document])

	return size
}
