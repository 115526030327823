/// National Verifier-related convenience functions

/**
 * @param {String} uuid: Locally-generated id for a NV ACP eligibility application
 * @param {String} eligibilityId: ID returned by NV
 * @returns {void}
 */
export const storeEligibilityID = (uuid, applicationId, eligibilityId) => {
	localStorage.setItem(`eligibilityId:${uuid}`, eligibilityId)
	localStorage.setItem(`applicationId:${applicationId}`, eligibilityId)
}

/**
 * @param {String} uuid: Locally-generated id for a NV ACP eligibility application
 * @returns {String} NV-generated ID that was returned for ACP eligibility application associated with {uuid}
 */
export const getEligibilityID = (uuid) =>
	localStorage.getItem(`eligibilityId:${uuid}`)

/**
 * @param {String} applicationId: NV-generated ID that was returned for the user to apply to ACP
 * @returns {String} NV-generated ID that was returned for ACP eligibility application associated with {uuid}
 */
export const getEligibilityIdByApplicationId = (applicationId) =>
	localStorage.getItem(`applicationId:${applicationId}`)
