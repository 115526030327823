import { Select, MenuItem, Box, useTheme, Fade } from '@mui/material'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { useEffect, useState } from 'react'
import { IconBase } from '..'
import LanguageBlack from '&assets/icons/language-black.svg'
import LanguageWhite from '&assets/icons/language-white.svg'
import { useWeglot } from '&state/weglot/context'
import { LanguageCodes, languageArray } from '&constants/enums/languages'

/**
 * Drop down selector the will allow the user to select different languages.
 * @param {boolean} hasBackdrop - If true, sets the style of the input to match the topbar backdrop.
 * @param {boolean} opensUp - If true, sets the styling so that the Menu opens upwards.
 */
const LanguageSelector = ({ hasBackdrop = false, opensUp = false }) => {
	const [selectedLanguage, setSelectedLanguage] = useState(LanguageCodes.EN)
	const [isSelectOpen, setIsSelectOpen] = useState(false)
	const [{ currentLanguage }] = useWeglot()

	const theme = useTheme()

	const Weglot = window.Weglot

	useEffect(() => {
		if (currentLanguage) {
			setSelectedLanguage(currentLanguage)
		}
	}, [currentLanguage])

	const handleChange = (e) => {
		const selected = e.target.value
		Weglot?.switchTo(selected)
	}

	const baseSelectStyles = {
		borderRadius: 2,
		'.MuiInputBase-input': {
			paddingTop: 2,
			paddingBottom: 2,
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	}

	const topBarSelectStyles = {
		color: isSelectOpen ? 'veryDark' : 'almostWhite',
		backgroundColor: isSelectOpen ? 'white' : 'transparent',
		fontWeight: isSelectOpen ? 400 : 700,
		'&.Mui-focused': {
			outline: isSelectOpen
				? 'none'
				: `solid 2px ${theme.palette.primary.main}`,
		},
		'.MuiSelect-icon': {
			color: isSelectOpen ? 'veryDark' : 'almostWhite',
		},
		transition: 'background-color ease-in-out 0.3s',
	}

	const footerSelectStyles = {
		color: 'veryDark',
		'&.Mui-focused': {
			outline: `solid 2px ${theme.palette.secondary.main}`,
		},
		'.MuiSelect-icon': {
			color: 'veryDark',
			transform: 'rotate(180deg)',
		},
	}

	const menuStyles = {
		'.MuiList-root': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'.MuiPaper-root': {
			borderRadius: 2,
		},
		'.MuiButtonBase-root': {
			'&:first-of-type': {
				borderRadius: '8px 8px 0 0',
			},
			'&:last-child': {
				borderRadius: '0 0 8px 8px',
			},
			backgroundColor: 'transparent',
			outlineOffset: '-2px',
			'&:hover, &:focus': {
				backgroundColor: 'white',
				outline: `solid 2px ${theme.palette.secondary.main}`,
				boxShadow: '0px 0px 4px #009296',
			},
			/* We neet to set the styles again for selected items because the extra css selector 
			overrides changes */
			'&.Mui-selected': {
				backgroundColor: 'white',
				'&:hover, &:focus': {
					backgroundColor: 'white',
					outline: `solid 2px ${theme.palette.secondary.main}`,
					boxShadow: '0px 0px 4px #009296',
				},
			},
		},
	}

	const menuItemStyles = {
		paddingTop: 2,
		paddingBottom: 2,
		minHeight: 0,
	}

	const sortOptions = (languages) => {
		const sorted = languages.sort(function (x, y) {
			/*This sorting function puts selected values on top or bottom depending on 
			the position of the selector. We need them on top for the top bar and on the 
			bottom for the footer*/
			return (x.code === selectedLanguage) === (y.code === selectedLanguage)
				? 0
				: (opensUp ? x.code !== selectedLanguage : x.code === selectedLanguage)
				? -1
				: 1
		})
		return sorted
	}

	const isCurrentLanguage = (code) => {
		return code === selectedLanguage
	}

	return (
		<Select
			inputProps={{
				'aria-label': 'Select language',
			}}
			data-cy='weglot-custom-switcher'
			value={selectedLanguage}
			renderValue={(value) => (
				<Box
					component={'span'}
					className='weglot_exclude'
					sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
					{isSelectOpen || !hasBackdrop ? (
						<IconBase size='small' icon={LanguageBlack} />
					) : (
						<IconBase size='small' icon={LanguageWhite} />
					)}
					{languageArray.find((item) => item.code === value).label}
				</Box>
			)}
			onOpen={() => setIsSelectOpen(true)}
			onClose={() => setIsSelectOpen(false)}
			onChange={handleChange}
			MenuProps={{
				sx: { ...menuStyles },
				// Here we're placing the menu on top of the base input to show only our 2 options.
				anchorOrigin: {
					vertical: opensUp ? 'bottom' : 'top',
					horizontal: 'left',
				},
				transformOrigin: {
					vertical: opensUp ? 'bottom' : 'top',
					horizontal: 'left',
				},
				TransitionComponent: Fade,
			}}
			sx={{
				...baseSelectStyles,
				...(hasBackdrop ? topBarSelectStyles : footerSelectStyles),
			}}>
			{sortOptions(languageArray).map((item, index) => (
				<MenuItem
					sx={{
						paddingLeft: isCurrentLanguage(item.code) ? '14px' : '38px',
						...menuItemStyles,
					}}
					className='weglot_exclude'
					data-cy={`weglot-language-${item.code}`}
					data-ga-id={`${opensUp ? 'footer_' : ''}weglot-${item.name}`}
					value={item.code}
					lang={item.code}
					key={index}>
					{isCurrentLanguage(item.code) && (
						<IconBase
							size='small'
							sx={{ marginRight: '8px' }}
							icon={LanguageBlack}
						/>
					)}

					{item.label}

					{isCurrentLanguage(item.code) && (
						<ArrowDropUp
							sx={{
								display: 'inline-block',
								position: 'absolute',
								// Got this value from the absolute positioning of Mui's other arrow
								right: '7px',
								transform: `rotate(${opensUp ? '180' : '0'}deg)`,
							}}
						/>
					)}
				</MenuItem>
			))}
		</Select>
	)
}

export default LanguageSelector
