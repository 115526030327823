const IDTypes = {
	SSN: 'ssn',
	TRIBAL: 'tribal',
	ID: 'id', //aka alternate id
}

const PathValues = {
	SSN: IDTypes.SSN,
	TRIBAL: IDTypes.TRIBAL,
	ID: IDTypes.ID, //uses alternate id
	STATUS_CHECK: 'status_check',
}

export { PathValues, IDTypes }
