import { Button } from '..'

/**
 * Next button to use in views. Besides data properties and inner text, args
 * should be no different than the ones used in Button component.
 * @param {object} args - Other props
 */
const NextButton = ({ ...args }) => {
	return (
		<Button data-ga-id='next-button' data-cy='next-btn' {...args}>
			{'Next'}
		</Button>
	)
}

export default NextButton
