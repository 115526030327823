import { createContext, useContext, useReducer } from 'react'
import applicationProcessReducer from './reducer'
import {
	ErrorView,
	APIFeedbackView,
	UploadIDView,
	ApprovedView,
	SSNErrorView,
} from '&views'

const initialState = {
	pathName: undefined,
	currentStepIndex: -1,
	steps: [],
	// include all view options for a user's result
	results: [
		ErrorView,
		APIFeedbackView,
		UploadIDView,
		ApprovedView,
		SSNErrorView,
	],
}

// the ApplicationProcess Context keeps track of where in the application process the user is
const ApplicationProcessContext = createContext(initialState)

const ApplicationProcessProvider = ({ children }) => {
	const [state, dispatch] = useReducer(applicationProcessReducer, initialState)

	return (
		<ApplicationProcessContext.Provider value={[state, dispatch]}>
			{children}
		</ApplicationProcessContext.Provider>
	)
}

/**
 * rather than importing React.useContext and ApplicationProcessContext wherever we want to get
 * application process details, we export a hook which does that for us, so we need only one import
 * elsewhere.
 */
const useApplicationProcessContext = () => {
	const context = useContext(ApplicationProcessContext)
	if (context === undefined) {
		throw new Error(
			'useApplicationProcess must be used within a ApplicationProcessProvider'
		)
	}
	return context
}

export {
	ApplicationProcessContext,
	ApplicationProcessProvider,
	useApplicationProcessContext,
}
