/**
 * This is a Redux-style reducer for the National Verifier Context.
 *
 * @param {React.state} state – the current Selection state
 * @param {Object} action - an action object
 * @returns {Object} – the new Selection state
 */
const nvReducer = (state, action) => {
	switch (action.type) {
		case 'setApplicationStatus': {
			return {
				...state,
				applicationId: action.applicationId,
				eligibilityId: action.eligibilityId,
				failures: action.failures,
				applicationStatus: action.status,
				link:
					action._links?.resolution?.href || action._links?.certification?.href,
			}
		}
		case 'setUUID': {
			return {
				...state,
				uuid: action.uuid,
			}
		}
		case 'setResult': {
			const { response, error, loading } = action.result

			return {
				...state,
				response,
				error,
				loading,
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default nvReducer
