import { IconButton } from '@mui/material'
import IconBase from '../IconBase'
import Checkmark from '../../assets/icons/checkmark.svg'
import Copy from '../Copy'
import StepStatus from '&constants/enums/stepStatus'

/**
 * NavIconWrapper is the wrapper component for the icons in the navigation bar.
 * It renders the icon, background, and text, and styles them based on the status prop.
 * Please translate all passed text parameters.
 *
 * @param {object} icon - The icon to be rendered.
 * @param {string} text - The text to be rendered (translate in parent).
 * @param {string} ariaLabel - The aria-label for the icon and button (translate in parent).
 * @param {function} [onClick] - The function to be called when the button is clicked, if in 'visited' status.
 * @param {string} status [d=StepStatus.FUTURE] - The status of the step. Can be 'future', 'visited', or 'current'. See StepStatus enum
 * @param {boolean} [disabled] [d=true] - Whether or not the button is clickable. Defaults to true.
 * @param {string} [dataGaId] - The data-ga-id for the button.
 * */
const NavIconWrapper = ({
	id,
	icon,
	text,
	ariaLabel,
	onClick,
	status = StepStatus.FUTURE,
	disabled = true,
	...args
}) => {
	const baseStyles = {
		width: '48px',
		height: '48px',
		border: 'none',
		borderRadius: '50%',
		// Moving all states to a single object and targetting through classnames
		backgroundColor: '#FED571',
		'&:hover, &:focus': {
			backgroundColor: '#FED571',
			border: '2px solid #009296',
			boxShadow: '0px 0px 10px rgba(0, 146, 150, 0.5)',
		},
		'&.Mui-disabled': {
			backgroundColor: '#F6F5F1',
			'&:hover, &:focus': {
				border: 'none',
				boxShadow: 'none',
			},
		},
	}

	const checkmarkStyles = {
		position: 'absolute',
		top: '1px',
		right: '0',
	}

	return (
		<div
			id={id}
			style={{
				display: 'inline-flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<IconButton
				onClick={!disabled ? onClick : undefined}
				disabled={disabled}
				sx={{
					...baseStyles,
					cursor: !disabled ? 'pointer' : 'default',
				}}
				data-cy={id}
				aria-current={status === StepStatus.CURRENT ? 'page' : undefined}
				aria-label={ariaLabel}
				disableFocusRipple
				{...args}>
				<IconBase
					sx={{ pointerEvents: 'none' }}
					icon={icon}
					size='medium'
					aria-hidden='true'
				/>
				{status === StepStatus.COMPLETED && (
					<IconBase
						icon={Checkmark}
						size='small'
						sx={checkmarkStyles}
						data-cy={`${id}-checkmark`}
						aria-label='Completed'
					/>
				)}
			</IconButton>
			<Copy aria-hidden={true} sx={{ mb: 0 }}>
				{text}
			</Copy>
		</div>
	)
}

export default NavIconWrapper
