/**
 * Definitions for responses from the NV Eligibility API
 * @typedef {Object} NVEligibilityAPIResponse
 * @property {string} applicationId
 * @property {string} eligibilityCheckId
 * @property {string[]} [failures]
 * @property {string} status
 * @property {string} eligibilityExpirationDate
 * @property {string} activeSubscriber
 * @property {Object} [_links]
 * @property {Object} [_links.resolution]
 * @property {string} [_links.resolution.href]
 * @property {Object} [_links.certification]
 * @property {string} [_links.certification.href]
 */

/**
 * This action is for setting variable values returned by NV Eligibility API calls
 *
 * @param {NVEligibilityAPIResponse}
 */
export const setApplicationStatusAction = ({
	applicationId,
	eligibilityCheckId,
	status,
	failures,
	_links,
}) => ({
	type: 'setApplicationStatus',
	applicationId,
	eligibilityCheckId,
	status,
	failures,
	_links,
})

/**
 * This action is for setting the locally-generated UUID associated with the current
 * sessions' latest eligibility check
 *
 * @param {string} uuid
 */
export const setUUIDAction = (uuid) => ({
	type: 'setUUID',
	uuid,
})

/**
 * This action is for setting the result of the NV callback
 * @param {Object} result
 */
export const setResultAction = (result) => ({
	type: 'setResult',
	result,
})
