// react imports
import { Box, IconButton, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'

// local imports
import { setChatWindowOpenAction } from '&state/hubSpotChat/actions'
import { useHubSpotChat } from '&state/hubSpotChat/context'

/**
 * This component houses the HubSpot chat widget iframe
 */
const HubSpotChatContainer = () => {
	const theme = useTheme()
	const isSmMobile = useMediaQuery(theme.breakpoints.down('380'))
	const [{ open }, chatDispatch] = useHubSpotChat()

	const handleCloseIconClick = () =>
		chatDispatch(setChatWindowOpenAction(false))

	return (
		<Box
			id='esh-hubspot-widget-container'
			sx={{
				zIndex: '2147483646',
				position: 'fixed',
				bottom: '0',
				right: isSmMobile ? '0' : '10px',
				display: open ? 'initial' : 'none',
				width: isSmMobile ? '100vw' : '315px',
				height: isSmMobile ? '60vh' : '420px',
				boxShadow: '0 0 10px 1px #808080',
				'& iframe': {
					display: 'initial',
					width: '100%',
					height: '100%',
					border: 'none',
					position: 'absolute',
					bottom: '0',
					right: '0',
					background: 'transparent',
				},
				'& #chat-widget-launcher': {
					display: 'none',
				},
			}}>
			<IconButton
				id='chat-close-button'
				edge='end'
				color='inherit'
				onClick={handleCloseIconClick}
				aria-label={'Close chat window'}
				sx={{
					zIndex: '2147483647',
					position: 'absolute',
					right: '1em',
					top: '.6em',
					color: 'white',
				}}>
				<CloseIcon data-ga-id='chat-close-button' />
			</IconButton>
		</Box>
	)
}

export default HubSpotChatContainer
