import { Box } from '@mui/material'
import Header from '../Header'
import Copy from '../Copy'
import ButtonGrid from '../ButtonGrid'
import ChatButton from '../ChatButton'

/**
 * The NeedHelp component houses the ChatButton component and decoration for sections where users need help.
 *
 * @param {object} [sx] - (optional) The styles to be added to the parent container
 */
const NeedHelp = ({ sx }) => {
	const emily = new URL(
		'../../assets/images/emily.png?as=webp&width=56',
		import.meta.url
	)
	const sandra = new URL(
		'../../assets/images/sandra.png?as=webp&width=56',
		import.meta.url
	)

	return (
		<Box
			sx={{
				position: 'relative',
				backgroundColor: 'almostWhite',
				borderRadius: '26px',
				mb: 3,
				mt: 4,
				...sx,
			}}>
			<Box
				sx={{
					position: 'absolute',
					top: -30,
					left: '50%',
					transform: 'translateX(-50%)',
				}}>
				<img
					src={sandra}
					alt={'One of our specialists, Sandra'}
					style={{ width: '56px' }}
				/>
				<img
					src={emily}
					alt={'One of our specialists, Emily'}
					style={{ width: '56px' }}
				/>
			</Box>
			<Header
				component='h2'
				center
				sx={{
					pt: 4,
					fontSize: '1.25em',
					fontWeight: 500,
					lineHeight: '30px',
				}}>
				{'Need help?'}
			</Header>
			<Copy center>{'Chat with our specialists.'}</Copy>
			<ButtonGrid center sx={{ m: 0, pb: 4 }}>
				<ChatButton
					data-ga-id='live-chat_need-help'
					id='chat-button-need-help'
				/>
			</ButtonGrid>
		</Box>
	)
}

export default NeedHelp
