/**
 * this util finds the date of the ACP sign up deadline (90 days from the date the user receives the application ID).
 */

export const getSignUpDeadline = () => {
	const today = new Date()
	// find the date 90 days from the date the user receives the application ID
	const deadline = new Date(today.setDate(today.getDate() + 90))

	// Format the date as "Mon D, YYYY"
	const options = { month: 'short', day: 'numeric', year: 'numeric' }
	const formattedDeadline = deadline.toLocaleDateString('en-US', options)

	return formattedDeadline
}
