/*
 we define our action types as constants to help avoid typos and make it clear what our possible
 actions are.
 */
export const SET_WEGLOT_ENABLED = 'SET_WEGLOT_ENABLED'
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE'

/**
 * an action to set Weglot as enabled or disabled
 *
 * @param {boolean} enabled – Whether or not Weglot functionality is enabled
 */
export const setWeglotEnabledAction = (enabled = true) => ({
	type: SET_WEGLOT_ENABLED,
	payload: { enabled },
})

/**
 * an action to set the current weglot-detected language
 *
 * @param {boolean} language – The language we want to set the value to
 */
export const setCurrentLanguage = (language) => ({
	type: SET_CURRENT_LANGUAGE,
	payload: { language },
})
