// react imports

// local imports
import ChatIcon from '&assets/icons/chat.svg'
import Button from '&components/Button'
import IconBase from '&components/IconBase'
import { setChatWindowOpenAction } from '&state/hubSpotChat/actions'
import { useHubSpotChat } from '&state/hubSpotChat/context'

/**
 * This component houses our chat button and functionality
 * @param {string} id (Default 'chat-button') HTML ID attr to assign chat button
 * @param {string} queryParameter Query parameter to add to window - this is used to target a specific HubSpot chat flow for a specific button instance
 * @param {Boolean} iconOnly If true, it will not display the text, just the icon
 *  parameter that displays the chat button as a link with the string "Chat with our specialists" instead of a button
 * @param {Boolean} linkOnly If true, it will display as a link with the string
 * "Chat with our specialists" instead of a button
 */
const ChatButton = ({
	id = 'chat-button',
	queryParameter,
	iconOnly,
	linkOnly,
	...args
}) => {
	const [{ enabled, loaded, open }, chatDispatch] = useHubSpotChat()

	const handleChatClick = () => {
		/**
		 * Before dispatching a window message to open the chat, check to see if this instance
		 * of the chat button has a {queryParameter} prop defined. If it does, it is indicating that
		 * a certain chat flow is being targeted, and to match that target we need to push the query
		 * parameter to window.history in order for the window-level HubSpot widget to route to the
		 * desired chat flow.
		 *
		 * Docs here: https://developers.hubspot.com/docs/api/conversations/open-to-chatflow
		 */
		if (queryParameter) {
			const searchParams = new URLSearchParams(window.location.search)
			searchParams.set(queryParameter, 'true')
			const newUrl = `${window.location.pathname}?${searchParams.toString()}`
			window.history.pushState({}, '', newUrl)
			window.HubSpotConversations.widget.refresh({ openToNewThread: true })
		}
		chatDispatch(setChatWindowOpenAction(!open))
	}

	const iconOnlyStyles = iconOnly
		? {
				paddingLeft: 2,
				paddingRight: 2,
				minWidth: 3,
				'& .MuiButton-startIcon': {
					mr: iconOnly ? '0' : '3px',
					mt: iconOnly ? '0' : '3px',
					ml: 0,
				},
		  }
		: {}

	if (linkOnly) {
		return enabled ? (
			<Button
				sx={{
					verticalAlign: 'baseline',
					fontWeight: 400,
				}}
				variant='text'
				onClick={handleChatClick}
				{...args}>
				{'Chat with our specialists.'}
			</Button>
		) : (
			<></>
		)
	}

	return enabled ? (
		<Button
			variant='contained'
			id={id}
			isCondensed
			disabled={!loaded}
			onClick={handleChatClick}
			aria-label={'open chat window'}
			startIcon={<IconBase size='small' icon={ChatIcon} />}
			color='primary'
			sx={{
				maxWidth: 'fit-content',
				fontSize: '1em',
				minWidth: 4,
				'& .MuiButton-startIcon': {
					mr: '3px',
					mt: '3px',
				},
				...iconOnlyStyles,
			}}
			{...args}>
			{!iconOnly && 'Live Chat'}
		</Button>
	) : (
		<></>
	)
}

export default ChatButton
