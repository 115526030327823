import { IDTypes } from '&constants/enums/pathAndId'

// takes in the three types of identification and returns the enum for the user's type
export const getIDType = ({
	ssn = '',
	tribalId = '',
	alternateIdIndicator = 0,
}) => {
	if (ssn) return IDTypes.SSN
	if (tribalId) return IDTypes.TRIBAL
	if (alternateIdIndicator) return IDTypes.ID
	return null
}
