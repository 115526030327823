// react imports

import { useTheme } from '@emotion/react'
import Lottie from 'lottie-react'
import { createRef } from 'react'
import { useScreenshot, createFileName } from 'use-react-screenshot'
// local imports
import { Button, Copy, Header, Layout, Link, InviteBox } from '&components'
import { Box, Divider } from '@mui/material'
import IconBase from '&components/IconBase'
import { useNVResponse } from '&state/nationalVerifier/context'
import useExperiment from '&hooks/useExperiment'
import { getSignUpDeadline } from '&utils/signUpDeadline'
import { useSelection } from '&state/selection/context'
//media
import DownloadIcon from '&assets/icons/download.svg'
import CopyIcon from '&assets/icons/copy.svg'
import OneIcon from '&assets/icons/1.svg'
import TwoIcon from '&assets/icons/2.svg'
import ThreeIcon from '&assets/icons/3.svg'
import FourIcon from '&assets/icons/4.svg'
import congratsAnimation from '&assets/animations/congrats2.json'
import HousewifiIcon from '&assets/icons/housewifi.svg'

const Approved = () => {
	const theme = useTheme()
	const backgroundColor = theme.palette.secondary.main
	const ref = createRef(null)
	const [, takeScreenShot] = useScreenshot({
		type: 'image/jpeg',
		quality: 1.0,
	})

	const [{ applicationId, response }] = useNVResponse()
	// We check to see if the user has the tribal benefit
	const hasTribalBenefit = response?.tribalStatusConfirmed === 'Y'

	const { featureValue: socialComponentValue } =
		useExperiment('social-component')

	const download = (
		image,
		{ name = 'ACPInstructions', extension = 'jpg' } = {}
	) => {
		const a = document.createElement('a')
		a.href = image
		a.download = createFileName(extension, name)
		a.click()
	}
	const [{ name }] = useSelection()

	const downloadScreenshot = () => takeScreenShot(ref.current).then(download)

	/* TODO: Ask Shan/Marketing about the real texts that are going to be used.
	Maybe move them to their own file? */
	const sharingSubject = 'Learn about ACP!'
	const sharingText =
		'You are sharing this message in a different platform, visit https://beta.getacp.org for more info'

	return (
		<Layout title='Approved' topBarText={'Result'} {...backgroundColor}>
			<div ref={ref} height='100%'>
				<Lottie
					style={{ margin: 'auto', width: '160px' }}
					animationData={congratsAnimation}
					aria-hidden='true'
					data-visual-test='blackout'
				/>
				<Header component='h1' center data-cy='approved-blurb' sx={{ pt: 3 }}>
					{'Congrats! You are approved.'}
				</Header>
				<Box sx={{ px: 3, pt: 4, pb: 3 }}>
					<Box
						sx={{
							backgroundColor: '#FFF1D0',
							p: 3,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							boxShadow: '0px 4px 7px 0px #0000000D',
							borderRadius: '8px',
						}}>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.1 }}>
							<Copy italic sx={{ mb: 2 }}>
								{'ACP application ID'}
							</Copy>
							<Copy
								bold
								sx={{ fontSize: '25px', mb: 2 }}
								className='weglot_exclude'>
								{applicationId}
							</Copy>
							<Divider
								flexItem
								aria-hidden='true'
								sx={{ mb: 2, borderBottomWidth: '1px', bgcolor: '#2C2C2C' }}
							/>
							<Copy sx={{ mb: 2 }} className='weglot_exclude'>
								{name.firstName + ' ' + name.lastName}
							</Copy>
							<Copy sx={{ mb: 2 }}>{`Use by ${getSignUpDeadline()}`}</Copy>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
								justifyContent: 'space-between',
								mb: 0.1,
							}}>
							<HousewifiIcon aria-hidden='true' />
							<Copy sx={{ mb: 2, justifyItems: 'flex-end' }}>
								<Box
									component='span'
									data-cy='benefit-amount'
									sx={{ fontWeight: 'bold' }}>
									{hasTribalBenefit ? '$75' : '$30'}
								</Box>
								{' off/month'}
							</Copy>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						textAlign: 'center',
						pt: 3,
					}}>
					<Button
						data-ga-id='save-image'
						sx={{ alignSelf: 'center', maxWidth: 'fit-content' }}
						onClick={downloadScreenshot}
						isCondensed
						startIcon={
							<IconBase size='small' icon={DownloadIcon} aria-hidden='true' />
						}
						noFill
						variant='outlined'>
						{'Save Image'}
					</Button>
				</Box>
				<Box
					sx={{
						textAlign: 'center',
						pt: 3,
					}}>
					<Button
						data-ga-id='copy-code'
						sx={{ alignSelf: 'center', maxWidth: 'fit-content' }}
						isCondensed
						copyToClipboard
						copyContent={applicationId}
						startIcon={
							<IconBase size='small' icon={CopyIcon} aria-hidden='true' />
						}
						noFill
						aria-label={'copy code of application ID'}
						variant='outlined'>
						{'Copy Code'}
					</Button>
				</Box>
				<Box sx={{ px: 3, pt: 4, pb: 3 }}>
					<div style={{ borderRadius: '10px', overflow: 'hidden' }}>
						<Box
							sx={{
								backgroundColor: 'almostWhite',
								p: 3,
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Copy sx={{ fontSize: 'large', mb: 2 }}>
								{'How to use the application ID?'}
							</Copy>
							<ol
								style={{
									listStyle: 'none',
									paddingInlineStart: '0px',
								}}>
								<li style={{ paddingBottom: '16px', display: 'flex' }}>
									<OneIcon aria-label='One' />
									<Copy sx={{ ml: 2, mb: 0 }}>
										{'Save your application ID.'}
									</Copy>
								</li>
								<li style={{ paddingBottom: '16px', display: 'flex' }}>
									<TwoIcon aria-label='Two' style={{ flexShrink: 0 }} />
									<Copy sx={{ flexShrink: 1, ml: 2, mb: 0 }}>
										{
											'Call an internet provider to sign up within 90 days. If you don’t have an internet provider, please check'
										}{' '}
										<Link
											outbound
											newTab
											href='https://cnm.universalservice.org/'>
											{'this list of eligible providers and plans.'}
										</Link>
									</Copy>
								</li>
								<li style={{ paddingBottom: '16px', display: 'flex' }}>
									<ThreeIcon aria-label='Three' style={{ flexShrink: 0 }} />
									<Copy sx={{ flexShrink: 1, ml: 2, mb: 0 }}>
										{'Let them know you have an ACP application ID.'}
									</Copy>
								</li>
								<li style={{ paddingBottom: '16px', display: 'flex' }}>
									<FourIcon aria-label='Four' style={{ flexShrink: 0 }} />
									<Copy sx={{ flexShrink: 1, ml: 2, mb: 0 }}>
										{
											'You may sign up for eligible plans or use the discount on your current plan. There are special ACP plans that may be cheaper than your current plans.'
										}
									</Copy>
								</li>
							</ol>
						</Box>
					</div>
				</Box>
				{socialComponentValue && (
					<InviteBox
						sharingSubject={sharingSubject}
						sharingText={sharingText}
					/>
				)}
			</div>
		</Layout>
	)
}

export default Approved

export const ApprovedView = {
	component: Approved,
	viewName: 'approved',
}
