/**
 * This is a Redux-style reducer for the Init Context.
 *
 * @param {React.state} state – the current Init state
 * @param {Object} action - an action object
 * @returns {Object} – the new Init state
 */
const initReducer = (state, action) => {
	switch (action.type) {
		case 'SET_GB_FEATURES': {
			return {
				...state,
				growthBookFeatures: action.features,
				isGBAvailable: true,
			}
		}
		case 'SET_GB_UNAVAILABLE': {
			return {
				...state,
				isGBAvailable: false,
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default initReducer
