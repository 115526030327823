// MUI imports
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	DialogTitle,
	IconButton,
} from '@mui/material'
import Button from '../Button'
import { useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'

/**
 * @param {boolean} isOpen - A boolean value that determines if the Dialog is open or not
 * @param {function} setDialogState - A callback function that controls the open state of the Dialog
 * from within itself
 * @param {string} title - The title of the dialog
 * @param {any} children - The content of the dialog
 * @param {any} actions - A component meant to have action buttons that replace the
 * default "Close" button, if necessary
 * @param {string} [scroll = 'body'] - (optional) MUI's scroll property to adjust the scrolling of the info dialog
 * @param {boolean} [showTopCloseButton = false] - (optional) A boolean that determines if the dialog shows the close button in the title or the bottom
 * @param {boolean} [showScrollGradient = false] - (optional) A boolean that determines if the dialog shows a gradient at the bottom of the dialog
 * @param {string} [titleSize = '20px'] - (optional) A string that determines the size of the dialog title
 * @param {string} [titleWidth = '100%'] - (optional) A string that determines the width of the dialog title
 * @param {object} sx - MUI's SX property to adjust the styles of the info dialog
 */

const InfoDialog = ({
	isOpen,
	setDialogState,
	title,
	children,
	actions,
	scroll = 'body',
	showTopCloseButton = false,
	showScrollGradient = false,
	titleSize = '20px',
	titleWidth = '100%',
	sx,
}) => {
	const theme = useTheme()
	const [scrollHeight, setScrollHeight] = useState(90)

	const handleCloseDialog = () => {
		setDialogState(false)
	}

	// We want to conditionally toggle the gradient depending on whether the user has scrolled to the bottom of the dialog content
	// This function sets the scrollHeight state used by the CSS maskImage property, which basically determines if the gradient is shown or not
	const handleScrollPosition = (e) => {
		const el = e.target
		/**
		 * The distance to the bottom of the scrollable area is the total height of the content minus (the height visible dialog content plus how far the user has scrolled from the top)
		 *
		 * el.scrollHeight: total height of the dialog content
		 * el.clientHeight: height of the currently visible dialog content
		 * el.scrollTop: how far the user has scrolled from the top of the dialog content
		 *
		 * For example, if the user has scrolled all the way to the bottom, the visible dialog content plus the distance from the top will equal the total height of the dialog content, so the distance to the bottom will be 0.
		 */
		const distanceToBottom = el.scrollHeight - (el.clientHeight + el.scrollTop)
		distanceToBottom < 90
			? setScrollHeight(distanceToBottom)
			: setScrollHeight(90)
	}

	const BottomActions = () => {
		if (actions) return actions
		else if (!showTopCloseButton)
			return (
				<Button
					isCondensed
					onClick={handleCloseDialog}
					sx={{ alignSelf: 'center', maxWidth: 'min-content' }}>
					{'Close'}
				</Button>
			)
	}

	return (
		<Dialog
			fullScreen={false}
			open={isOpen}
			onClose={handleCloseDialog}
			scroll={scroll}
			role='main'
			PaperProps={{ 'aria-modal': isOpen }}
			aria-labelledby='responsive-dialog-title'
			maxWidth={'false'}
			sx={{
				'&.MuiPaper-root, .MuiDialog-paper': {
					margin: 0,
					borderRadius: '8px',
					maxWidth: `calc(100% - ${theme.spacing(4)})`,
				},
				py: '40px',
				...sx,
			}}>
			<DialogActions
				sx={{
					display: 'flex',
					justifyContent: 'center',
					px: '24px',
					pt: 3,
				}}>
				<DialogTitle
					id='responsive-dialog-title'
					sx={{
						width: titleWidth,
						whiteSpace: 'pre-wrap',
						fontSize: titleSize,
						fontWeight: 600,
						p: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					{title}
					{showTopCloseButton && (
						<IconButton
							aria-label='close dialog'
							onClick={handleCloseDialog}
							edge='end'>
							<CloseIcon />
						</IconButton>
					)}
				</DialogTitle>
			</DialogActions>
			<DialogContent
				onScroll={(e) => {
					handleScrollPosition(e)
				}}
				role='alert'
				sx={{
					position: 'relative',
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '1em',
					pt: 0,
					// The below CSS property creates a linear gradient from the top going to the bottom
					maskImage:
						showScrollGradient &&
						`linear-gradient(to bottom, white calc(100% - ${scrollHeight}px), transparent 100%)`,
				}}>
				<DialogContentText
					sx={{
						whiteSpace: 'pre-wrap',
						fontSize: '1em',
						'& a': {
							fontSize: '1em',
							textTransform: 'none',
						},
						height: '100%',
						fontWeight: 500,
						color: 'veryDark',
					}}>
					{children}
				</DialogContentText>
				<BottomActions />
			</DialogContent>
		</Dialog>
	)
}

export default InfoDialog
