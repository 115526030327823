import {
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	TextField,
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { IMaskMixin } from 'react-imask'
import { memo } from 'react'

// component to wrap MUI TextField with IMask for masking zipcodes
const IMaskInput = IMaskMixin(({ ...props }) => {
	return <TextField {...props} />
})

// component to show '+1' prefix for phone number input
const PhonePrefix = () => {
	return (
		<div
			style={{
				fontFamily: 'Lato',
				fontSize: '16px',
				lineHeight: '48px',
				textAlign: 'center',
				width: '60px',
				height: '48px',
				background: '#F6F5F1',
				color: '#2C2C2C',
			}}>
			(+1)
		</div>
	)
}

/*
 * Reusable component for phone number input. Note: this component does not validate the phone number.
 *
 * @param {boolean} isInvalid – whether the phone number is invalid
 * @param {boolean} isRequired – whether the phone number is required
 * @param {function} onPhoneChange – callback function to handle changes to the phone number.
 * @param {string} [value] – (optional) the value of the phone number input if controlled.
 */
const PhoneInput = ({
	isInvalid = false,
	isRequired = true,
	onPhoneChange,
	value = '',
}) => {
	const formStyles = {
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		background: 'white',
		height: '48px',
		pl: 0,
		'& input': {
			py: 2,
		},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		<FormControl
			error={isInvalid}
			required={isRequired}
			variant='standard'
			sx={{ ...formStyles }}>
			<InputLabel sx={{ ...labelStyles }} htmlFor='phone-input'>
				{'Cell Phone Number'}
			</InputLabel>
			<IMaskInput
				id='phone-input'
				value={value}
				error={isInvalid}
				onChange={(e) => onPhoneChange(e.target.value)}
				sx={{ width: '328px' }}
				InputProps={{
					'aria-describedby': isInvalid ? 'phone-error-text' : undefined,
					sx: { ...inputRootStyles },
					inputProps: {
						'aria-required': isRequired,
						autoComplete: 'tel',
						name: 'tel',
						'data-cy': 'input-tel',
						'data-ga-id': 'input_cell-phone',
					},
					startAdornment: (
						<InputAdornment position='start'>
							<PhonePrefix />
						</InputAdornment>
					),
				}}
				mask={'(000) 000-0000'}
			/>
			{isInvalid && (
				<FormHelperText
					role='alert'
					sx={{ ...messageStyles }}
					data-cy='phone-input-error'
					id='phone-error-text'>
					<ErrorOutlineIcon fontSize='small' />
					{'Please enter a valid phone number.'}
				</FormHelperText>
			)}
		</FormControl>
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(PhoneInput, comparator)
