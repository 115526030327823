// react imports
import { useState } from 'react'
import { useTheme } from '@emotion/react'
// local imports
import {
	Header,
	Layout,
	Copy,
	BackButton,
	ButtonGrid,
	Link,
	TipBox,
	NextButton,
} from '&components'
import { Box } from '@mui/material'
import { clamp } from '&utils/css'
import { SSNInput, TribalIdInput } from '&components/FormInputs'
import { useSelection } from '&state/selection/context'
import {
	setAlternateIdIndicator,
	setSSNAction,
	setTribalIdAction,
} from '&state/selection/actions'
import { useApplicationProcessContext } from '&state/applicationProcess/context'
import { setPathAction } from '&state/applicationProcess/actions'
import { validateSSN, validateTribalId } from '&utils/validation'
import useNavigation from '&hooks/useNavigation'
import { ReviewView } from '../Review'
import IdIcon from '&assets/icons/ID.svg'
import { PathValues, IDTypes } from '&constants/enums/pathAndId'
import RadioBtnInput from '&components/FormInputs/RadioBtnInput'
import useExperiment from '&hooks/useExperiment'
import { getIDType } from '&utils/identification'

const Identification = () => {
	const theme = useTheme()
	const [
		{
			ssn: storedSSN,
			tribalId: storedTribalId,
			alternateIdIndicator: storedAlternateId,
		},
		selectionDispatch,
	] = useSelection()

	const { goBackward, goToView } = useNavigation()
	const [, applicationProcessDispatch] = useApplicationProcessContext()

	const [ssn, setSSN] = useState(storedSSN)
	const [tribalId, setTribalId] = useState(storedTribalId)
	const [isSSNInvalid, setIsSSNInvalid] = useState(false)
	const [isTribalIdInvalid, setIsTribalIdInvalid] = useState(false)
	const [idSelected, setIdSelected] = useState(
		getIDType({
			ssn: storedSSN,
			tribalId: storedTribalId,
			alternateIdIndicator: storedAlternateId,
		}) || IDTypes.SSN
	)

	const { featureValue: enableLifelineTribal } = useExperiment(
		'v2-enable-lifelinetribal'
	)

	const backgroundColor = theme.palette.secondary.main

	const identificationOptions = [
		{
			label: 'Last 4 digits of SSN',
			value: IDTypes.SSN,
			subInput: (
				<SSNInput
					isInvalid={isSSNInvalid}
					onSSNChange={setSSN}
					value={ssn}
					SSNLabel={'Last 4 digits of SSN'}
				/>
			),
			dataCy: 'ssn-radio-btn',
			dataGaId: 'ssn-id',
		},
		{
			label: 'Tribal ID',
			value: IDTypes.TRIBAL,
			subInput: (
				<TribalIdInput
					isInvalid={isTribalIdInvalid}
					onTribalIdChange={setTribalId}
					value={tribalId}
				/>
			),
			dataCy: 'tribal-id-radio-btn',
			dataGaId: 'tribal-id',
		},
		{
			label: 'Other IDs',
			value: IDTypes.ID,
			subInput: null,
			dataCy: 'other-ids-radio-btn',
			dataGaId: 'other-ids',
		},
	]

	const handleNext = () => {
		if (enableLifelineTribal) {
			switch (idSelected) {
				case IDTypes.SSN: {
					handleSSN()
					break
				}
				case IDTypes.TRIBAL: {
					handleTribalId()
					break
				}
				case IDTypes.ID: {
					handleAltIDClick()
					break
				}
				default: {
					console.error('unknown idSelected value')
				}
			}
		} else {
			handleSSN()
		}
	}

	const handleSSN = () => {
		//double checks we have a 4 digit number
		const isValidSSN = validateSSN(ssn)
		setIsSSNInvalid(!isValidSSN)

		if (isValidSSN) {
			selectionDispatch(setSSNAction(ssn))
			selectionDispatch(setAlternateIdIndicator(0))
			applicationProcessDispatch(setPathAction(PathValues.SSN))
			goToView(ReviewView)
		}
	}

	const handleAltIDClick = () => {
		selectionDispatch(setAlternateIdIndicator(1))
		applicationProcessDispatch(setPathAction(PathValues.ID))
		goToView(ReviewView)
	}

	const handleTribalId = () => {
		const isValidTribalId = validateTribalId(tribalId)
		setIsTribalIdInvalid(!isValidTribalId)

		if (isValidTribalId) {
			selectionDispatch(setTribalIdAction(tribalId))
			selectionDispatch(setAlternateIdIndicator(0))
			applicationProcessDispatch(setPathAction(PathValues.TRIBAL))
			goToView(ReviewView)
		}
	}

	return (
		<Layout
			title={'Identification'}
			topBarText={'Application'}
			showProgressBars
			noPadding
			{...backgroundColor}>
			<Box sx={{ p: 3 }}>
				<Header
					component='h1'
					sx={{
						mb: 3,
					}}>
					{'Identification'}
				</Header>
				<Copy sx={{ mb: 4, fontSize: clamp('1.25em') }}>
					{
						'We will not store your government issued identification number and will only share it with the government agency (USAC) that reviews your application. We employ 256-bit encryption during transmission and storage to safeguard your data.'
					}
				</Copy>
				{enableLifelineTribal ? (
					<RadioBtnInput
						isInvalid={false}
						options={identificationOptions}
						value={idSelected}
						radioGroupLabel='Identification types'
						errorText='Please select one before proceeding'
						sx={{ ml: 1 }}
						onRadioChange={(value) => setIdSelected(value)}
						isIdPage
					/>
				) : (
					<>
						<Box sx={{ mb: 5 }}>
							<SSNInput
								isInvalid={isSSNInvalid}
								onSSNChange={setSSN}
								value={ssn}
								SSNLabel={'Last 4 digits of SSN'}
							/>
						</Box>
						<Link
							role='button'
							data-cy='other-ids-btn'
							data-ga-id='other-ids'
							onClick={handleAltIDClick}>
							{'I prefer to use other IDs.'}
						</Link>
					</>
				)}
				{enableLifelineTribal && idSelected === PathValues.ID && (
					<TipBox
						sx={{ mt: 4 }}
						message={`You will need to upload proof of your participation in a federal benefit program as well as your ID.`}
					/>
				)}
				<ButtonGrid sx={{ mb: 5 }}>
					<BackButton isCondensed onClick={goBackward} variant='outlined' />
					<NextButton onClick={handleNext} isCondensed />
				</ButtonGrid>
			</Box>
		</Layout>
	)
}

export default Identification

export const IdentificationView = {
	icon: IdIcon,
	displayName: 'ID',
	component: Identification,
	viewName: 'identification',
}
