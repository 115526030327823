// local imports
import { useNVResponse } from '&state/nationalVerifier/context'
import { setUUIDAction } from '&state/nationalVerifier/actions'
import useFetch from './useFetch'
import { v4 as uuidv4 } from 'uuid'
import { getToken } from '&utils/jwtAuth'

/**
 * This hook is used to send contact Data to the Database.
 *
 * @param {string} authorization - Value of authorization header for eligibility request
 * @param {object} contactData - An object containing firstName, lastName, email and phone number.
 */
const useFetchContactData = () => {
	const [nvState, nvDispatch] = useNVResponse()
	const { response, loading, error, executeFetch } = useFetch()

	const storeContactData = async (reCaptcha, contactData) => {
		// if already loading do nothing
		if (loading) return

		// Check if there's already an uuid created.
		let uuid = nvState.uuid

		if (!uuid) {
			uuid = uuidv4()
			nvDispatch(setUUIDAction(uuid))
		}

		let jwt
		try {
			jwt = await getToken()
		} catch (error) {
			return
		}

		// Making the POST request to the backend.
		executeFetch(`/contact`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Authorization: jwt,
				'x-getacp-recaptcha': reCaptcha,
				'Content-Type': 'application/json',
				'Access-Control-Request-Method': 'POST',
			},
			body: JSON.stringify({
				contactId: uuid,
				...contactData,
				referrer: window.location.origin,
				sourceSubmit: 'false',
				sourceContact: 'true',
			}),
		})
			// handle error if call is unsuccessful
			.catch(() => {
				// TO DO: get product direction as to what to do with errors in the future
				console.error('Error saving information')
			})
	}

	return {
		loading,
		response,
		error,
		storeContactData,
	}
}

export default useFetchContactData
