// react imports

import { useTheme } from '@emotion/react'
import Lottie from 'lottie-react'
import { useState } from 'react'
// local imports
import {
	BackButton,
	ButtonGrid,
	Copy,
	Header,
	Layout,
	NextButton,
} from '&components'
import congratsAnimation from '&assets/animations/congrats1.json'
import { StringInput, DOBInput } from '&components/FormInputs'
import {
	validateDay,
	validateMonth,
	validateName,
	validateYear,
	validateAge,
	trimAndValidate,
} from '&utils/validation'
import { useSelection } from '&state/selection/context'
import { setNameAndDOBAction } from '&state/selection/actions'
import useNavigation from '&hooks/useNavigation'
import { ContactInfoView } from '../ContactInfo'
import HumanIcon from '&assets/icons/human.svg'
import { Box } from '@mui/material'

const NameDOB = () => {
	const theme = useTheme()
	const [
		{
			name: { firstName: storedFirstName, lastName: storedLastName },
			dob: { month: storedMonth, day: storedDay, year: storedYear },
		},
		selectionDispatch,
	] = useSelection()
	const { goBackward, goToView } = useNavigation()

	const [firstName, setFirstName] = useState(storedFirstName)
	const [firstNameInvalid, setFirstNameInvalid] = useState(false)
	const [lastName, setLastName] = useState(storedLastName)
	const [lastNameInvalid, setLastNameInvalid] = useState(false)
	const [month, setMonth] = useState(storedMonth)
	const [monthInvalid, setMonthInvalid] = useState(false)
	const [day, setDay] = useState(storedDay)
	const [dayInvalid, setDayInvalid] = useState(false)
	const [year, setYear] = useState(storedYear)
	const [yearInvalid, setYearInvalid] = useState(false)
	const [DOBInvalid, setDOBInvalid] = useState(false)

	const backgroundColor = theme.palette.secondary.main

	const handleSubmit = () => {
		const { cleanedValue: cleanedFirstName, isValid: validFirstName } =
			trimAndValidate(
				firstName,
				validateName,
				setFirstNameInvalid,
				setFirstName
			)
		const { cleanedValue: cleanedLastName, isValid: validLastName } =
			trimAndValidate(lastName, validateName, setLastNameInvalid, setLastName)
		const { cleanedValue: cleanedMonth, isValid: validMonth } = trimAndValidate(
			month,
			validateMonth,
			setMonthInvalid,
			setMonth
		)
		const { cleanedValue: cleanedDay, isValid: validDay } = trimAndValidate(
			day,
			validateDay,
			setDayInvalid,
			setDay
		)
		const { cleanedValue: cleanedYear, isValid: validYear } = trimAndValidate(
			year,
			validateYear,
			setYearInvalid,
			setYear
		)

		if (
			validFirstName &&
			validLastName &&
			validMonth &&
			validDay &&
			validYear
		) {
			const { isValid: isThirteenOrOlder } = trimAndValidate(
				{ month: month, day: day, year: year },
				validateAge,
				setDOBInvalid
			)

			if (isThirteenOrOlder) {
				const normalizedDay =
					cleanedDay.length === 1 ? `0${cleanedDay}` : cleanedDay
				selectionDispatch(
					setNameAndDOBAction(
						cleanedFirstName,
						cleanedLastName,
						normalizedDay,
						cleanedMonth,
						cleanedYear
					)
				)

				goToView(ContactInfoView)
			}
		}
	}

	// used to reset too young age error so that the modal can be opened multiple times
	const resetError = () => setDOBInvalid(false)

	return (
		<Layout
			title='Name & DOB'
			topBarText={'Application'}
			showProgressBars
			{...backgroundColor}>
			<Lottie
				style={{ margin: 'auto', width: '160px' }}
				animationData={congratsAnimation}
				aria-hidden='true'
				data-visual-test='blackout'
			/>
			<Header component='h1' sx={{ pt: 3 }}>
				{'Congrats!'}
			</Header>
			<Copy large sx={{ pt: 3, mb: 4 }}>
				{`You are eligible. Let's start the application. It will take about 5-8 minutes.`}
			</Copy>
			<Box role='form'>
				{/* first name */}
				<StringInput
					isInvalid={firstNameInvalid}
					isRequired
					name='given-name'
					autoComplete='given-name'
					id='given-name'
					data-cy='input-given-name'
					data-ga-id='input_first-name'
					value={firstName}
					onStringChange={setFirstName}
					stringLabel={'Legal First Name'}
					stringErrorText={
						'Please enter at least one letter for your first name'
					}
					sx={{ display: 'block' }}
				/>
				{/* last name */}
				<StringInput
					isInvalid={lastNameInvalid}
					isRequired
					name='family-name'
					autoComplete='family-name'
					id='family-name'
					data-cy='input-family-name'
					data-ga-id='input_last-name'
					value={lastName}
					onStringChange={setLastName}
					stringLabel={'Legal Last Name or Family Name'}
					stringErrorText={
						'Please enter at least one letter for your last or family name'
					}
				/>
				{/* DOB */}
				<DOBInput
					isDayInvalid={dayInvalid}
					isMonthInvalid={monthInvalid}
					isYearInvalid={yearInvalid}
					isTooYoung={DOBInvalid}
					dayValue={day}
					monthValue={month}
					yearValue={year}
					onMonthChange={setMonth}
					onDayChange={setDay}
					onYearChange={setYear}
					resetError={resetError}
					placeholder={new Date()}
				/>
			</Box>
			<ButtonGrid>
				<BackButton isCondensed onClick={goBackward} variant='outlined' />
				<NextButton onClick={handleSubmit} isCondensed />
			</ButtonGrid>
		</Layout>
	)
}

export default NameDOB

export const NameDOBView = {
	displayName: 'Name',
	icon: HumanIcon,
	component: NameDOB,
	viewName: 'name-dob',
}
