// react imports

import { useTheme } from '@emotion/react'
import { useState } from 'react'
// local imports
import {
	ButtonGrid,
	Copy,
	Header,
	Layout,
	NextButton,
	BackButton,
} from '&components'
import { Box } from '@mui/material'
import { useSelection } from '&state/selection/context'
import { AddressInput } from '&components/FormInputs'
import { validateZipcode, trimAndValidate } from '&utils/validation'
import { setAddressAction } from '&state/selection/actions'
import useNavigation from '&hooks/useNavigation'
import { IdentificationView } from '../Identification'
import HomeIcon from '&assets/icons/home.svg'

const Address = () => {
	const theme = useTheme()
	const [
		{
			address: {
				street: storedStreet,
				aptUnit: storedAptUnit,
				city: storedCity,
				zipCode: storedZipCode,
				stateCode: storedStateCode,
			},
		},
		selectionDispatch,
	] = useSelection()
	const { goBackward, goToView } = useNavigation()

	const backgroundColor = theme.palette.secondary.main

	const [street, setStreet] = useState(storedStreet)
	const [streetInvalid, setStreetInvalid] = useState(false)
	const [aptUnit, setAptUnit] = useState(storedAptUnit)
	const [city, setCity] = useState(storedCity)
	const [cityInvalid, setCityInvalid] = useState(false)
	const [stateCode, setStateCode] = useState(storedStateCode)
	const [stateInvalid, setStateInvalid] = useState(false)
	const [zipCode, setZipCode] = useState(storedZipCode)
	const [zipCodeInvalid, setZipCodeInvalid] = useState(false)

	const handleSubmit = () => {
		const { cleanedValue: cleanedStreet, isValid: validStreet } =
			trimAndValidate(
				street,
				(value) => Boolean(value),
				setStreetInvalid,
				setStreet
			)
		const { cleanedValue: cleanedCity, isValid: validCity } = trimAndValidate(
			city,
			(value) => Boolean(value),
			setCityInvalid,
			setCity
		)
		const { cleanedValue: cleanedStateCode, isValid: validState } =
			trimAndValidate(
				stateCode,
				(value) => Boolean(value),
				setStateInvalid,
				setStateCode
			)
		const { cleanedValue: cleanedZipCode, isValid: validZipCode } =
			trimAndValidate(zipCode, validateZipcode, setZipCodeInvalid, setZipCode)

		const cleanedAptUnit = aptUnit.trim()

		if (validStreet && validCity && validState && validZipCode) {
			selectionDispatch(
				setAddressAction(
					cleanedStreet,
					cleanedAptUnit,
					cleanedCity,
					cleanedStateCode,
					cleanedZipCode
				)
			)
			goToView(IdentificationView)
		}
	}

	return (
		<Layout
			title='Address'
			topBarText={'Application'}
			showProgressBars
			noPadding
			{...backgroundColor}>
			<Box sx={{ p: 3 }}>
				<Header component='h1'>{'Address'}</Header>
				<Copy large sx={{ pt: 3, mb: 4 }}>
					{
						'We need your address to make sure there is no existing Affordable Connectivity Program plan for your household.'
					}
				</Copy>
				<Box role='form'>
					<AddressInput
						isStreetInvalid={streetInvalid}
						isCityInvalid={cityInvalid}
						isStateInvalid={stateInvalid}
						isZipInvalid={zipCodeInvalid}
						onStreetChange={setStreet}
						onAptChange={setAptUnit}
						onCityChange={setCity}
						onStateChange={setStateCode}
						onZipChange={setZipCode}
						streetValue={street}
						aptValue={aptUnit}
						cityValue={city}
						stateValue={stateCode}
						zipValue={zipCode}
						sx={{ mb: 4 }}
					/>
				</Box>
				<ButtonGrid>
					<BackButton isCondensed onClick={goBackward} variant='outlined' />
					<NextButton onClick={handleSubmit} isCondensed />
				</ButtonGrid>
			</Box>
		</Layout>
	)
}

export default Address

export const AddressView = {
	icon: HomeIcon,
	displayName: 'Address',
	component: Address,
	viewName: 'address',
}
