import { createContext, useContext, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import navigationReducer from './reducer'
import { WelcomeView as FirstView } from '&views/Welcome'

/* 
 our views are stored in a library matching their names to their components. we will use this to
 populate our path and order.

 we will use the view name as the key, and the component as the value.
 */
const initialView = { [FirstView.viewName]: FirstView.component }
// since our views are stored in a library, we need to keep track of the order of the views
const initialOrder = [FirstView.viewName]

// The Navigation Context will store all of our information about the steps the user has gone through
const NavigationContext = createContext()

const NavigationProvider = ({ children }) => {
	// this may not exist, since it’s a path param
	let { view: viewName } = useParams()
	/* 
	 and so we’ll make sure it’s safe before we use it. we'll use the view param as the initialView. if
	 it's undefined or not a key in our views, we will default to the 'welcome' step.
	 */
	const initialViewName =
		viewName in initialView ? viewName : FirstView.viewName

	const initialState = {
		views: initialView,
		order: initialOrder,
		currentViewName: initialViewName,
	}

	const [state, dispatch] = useReducer(navigationReducer, initialState)

	return (
		<NavigationContext.Provider value={[state, dispatch]}>
			{children}
		</NavigationContext.Provider>
	)
}

/**
 * rather than importing React.useContext and NavigationContext wherever we want to get nav
 * details, we export a hook which does that for us, so we need only one import elsewhere.
 */
const useNavigationContext = () => {
	const context = useContext(NavigationContext)
	if (context === undefined) {
		throw new Error(
			'useNavigationContext must be used within a NavigationProvider'
		)
	}
	return context
}

export { NavigationContext, NavigationProvider, useNavigationContext }
