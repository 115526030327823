// React imports
import { useEffect, useRef, useState } from 'react'
// MUI imports
import { Box, useTheme } from '@mui/material'
// local imports
import Copy from '../Copy'

/**
 * this component is a linear chunk that is used to indicate the current slide in the image slider
 *
 * @param {number} index - index of the chunk
 * @param {boolean} [isActive=false] - whether or not the chunk is active
 * @param {function} onClick - function to be called when the chunk is clicked
 * @param {function} onKeyDown - function to be called when the chunk is clicked
 * @param {object} args - additional arguments to be passed to the Box component
 */
export const LinearChunk = ({
	isActive = false,
	args,
	onClick,
	onKeyDown,
	theme,
}) => {
	return (
		<Box
			role='button'
			aria-label='Scroll image gallery'
			tabIndex={0}
			onClick={onClick}
			onKeyDown={onKeyDown}
			sx={{
				backgroundColor: isActive ? 'secondary.light' : 'almostWhite',
				height: '5px',
				width: '100%',
				cursor: 'pointer',
				transition: 'background-color 0.1s ease-in-out',
				'&:hover': {
					backgroundColor: isActive ? 'secondary.main' : '#ECEBE7',
				},
				'&:focus-visible': {
					outline: `2px solid ${theme.palette.secondary.dark}`,
					borderRadius: '2px',
				},
			}}
			{...args}
		/>
	)
}

/**
 * Image slider components that displays a horizontal slider of images with titles, locations and a linear progress bar.
 *
 * @param {array} slides - array of slide objects to be rendered, including image, title, and location
 * @param {number} [width= 360px] - width of the slider
 */
const ImageSlider = ({ slides, width = '360px', sx }) => {
	const theme = useTheme()
	const [currIndex, setCurrIndex] = useState(0)
	const [scrollTimeout, setScrollTimeout] = useState(null)
	const imageSlider = useRef(null)

	useEffect(() => {
		return () => clearTimeout(scrollTimeout)
	}, [scrollTimeout])

	const maxIndex = slides.length - 1

	const goToSlide = (slideIndex) => {
		setCurrIndex(slideIndex)
		imageSlider.current.scrollTo({ left: slideIndex * 247, behavior: 'smooth' })
	}

	const handleScroll = (e) => {
		clearTimeout(scrollTimeout)
		const scrollIndex = Math.round(e.target.scrollLeft / 247)
		if (scrollIndex !== currIndex) {
			// a tiny timeout to avoid conflicts with the the goToSlide function
			setScrollTimeout(
				setTimeout(() => {
					setCurrIndex(scrollIndex)
				}, 75)
			)
		}
	}

	return (
		<Box
			component='section'
			aria-label={'image carousel'}
			sx={{ width: width, ...sx }}>
			<Box
				id='image-slider'
				ref={imageSlider}
				tabIndex={0}
				onScroll={handleScroll}
				sx={{
					display: 'grid',
					gridTemplateColumns: `repeat(${maxIndex + 1}, 1fr)`,
					gridColumnGap: '8px',
					overflowX: 'scroll',
					overflowY: 'hidden',
					scrollBehavior: 'smooth',
					pb: 3,
					'&:focus-visible': {
						outline: `2px solid ${theme.palette.secondary.dark}`,
						borderRadius: '2px',
					},
				}}>
				{slides.map((slide, i) => (
					<Box
						key={i}
						role='group'
						aria-label={`slide ${i + 1} of ${maxIndex + 1}`}
						aria-current={i === currIndex ? 'true' : 'false'}
						aria-hidden={i === currIndex ? 'false' : 'true'}
						sx={{
							m: 1,
							width: '247px',
							borderRadius: '10%',
							boxShadow:
								'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
							overflow: 'hidden',
						}}>
						<Box sx={{ height: '225px' }}>
							<img
								src={slide.image}
								alt={slide.title}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									objectPosition: 'center',
								}}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<Copy center sx={{ fontWeight: 700, mb: 0 }}>
								{slide.title}
							</Copy>
							<Copy center italic sx={{ mb: 1 }}>
								{slide.location}
							</Copy>
						</Box>
					</Box>
				))}
			</Box>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: `repeat(${maxIndex + 1}, 1fr)`,
					gridTemplateRows: '1fr',
					gridColumnGap: '4px',
					mt: 2,
					mx: 4,
				}}>
				{slides.map((_, i) => (
					<LinearChunk
						key={i}
						index={i}
						onClick={() => goToSlide(i)}
						isActive={i <= currIndex ? true : false}
						aria-label={`go to slide ${i + 1}`}
						onKeyDown={(e) => {
							if (e.code === 'Space' || e.code === 'Enter') goToSlide(i)
						}}
						theme={theme}
					/>
				))}
			</Box>
		</Box>
	)
}

export default ImageSlider
