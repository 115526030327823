import {
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { memo } from 'react'

/*
 * Reusable component for email input. Note: this component does not validate the email.
 *
 * @param {boolean} isInvalid – whether the email is invalid
 * @param {boolean} isRequired – whether the email is required
 * @param {function} onEmailChange – callback function to handle changes to the email
 * @param {string} [value] – (optional) the value of the email if the input is controlled.
 */
const EmailInput = ({
	isInvalid = false,
	isRequired = true,
	onEmailChange,
	value = '',
}) => {
	const formStyles = {
		pb: 3,
		'&:focus-within': {
			'& > label': {
				color: isInvalid ? 'customError.main' : '#00585A',
				fontWeight: '700',
			},
		},
	}
	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}
	const inputRootStyles = {
		background: 'white',
		height: '48px',
		'& input': {
			py: 1,
		},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: isInvalid ? 'customError.main' : 'secondary.main',
			boxShadow: isInvalid ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	}
	const messageStyles = {
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		'&.Mui-error': { color: 'customError.main' },
	}

	return (
		<FormControl
			error={isInvalid}
			required={isRequired}
			variant='standard'
			sx={{ ...formStyles }}>
			<InputLabel sx={{ ...labelStyles }} htmlFor='email-input'>
				{'Email'}
			</InputLabel>
			<TextField
				id='email-input'
				value={value}
				error={isInvalid}
				onChange={(e) => onEmailChange(e.target.value)}
				sx={{ width: '328px' }}
				InputProps={{
					'aria-describedby': isInvalid ? 'email-error-text' : undefined,
					sx: {
						...inputRootStyles,
					},
					inputProps: {
						'aria-required': isRequired,
						autoComplete: 'email',
						name: 'email',
						'data-cy': 'input-email',
						'data-ga-id': 'input_email',
					},
				}}
			/>
			{isInvalid && (
				<FormHelperText
					role='alert'
					sx={{ ...messageStyles }}
					data-cy='email-input-error'
					id='email-error-text'>
					<ErrorOutlineIcon fontSize='small' />
					{'Please enter a valid email address'}
				</FormHelperText>
			)}
		</FormControl>
	)
}

// memoization comparator so component only rerenders when value or isInvalid changes
const comparator = (prevProps, nextProps) => {
	return (
		nextProps.value === prevProps.value &&
		nextProps.isInvalid === prevProps.isInvalid
	)
}

export default memo(EmailInput, comparator)
