import { USACErrorButton } from '&views/Error'
import { FailureCodes } from '&constants/enums/api'
//images
import Pineapple from '&assets/icons/pineapple.svg'
import Map from '&assets/icons/map.svg'
import Puzzle from '&assets/icons/puzzle.svg'
import Agreement from '&assets/icons/agreement.svg'
import Minor from '&assets/icons/minor.svg'
import Globe from '&assets/icons/globe.svg'
import Files from '&assets/icons/files.svg'

// Dictionary to store error view variations based on status code
const getErrorDict = ({ nvLink }) => ({
	default: {
		ErrorImage: Pineapple,
		errorName: 'Default Error',
		title: 'Sorry...',
		blurb:
			'There was an error with your application. You can reach out to our support team for further assistance.',
	},
	multiple: {
		ErrorImage: Puzzle,
		errorName: 'Multiple Issues',
		title: 'Sorry...',
		blurb:
			'We have identified mismatches between the information you provided and the government’s data. Please kindly review your information. ',
		ErrorButton: <USACErrorButton nvLink={nvLink} />,
	},
	network: {
		ErrorImage: Globe,
		errorName: 'Network Issue',
		title: 'Sorry...',
		blurb:
			'The network is not responding. We are working to resolve the issue. Please come back later.',
	},
	[FailureCodes.INVALID_ADDRESS]: {
		ErrorImage: Map,
		errorName: 'Invalid Address',
		title: 'One more step...',
		blurb:
			'We couldn’t verify your address. You may reach out to our specialists to locate your address. Then continue your application on the Universal Service Administrative Company’s (USAC) website.',
		ErrorButton: <USACErrorButton nvLink={nvLink} />,
	},
	[FailureCodes.TPIV_FAIL_DECEASED]: {
		ErrorImage: Agreement,
		errorName: 'Proof of Life',
		title: 'One more step...',
		blurb: `Based on the information you entered, you will need to upload Proof of Life on the Universal Service Administrative Company's (USAC) website.`,
		ErrorButton: <USACErrorButton nvLink={nvLink} />,
	},
	[FailureCodes.DUPLICATE_ADDRESS]: {
		ErrorImage: Files,
		errorName: 'Duplicate Address',
		title: 'One more step...',
		blurb:
			'An ACP subscription already exists at this address. For multiple households at your address, please complete your application on the Universal Service Administrative Company’s (USAC) website.',
		ErrorButton: <USACErrorButton nvLink={nvLink} />,
	},
	[FailureCodes.SUBSCRIBER_UNDER_18]: {
		ErrorImage: Minor,
		errorName: 'Minor',
		title: 'Sorry...',
		blurb: `If you are a dependent, please apply through your parent or guardian. If you are an emancipated minor, please complete your application on the Universal Service Administrative Company's (USAC) website.`,
		ErrorButton: <USACErrorButton nvLink={nvLink} />,
	},
})

export default getErrorDict
