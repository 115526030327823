import { createContext, useContext, useReducer } from 'react'
import selectionReducer from './reducer'
import {
	benefitOptions,
	connectionOptions,
	internetUsageOptions,
} from '&constants/enums/options'

// the Selection Context will store all of our information about the selections the user has made
const SelectionContext = createContext()

const SelectionProvider = ({ children }) => {
	const initialState = {
		isConnectedAtHome: null,
		name: { firstName: '', lastName: '' },
		dob: { month: undefined, day: '', year: '' },
		phone: '',
		email: '',
		address: {
			street: '',
			aptUnit: '',
			city: '',
			stateCode: undefined,
			zipCode: '',
		},
		ssn: '',
		tribalId: '',
		benefits: benefitOptions,
		connection: connectionOptions,
		usage: internetUsageOptions,
		publicHousingCode: '',
		consentGiven: false,
		alternateIdIndicator: 0,
	}
	const [state, dispatch] = useReducer(selectionReducer, initialState)

	return (
		<SelectionContext.Provider value={[state, dispatch]}>
			{children}
		</SelectionContext.Provider>
	)
}

/**
 * rather than importing React.useContext and SelectionContext wherever we want to get selection details, we
 * export a hook which does that for us, so we need only one import elsewhere.
 */
const useSelection = () => {
	const context = useContext(SelectionContext)
	if (context === undefined) {
		throw new Error('useSelection must be used within a SelectionProvider')
	}
	return context
}

export { SelectionContext, SelectionProvider, useSelection }
