import {
	NameDOBView,
	ContactInfoView,
	AddressView,
	IdentificationView,
	ReviewView,
	APIFeedbackView,
	UploadIDView,
	StatusCheckView,
	TempStatusCheckView,
	ForgotIDView,
} from '&views'
import { PathValues } from './enums/pathAndId'

export const paths = {
	[PathValues.SSN]: [
		NameDOBView,
		ContactInfoView,
		AddressView,
		IdentificationView,
		ReviewView,
		{
			viewName: 'result',
			displayName: 'Result',
			icon: APIFeedbackView.icon,
		},
	].map((step) => ({ ...step, isCompleted: false })),
	[PathValues.TRIBAL]: [
		NameDOBView,
		ContactInfoView,
		AddressView,
		IdentificationView,
		ReviewView,
		{
			viewName: 'result',
			displayName: 'Result',
			icon: APIFeedbackView.icon,
		},
	].map((step) => ({ ...step, isCompleted: false })),
	[PathValues.ID]: [
		NameDOBView,
		ContactInfoView,
		AddressView,
		IdentificationView,
		ReviewView,
		{
			viewName: UploadIDView.viewName,
			displayName: UploadIDView.displayName,
			icon: UploadIDView.icon,
		},
	].map((step) => ({ ...step, isCompleted: false })),
	[PathValues.STATUS_CHECK]: [
		StatusCheckView,
		TempStatusCheckView,
		ForgotIDView,
		{
			viewName: 'result',
			displayName: 'Result',
			icon: APIFeedbackView.icon,
		},
	],
}
