// react imports
import { useState, useEffect, useRef } from 'react'

import { useTheme } from '@emotion/react'
// local imports
import { Copy, Header, Layout, Link } from '&components'
import { Box } from '@mui/material'
import EditInfoInput from '&components/FormInputs/EditInfoInput'
import { CheckboxInput } from '&components/FormInputs'
import ChatButton from '&components/ChatButton'
import useFetchEligibility from '&hooks/useFetchEligibility'
import { useSelection } from '&state/selection/context'
import { setConsentGiven } from '&state/selection/actions'
import useNavigation from '&hooks/useNavigation'
import useReCaptcha from '&hooks/useReCaptcha'
import config from '&config'

const IDNotFound = () => {
	const hasFetched = useRef(false)
	const [isPageLoading, setIsPageLoading] = useState(false)
	const [consentInvalid, setConsentInvalid] = useState(false)

	const theme = useTheme()
	const { goToView } = useNavigation()
	const { executeRecaptcha } = useReCaptcha()
	const [{ consentGiven }, selectionDispatch] = useSelection()
	const {
		loading: eligibilityLoading,
		callEligibilityCheck,
		destination: destinationView,
	} = useFetchEligibility()

	useEffect(() => {
		// if the call is loading, set the page to load
		if (eligibilityLoading) setIsPageLoading(true)
	}, [eligibilityLoading])

	useEffect(() => {
		//listen for a destination, set in useFetchEligibility, then go to it
		if (destinationView && !eligibilityLoading) {
			goToView(destinationView)
		}
	}, [destinationView, eligibilityLoading])

	const handleSubmit = async () => {
		setConsentInvalid(!consentGiven)
		if (consentGiven && !hasFetched.current) {
			//get new captcha token
			const token = await executeRecaptcha(
				config.reCaptcha.actions.SUBMIT_ELIGIBILITY_CHECK
			)
			await callEligibilityCheck(token)
			hasFetched.current = true
		}
	}

	const handleConsent = () => {
		selectionDispatch(setConsentGiven(!consentGiven))
	}

	const backgroundColor = theme.palette.secondary.main

	return (
		<>
			<Layout
				title='Status Check'
				topBarText={'Status Check'}
				noPadding
				isLoading={isPageLoading}
				{...backgroundColor}>
				<Box sx={{ p: 3 }}>
					<Header component='h1'>{'Status Check'}</Header>
					<Copy data-cy='id-not-found-blurb' large sx={{ pt: 3, mb: 4 }}>
						{`Sorry, we couldn't find your application ID. Please enter the information you used to apply or chat with one of our specialists for assistance.`}{' '}
						<ChatButton data-ga-id='live-chat' iconOnly />
					</Copy>
					<Box role='form'>
						<EditInfoInput
							nameTitle='Name*'
							DOBTitleFontWeight={700}
							addressRequired
							SSNRequired
							showContact={false}
							saveButtonText='Submit'
							submitFunction={handleSubmit}
							inputFooter={
								<Box sx={{ mb: -4 }}>
									<Copy sx={{ mb: 0 }}>
										{`By clicking "Submit," your information will be shared with the Universal Service Administrative Company (the federal administrator of the ACP). You agree to EducationSuperHighway’s`}{' '}
										<Link
											newTab
											href='https://www.educationsuperhighway.org/terms-of-service/'>
											{'Terms of Service'}
										</Link>
										{'. '}
										{'See our Privacy Policy'}{' '}
										<Link
											newTab
											aria-label={'ESH Privacy Policy'}
											href='https://www.educationsuperhighway.org/privacy-policy/'>
											{'here'}
										</Link>
										{'.'}
									</Copy>
									<CheckboxInput
										isRequired
										isInvalid={consentInvalid}
										onCheckboxChange={handleConsent}
										checked={consentGiven}
										errorText={`Please check the “I agree” box to proceed.`}
										checkboxLabel={`I understand and agree.`}
										data-cy={'select-agree-terms-box'}
										id='consent'
										sx={{ py: 3 }}
									/>
								</Box>
							}
						/>
					</Box>
				</Box>
			</Layout>
		</>
	)
}

export default IDNotFound

export const IDNotFoundView = {
	component: IDNotFound,
	viewName: 'id-not-found',
}
