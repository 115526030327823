import { useReCaptchaContext } from '../state/reCaptcha/provider'
import { setLatestTokenAction } from '../state/reCaptcha/actions'

export const useReCaptcha = () => {
	const [{ getToken }, dispatch] = useReCaptchaContext()

	const executeRecaptcha = async (action) => {
		const token = await getToken(action)
		dispatch(setLatestTokenAction(token))
		return token
	}

	return {
		executeRecaptcha,
	}
}

export default useReCaptcha
