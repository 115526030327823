/*
 we define our action types as constants to help avoid typos and make it clear what our possible
 actions are.
 */
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW'
export const ADD_TO_VIEWS = 'ADD_TO_VIEWS'

/**
 * an action to set the current view
 *
 * @param {string} viewName – the name of the view to set
 */
export const setViewAction = (viewName) => ({
	type: SET_CURRENT_VIEW,
	payload: { viewName },
})

/**
 * an action to add a view to the views object
 *
 * @param {object} component – the component to add
 * @param {string} viewName – the name of the view above
 */
export const addToViewsAction = (component, viewName) => ({
	type: ADD_TO_VIEWS,
	payload: { component, viewName },
})
