// react imports
import { useEffect, useState } from 'react'
// Other imports
// local imports
import { setApplicationStatusAction } from '&state/nationalVerifier/actions'
import { useNVResponse } from '&state/nationalVerifier/context'
import useFetch from './useFetch'
import {
	ApiStatus,
	NVCertifiableStatuses,
	DocumentErrorFailures,
	SSNErrorFailures,
	ErrorStatuses,
} from '&constants/enums/api'
import { storeEligibilityID } from '&utils/localStorageUtils'
// views
import {
	ApprovedView,
	ErrorView,
	UnderReviewView,
	UploadIDView,
	APIFeedbackView,
	SSNErrorView,
	AppNotFoundView,
	LifelineErrorView,
} from '&views'

/**
 * This hook is used to fetch specifically eligibility data from the API.
 *
 * @param {string} authorization - Value of authorization header for eligibility request
 */
const useFetchStatusCheck = () => {
	const [nvState, nvDispatch] = useNVResponse()
	const { response, loading, error, executeFetch } = useFetch()
	const [destination, setDestination] = useState('')

	const callStatusCheck = (reCaptcha, jwt, eligibilityId) => {
		// if already loading do nothing
		if (loading) return

		// Define the function that fetches the eligibility and sets it as the state variable.
		executeFetch(`/eligibility/${eligibilityId}/status`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Authorization: jwt,
				'x-getacp-recaptcha': reCaptcha,
				'Content-Type': 'application/json',
				'Access-Control-Request-Method': 'POST',
			},
		})
			// handle other responses (applicationStatus, UUID etc) as needed
			.catch((error) => {
				nvDispatch({
					type: 'setResult',
					result: { loading: false, error, response: null },
				})
			})
	}

	//store and set destination based on response
	useEffect(() => {
		// if there is no response or error, do nothing (yet)
		if (!response && !error) return

		// dispatch the action to update the state with the fetched data
		nvDispatch({
			type: 'setResult',
			result: { loading: false, error, response },
		})

		if (error) {
			//divert lifeline applicants from other errors
			setDestination(
				error.status === ErrorStatuses.EXISTING_LIFELINE_APP
					? LifelineErrorView
					: ErrorView
			)
		} else if (response) {
			//store response details
			nvDispatch(setApplicationStatusAction(response))

			storeEligibilityID(
				nvState.uuid,
				response.applicationId,
				response.eligibilityCheckId
			)

			if (response.failures?.length > 0) {
				//then check if there are any failures present

				//get destination based on failures
				const getFailureDestination = (responseArray) => {
					const hasSSNError = responseArray.some((failure) =>
						SSNErrorFailures.includes(failure)
					)
					const hasDocumentError = responseArray.some((failure) =>
						DocumentErrorFailures.includes(failure)
					)

					if (hasSSNError) return SSNErrorView
					else if (hasDocumentError) return UploadIDView
					else return ErrorView
				}

				setDestination(getFailureDestination(response.failures))
			} else {
				//next check api status
				switch (response.status) {
					case ApiStatus.COMPLETE:
						setDestination(ApprovedView)
						break
					case ApiStatus.IN_PROGRESS:
						setDestination(UnderReviewView)
						break
					case ApiStatus.PENDING_REVIEW:
						setDestination(UnderReviewView)
						break
					case ApiStatus.BAD_REQUEST:
						setDestination(ErrorView)
						break
					case ApiStatus.APP_NOT_FOUND:
						setDestination(AppNotFoundView)
						break
					default:
						//if statuses require USAC certification (PENDING_CERT or PENDING_RESOLUTION)
						if (NVCertifiableStatuses.includes(response.status)) {
							//send user to USAC
							setDestination(APIFeedbackView)
						} else {
							//if we don't know what's going on
							setDestination(ErrorView)
						}
				}
			}
		}
	}, [response, error])

	return {
		loading,
		response: nvState.response,
		error: nvState.error,
		callStatusCheck,
		destination,
	}
}

export default useFetchStatusCheck
