import { Box } from '@mui/material'

/**
 * This HOC helps us standardize how buttons are layed out in the app.
 */
const ButtonGrid = ({ children, center, sx = {}, ...args }) => {
	const styles = () => ({
		display: 'flex',
		justifyContent: center ? 'center' : 'space-between',
		mt: '72px',
		mb: 4,
		...sx,
	})

	return (
		<Box sx={styles} {...args}>
			{children}
		</Box>
	)
}

export default ButtonGrid
