import { useNavigationContext } from '../state/navigation/context'
import { setViewAction, addToViewsAction } from '../state/navigation/actions'
import { useApplicationProcessContext } from '../state/applicationProcess/context'
import {
	setCurrentApplicationStepAction,
	setResultAction,
} from '../state/applicationProcess/actions'
import {
	useNavigate as useReactRouterNavigate,
	useParams,
} from 'react-router-dom'
import { useEffect } from 'react'
const queryString = location.search
const onModal = location.pathname.endsWith('/modal')

/**
 * A custom hook to control navigation within our app. For more on what’s happening here, look at
 * the Navigation Context.
 */
export const useNavigation = () => {
	const [{ order, views, currentViewName }, navigationDispatch] =
		useNavigationContext()
	const [{ results }, applicationProcessDispatch] =
		useApplicationProcessContext()
	let { view: viewParam, partner } = useParams()
	// we will build upon this navigateNative function, such that we add the query string to the url
	const navigateNative = useReactRouterNavigate()

	// if we're on a partner page, we want to keep the partner prefix in the views, so let's store it
	const partnerPrefix = partner ? `/${partner}` : '/esh'

	// ensure that the viewParam is the current view
	useEffect(() => {
		navigationDispatch(setViewAction(viewParam))
		applicationProcessDispatch(setCurrentApplicationStepAction(viewParam))
	}, [viewParam])

	/**
	 * we attach the query string to the url that is directly built as an input to the function, and
	 * navigate!
	 *
	 * @param {string} url - the url to navigate to
	 */
	const navigate = (url) => {
		return navigateNative(`${url}${queryString}`)
	}

	/**
	 * @returns the current view
	 */
	const getCurrentView = () => views[currentViewName]

	/**
	 * Navigate to a view by name. If the view doesn't exist in the views, add it.
	 *
	 * @param {object} – an object exported from our view files, such as WelcomeView, containing...
	 * @property {string} name – the name of the view to navigate to
	 * @property {object} view – the view to navigate to
	 */
	const goToView = async (viewObject) => {
		const { component, viewName } = viewObject

		//check if the view is in the results array and set it as the result
		if (results.includes(viewObject)) {
			applicationProcessDispatch(setResultAction(viewObject))
		}

		if (!(viewName in views)) {
			// If the view doesn't exist in the views, add it.
			await navigationDispatch(addToViewsAction(component, viewName))
			navigate(`${partnerPrefix}/${viewName}`)
			return
		}
		navigate(`${partnerPrefix}/${viewName}`)
	}

	/**
	 * Navigate to the next view in the order, if it exists.
	 */
	const goForward = () => {
		const currentIndex = order.indexOf(currentViewName)
		// we add 1 because we're pointing to the NEXT view
		const nextViewIndex = currentIndex + 1
		const nextViewName = order[nextViewIndex]
		const nextView = views[nextViewName]
		// if there is no next view, we don't want to do anything
		if (!nextView) return
		// otherwise, we navigate to the next view
		goToView({
			component: nextView,
			viewName: nextViewName,
		})
		// and update the application step index
	}

	/**
	 * Navigate to the previous view in the order, if it exists.
	 */
	const goBackward = () => {
		const currentIndex = order.indexOf(currentViewName)
		const previousViewIndex = currentIndex - 1
		const previousViewName = order[previousViewIndex]
		const previousView = views[previousViewName]
		// if there is no previous view, we don't want to do anything
		if (!previousView) return
		// otherwise, we navigate to the previous view
		goToView({
			component: previousView,
			viewName: previousViewName,
		})
		// and update the application step index
	}

	/**
	 * Navigate to the modal view, or back to the previous view, depending on whether the modal is
	 * opening or closing.
	 *
	 * @param {boolean} isOpening – whether the modal is opening or closing
	 */
	const handleModalNavigation = (isOpening) => {
		if (isOpening) {
			if (onModal) navigate('')
			else navigate('./modal')
		} else {
			navigate(`${partnerPrefix}/${currentViewName}`)
		}
	}

	/**
	 * If the param is not a key in our views, we reset to the initial 'welcome' step
	 */
	const resetIndices = () => {
		if (!(viewParam in views) || onModal) {
			// if the param is not a key in our views, we reset to the 'welcome' step
			window.history.replaceState(null, null, `${partnerPrefix}${queryString}`)
		}
	}

	return {
		currentViewName,
		getCurrentView,
		goBackward,
		goForward,
		goToView,
		handleModalNavigation,
		resetIndices,
	}
}

export default useNavigation
