import Link from '../Link'
import { useTheme } from '@emotion/react'

/**
 * An accessible link to skip to the page content.  The link sits at the top of the page.
 * This link sits off page unless hovered or in focus, but always exists in the keyboard focus order.
 *
 * NOTE: this component should always come FIRST in keyboard focus order.
 *
 * @prop {Array} children - children that will be wrapped by parent box and sit next to link
 * @prop {String} ariaLabel - Skip Link's aria label
 */
const SkipLink = ({ children, ariaLabel }) => {
	const theme = useTheme()

	// focus on the top of the page content
	const changeFocus = () => {
		const content = document.getElementById('page-content')
		content.focus()
	}

	const handleKeyPress = (e) => {
		if (e.code === 'Space' || e.code === 'Enter') changeFocus()
	}

	return (
		<Link
			tabIndex='0'
			sx={{
				position: 'absolute',
				backgroundColor: '#fff',
				p: 2,
				borderRadius: 2,
				//visually-hidden
				transform: 'translateY(-250%)',
				transition: 'transform 0.3s',
				//on focus
				'&:focus-within': {
					position: 'absolute',
					transform: 'translate(0)',
					border: `2px solid ${theme.palette.secondary.dark}`,
					outline: '1px solid white',
					borderRadius: 2,
				},
				zIndex: 1100,
			}}
			onClick={changeFocus}
			onKeyPress={handleKeyPress}
			role='link'
			aria-label={ariaLabel}>
			{children}
		</Link>
	)
}

export default SkipLink
