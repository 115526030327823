import { getStateFromZip } from './zipCode'

export const validateZipcode = (zipcode) => {
	const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
	const zipIsReal = Boolean(getStateFromZip(zipcode))
	return regex.test(zipcode) && zipIsReal
}

export const validateName = (name) => {
	const regex = /^[\p{L}'\s-]+$/u
	const longEnough = name.length > 1
	return regex.test(name) && longEnough
}

export const validateMonth = (month) => {
	const regex = /^(0?[1-9]|1[012])$/
	return regex.test(month)
}

export const validateDay = (day) => {
	const regex = /^(0?[1-9]|[12][0-9]|3[01])$/
	return regex.test(day)
}

export const validateYear = (year) => {
	const regex = /^(19|20)\d{2}$/
	const isAppropriate = year >= 1900 && year <= 2023
	return regex.test(year) && isAppropriate
}

export const validateEmail = (email) => {
	if (typeof email !== 'string') return false
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone) => {
	const regex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
	return regex.test(phone)
}

export const validateSSN = (ssn) => {
	const regex = /^\d{4}$/ // 4 digits
	return regex.test(ssn)
}

export const validateTribalId = (tribalId) => {
	const regex = /^[\w\d\S]{2,}$/ // 2 or more characters including numbers, letters, and special characters
	return regex.test(tribalId)
}

export const validateApplicationID = (applicationID) => {
	const regex = /^[A-Za-z][0-9]+([-–—]\s*[0-9]+)?$/
	const longEnough = applicationID.length === 12
	return regex.test(applicationID) && longEnough
}

// this function helps us meet regulatory requirements that prevent us from collecting PII of those younger than 13
// more info here: https://www.ftc.gov/business-guidance/resources/childrens-online-privacy-protection-rule-six-step-compliance-plan-your-business
export const validateAge = (dob) => {
	const today = new Date()
	const thirteenYearsAgo = new Date(
		today.getFullYear() - 13,
		today.getMonth(),
		today.getDate()
	)
	// month is 0 indexed, which is why month - 1 is used
	if (new Date(dob.year, dob.month - 1, dob.day) <= thirteenYearsAgo) {
		return true
	}
	return false
}

/** This helps trim whitespaces for user data and run the input value through a validation function.
 * Also it returns a trimmed version of the value and updates the value if the original and the trimmed one don't match.
 * @param {any} inputValue - The value you want to evaluate
 * @param {function} validationFunction - The function to evaluate the format of the value
 * @param {function} setIsInvalid - Function to set invalid state of the value in the component
 * @param {function} setValue - This is meant to receive a setState function that can be called in case we want to
 * update the value in the component after trimming
 * @returns {Object} - this object contains the trimmed value and the valid boolean state.
 */
export const trimAndValidate = (
	inputValue,
	validationFunction,
	setIsInvalid,
	setValue
) => {
	/* Trimming the whitespaces */
	const trimmedValue =
		typeof inputValue === 'string' ? inputValue.trim() : inputValue

	const isValid = trimmedValue && validationFunction(trimmedValue)

	if (setIsInvalid) setIsInvalid(!isValid)

	if (setValue && inputValue !== trimmedValue) setValue(trimmedValue)

	return { cleanedValue: trimmedValue, isValid }
}
