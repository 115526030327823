// react imports

import { useTheme } from '@emotion/react'
// local imports
import { Copy, Layout, BackButton, ButtonGrid } from '&components'
import { Box } from '@mui/material'
import useNavigation from '&hooks/useNavigation'

const TempStatusCheck = () => {
	const theme = useTheme()
	const { goBackward } = useNavigation()

	const backgroundColor = theme.palette.secondary.main

	return (
		<>
			<Layout
				title='Status Check'
				topBarText={'Status Check'}
				noPadding
				{...backgroundColor}>
				<Box sx={{ p: 3 }}>
					<Copy
						data-cy='temp-status-check-blurb'
						large
						display='inline'
						sx={{ pt: 3 }}>
						{'Please call the Universal Service Administrative Company at '}
					</Copy>
					<a
						href='tel:8773842575'
						style={{
							fontFamily: 'Lato',
							fontSize: '20px',
							color: theme.palette.veryDark,
						}}>
						(877) 384-2575
					</a>
					<Copy large display='inline' sx={{ mb: 4 }}>
						{' to check your status.'}
					</Copy>
					<ButtonGrid sx={{ mt: 5, mb: 6 }}>
						<BackButton isCondensed onClick={goBackward} variant='outlined' />
					</ButtonGrid>
				</Box>
			</Layout>
		</>
	)
}

export default TempStatusCheck

export const TempStatusCheckView = {
	component: TempStatusCheck,
	viewName: 'temp-status-check',
}
