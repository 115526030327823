import { useState } from 'react'
import {
	Accordion as MuiAccordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material'
import Copy from '&components/Copy'
import { Add, Remove } from '@mui/icons-material'

/**
 * @param {string} id - Id required for accessibility purposes. This id is used
 * in aria properties.
 * @param {string} dataGaId - Id required for GA tracking.
 * @param {string} title - The text in the summary (or header) of the Accordion item.
 * @param {object} sx - Further styles to apply to the root component.
 * @param {children} any - The content of the accordion.
 */
const Accordion = ({ id, title, sx, children, dataCy = '', dataGaId = '' }) => {
	const [expanded, setExpanded] = useState(false)

	const accordionStyles = {
		backgroundColor: 'almostWhite',
		'&.MuiAccordion-root:before': {
			display: 'none',
		},
		'.MuiAccordionSummary-root': {
			p: 3,
			'& .MuiAccordionSummary-expandIconWrapper': {
				color: 'inherit',
				'&.Mui-expanded': {
					transform: 'rotate(0deg)',
				},
			},
			'& .MuiAccordionSummary-content': {
				margin: 0,
				'&.Mui-expanded': {
					minHeight: 0,
				},
			},
			'&.Mui-expanded': {
				minHeight: 0,
			},
		},
		'& .MuiAccordionDetails-root': {
			p: 3,
		},
	}

	return (
		<MuiAccordion
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			sx={{ ...accordionStyles, ...sx }}>
			<AccordionSummary
				expandIcon={expanded ? <Remove /> : <Add />}
				//Accessibility: MUI derives aria-labelledby and id from the next 2 props
				aria-controls={`${id}-content`}
				id={`${id}-header`}
				//We make the ga-id state the opposite of the current one, signifying the intended action
				data-ga-id={`accordion_${expanded ? 'close' : 'open'}-${dataGaId}`}
				tabIndex={0}
				sx={{
					'.MuiAccordionSummary-content': { pointerEvents: 'none' },
					'.MuiAccordionSummary-expandIconWrapper': { pointerEvents: 'none' },
					pointerEvents: 'auto',
				}}
				data-cy={dataCy}>
				<Copy component='span' sx={{ mb: 0, fontWeight: 700 }}>
					{title}
				</Copy>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</MuiAccordion>
	)
}

export default Accordion
