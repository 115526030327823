import {
	enMonthList,
	esMonthList,
} from '../constants/dropdownOptions/monthList'
import {
	enStateOrTerritoryList,
	esStateOrTerritoryList,
} from '../constants/dropdownOptions/stateOrTerritoriesList'
import { LanguageCodes } from '../constants/enums/languages'
/**
 * Returns the correct monthList based on the selected language.
 *
 * @param {String} value – the 2-letter abbreviation for the month
 * @returns {array} – the month list
 */
export const getMonthList = (language) => {
	let monthList

	switch (language) {
		case LanguageCodes.ES:
			monthList = esMonthList
			break
		default:
			monthList = enMonthList
			break
	}

	return monthList
}

/**
 * Returns the full name of the month given the 2-letter abbreviation.
 *
 * @param {String} value – the 2-letter abbreviation for the month
 * @param {array} monthList – (optional) the month list
 * @returns {String} – the full name of the month
 */
export const getMonthLabel = (value, monthList) => {
	const currMonthList = monthList || getMonthList()
	const month = currMonthList.find((month) => month.value === value)
	return month ? month.label : ''
}

/**
 * Returns the month object given the 2-letter abbreviation.
 *
 * @param {String} value – the 2-letter abbreviation for the month
 * @param {array} monthList – (optional) the month list
 * @returns {Object} – the month object { value: String, label: String }
 */
export const getMonthObject = (value, monthList) => {
	const currMonthList = monthList || getMonthList()
	const month = currMonthList.find((month) => month.value === value) || null
	return month
}

/**
 * Returns the correct stateOrTerritoryList based on the selected language.
 *
 * @param {String} value – the 2-letter abbreviation for the state
 * @returns {array} – the state or territory list
 */
export const getStateOrTerritoryList = (language) => {
	let stateOrTerritoriesList

	switch (language) {
		case LanguageCodes.ES:
			stateOrTerritoriesList = esStateOrTerritoryList
			break
		default:
			stateOrTerritoriesList = enStateOrTerritoryList
			break
	}

	return stateOrTerritoriesList
}

/**
 * Returns the state or territory object given the 2-letter abbreviation.
 *
 * @param {String} value – the 2-letter abbreviation for the state or territory
 * @param {array} stateOrTerritoryList - (optional) The state and territory list
 */
export const getStateOrTerritoryObject = (value, stateOrTerritoryList) => {
	const currStateOrTerritoryLis =
		stateOrTerritoryList || getStateOrTerritoryList()
	const stateOrTerritory =
		currStateOrTerritoryLis.find(
			(stateOrTerritory) => stateOrTerritory.value === value
		) || null
	return stateOrTerritory
}

/**
 * This utility returns the label that will be displayed whenever a state or territory is
 * selected
 * @param {string} value - the 2-letter abbreviation for the state or territory
 * @param {array} stateOrTerritoryList - (optional) The state and territory list
 */
export const getStateOrTerritoryLabel = (value, stateOrTerritoryList) => {
	const currStateOrTerritoryLis =
		stateOrTerritoryList || getStateOrTerritoryList()
	const state = currStateOrTerritoryLis.find((month) => month.value === value)
	return state ? state.label : ''
}
