/*
 This file determines which environment we’re in, and provides the app with the appropriate config
 */

export const defaults = {
	metaAppId: '1997595870582731',
	nvCallbackBroadcastChannel: 'getacp_nv_callback',
	nvRedirectMessageName: 'nv_redirect',
}
/**
 * The google analytics id is not being used yet, but remains
 * available in case we need to change the GA property we're sending
 * data to.
 **/
export const dev = {
	apiBaseUrl: 'https://samwise-dev-api.getacp.org',
	devMode: true,
	datadogAppId: '6ffd2e80-b5a3-45d5-b0eb-a5f4e8162010',
	datadogClientToken: 'pub84ef7706c64a8debc01e19a05d7d9d26',
	datadogService: 'samwise-dev',
	datadogEnv: 'dev',
	featuresEndpoint:
		'https://growthbook-api.getacp.org/api/features/dev_yYrc9s6P9mqGMAYE6ybe6lWhOz7ZTXZpRM0ek6U0',
	reCaptcha: {
		siteKey: '6Ld99HUnAAAAAJIhyqLa-1IRCR9uSM-07GyQd246',
		actions: {
			SUBMIT_ELIGIBILITY_CHECK: 'submitEligibilityCheck',
		},
	},
	disableApplications: false,
	// googleAnalytics: 'G-SDL5788LVV',
}

export const beta = {
	apiBaseUrl: 'https://samwise-beta-api.getacp.org',
	datadogAppId: '9dd928aa-7fa1-4307-b416-493292015f46',
	datadogClientToken: 'pub482b0f5024ef2cc38040de69aa44061a',
	datadogService: 'samwise-beta',
	datadogEnv: 'beta',
	featuresEndpoint:
		'https://growthbook-api.getacp.org/api/features/beta_FOrm2hjjT5FHZo7DdNtBDrYzWAcLpqpdG9GAn1xr98',
	reCaptcha: {
		siteKey: '6Lex3DgnAAAAAMzRS_c-OIjzwNqrMwnyaM0RDlNx',
		actions: {
			SUBMIT_ELIGIBILITY_CHECK: 'submitEligibilityCheck',
		},
	},
	disableApplications: false,
	// googleAnalytics: 'G-SDL5788LVV',
}

export const prod = {
	apiBaseUrl: 'https://samwise-api.getacp.org',
	datadogAppId: '9d3d5165-173b-4ebe-8a58-fbfa8cb44e8f',
	datadogClientToken: 'pube67da4bd0d74134b96eb63fa7255ee7f',
	datadogService: 'samwise-prod',
	datadogEnv: 'prod',
	featuresEndpoint:
		'https://growthbook-api.getacp.org/api/features/prod_8x1szXQtfKTVRRVk5NWsBcLzNApjhqQA49GNVxiKs',
	reCaptcha: {
		siteKey: '6Ler03onAAAAAKx0EloSt6D2llFdXiIWIo0tbX3w',
		actions: {
			SUBMIT_ELIGIBILITY_CHECK: 'submitEligibilityCheck',
		},
	},
	disableApplications: true,
	// googleAnalytics: 'UA-31707186-29',
}

export const projectWavesBeta = {
	apiBaseUrl: 'http://dm7s8zftqng2g.cloudfront.net',
	datadogAppId: '12b56553-cd4b-46a8-94cc-541373d5afc4',
	datadogClientToken: 'pub8f900522b7a67550de28e5be85d14da4',
	datadogService: 'samwise-projectwavesbeta',
	datadogEnv: 'projectwavesbeta',
	featuresEndpoint:
		'https://growthbook-api.getacp.org/api/features/proj_lwV9ywHs7bC8JfCFtoJWni4uIw0iBIBV5IEFqFbgY',
	reCaptcha: {
		siteKey: '6Lex3DgnAAAAAMzRS_c-OIjzwNqrMwnyaM0RDlNx',
		actions: {
			SUBMIT_ELIGIBILITY_CHECK: 'submitEligibilityCheck',
		},
	},
	disableApplications: false,
	// googleAnalytics: 'G-SDL5788LVV',
}

export const projectWavesProd = {
	apiBaseUrl: 'https://d2rrt5obbko1ek.cloudfront.net',
	datadogAppId: 'c0763ce4-581e-46e5-b53f-be111a1e513c',
	datadogClientToken: 'pubde06bdfc2e4d851955318d0eaf48a13c',
	datadogService: 'samwise-projectwavesproduction',
	datadogEnv: 'projectwavesproduction',
	featuresEndpoint:
		'https://growthbook-api.getacp.org/api/features/proj_i4b9Wfev56TwTeKNQ0ro2dDrOwGntzp2hfADcTYLlw',
	reCaptcha: {
		siteKey: '6LeXh1wpAAAAAPdr73fUvBY8dXSBirUmfXn0WYMc',
		actions: {
			SUBMIT_ELIGIBILITY_CHECK: 'submitEligibilityCheck',
		},
	},
	disableApplications: true,
	// googleAnalytics: 'G-SDL5788LVV',
}

export const config = (environment = process.env.NODE_ENV) => {
	switch (environment) {
		case 'projectWavesBeta':
			return projectWavesBeta
		case 'production':
			return prod
		case 'beta':
			return beta
		default:
			return dev
	}
}

export const getConfigName = (environment = process.env.NODE_ENV) => {
	switch (environment) {
		case 'projectWavesBeta':
			return 'projectWavesBeta'
		case 'production':
			return 'prod'
		case 'beta':
			return 'beta'
		default:
			return 'dev'
	}
}

export default {
	...defaults,
	...config(),
}
