// React imports
import { useEffect } from 'react'
import Lottie from 'lottie-react'
// MUI imports
import { Box } from '@mui/material'
import { useTheme } from '@emotion/react'
// Local imports
import TopBar from '../TopBar'
import Footer from '../Footer'
import { getBackgroundColorFrom } from '../../utils/css'
import NavProgressBar from '../NavProgressBar'
import { isIOS } from '&utils/platform'
import loadingAnimation from '&assets/animations/loading.json'
import Copy from '../Copy'

/**
 * an HoC that wraps the pages of the app and provides a some consistent UI elements.
 *
 * @param {string} [backgroundColorProp] - The background color of the page (beyond the maxWidth)
 * @param {object} [sx] - styles object to apply to the main page content container
 * @param {string} [title] - The title of the page
 * @param {boolean} [noTopBar = false] - (optional) Whether to show the TopBar
 * @param {boolean} [noPadding = false] - (optional) Whether to add padding to the main page content container
 * @param {string} [heroImage] - (optional) The image to be rendered as the TopBar background.
 * @param {boolean} [showProgressBars = false] - (optional) Whether to show the two progress bars
 * @param {boolean} [displayCompleteLinearProgress = false] - (optional) Whether the linear progress bar step is completed
 * @param {string} [topBarText] - (optional) The text to be rendered in the TopBar title.
 * @param {boolean} [useMiniFooter = true] - (optional) Whether to show the mini footer
 * @param {boolean} [isLoading = false] - (optional) Whether to show the loading animation
 * @param {boolean} [showTopWeglotButton = false] - (optional) Whether to show the language selector on the topbar or the footer
 * @param {boolean} [disableSkipButton=false] - A boolean that determines whether or not the Skip Link is rendered
 * @param {string} [loadingMessage = 'We are submitting your information ...'] - (optional) The message to be rendered while loading
 */
const Layout = ({
	children,
	backgroundColor: backgroundColorProp,
	sx,
	title,
	noTopBar = false,
	noPadding = false,
	heroImage,
	showProgressBars = false,
	displayCompleteLinearProgress = false,
	topBarText,
	useMiniFooter = true,
	isLoading = false,
	showTopWeglotButton = false,
	disableSkipButton = false,
	loadingMessage = 'We are submitting your information to a government website. Please do not close this window.',
	banner = undefined,
}) => {
	const theme = useTheme()

	// add (lotsa) transparency to the palette color
	let backgroundColor = getBackgroundColorFrom(
		backgroundColorProp || theme.palette.secondary.main
	)

	/** changing the title allows us to track events differently on Google Analytics
	 * NOTE: NVEligbilityCertifyCallback changes its title within its View
	 */
	useEffect(() => {
		document.title = title
			? `Affordable Connectivity Program | ${title}`
			: 'Affordable Connectivity Program'
	}, [title])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	// the height gets messy on iOS, so we need to account for that
	const height = isIOS()
		? {
				// the 6.49 number is set by assuming most mobile phones
				// are below 600 in width and equating this to gandalf's spacing
				minHeight: `calc(100vh - 40px - ${theme.spacing(6.49185)})`,
		  }
		: {
				minHeight: `calc(100vh - 40px - ${theme.spacing(4)})`,
		  }

	const topBar = noTopBar ? null : (
		<TopBar
			title={topBarText}
			heroImage={heroImage}
			showProgressBar={showProgressBars}
			isProgressCompleted={displayCompleteLinearProgress}
			showWeglotButton={showTopWeglotButton}
			disableSkipButton={disableSkipButton}
		/>
	)

	return (
		<Box
			id='layout-box'
			sx={{
				backgroundColor,
				height: '100svh',
				width: '100svw',
				overflowY: 'scroll',
			}}>
			<Box sx={{ backgroundColor: 'white', maxWidth: '500px', m: '0 auto' }}>
				{banner && banner}
				{topBar}
				{showProgressBars && <NavProgressBar />}
				<Box
					id='page-content'
					tabIndex='-1' // this allows the skip link to work
					role='main'
					sx={{
						...height,
						p: noPadding ? 0 : 3,
						position: 'relative',
						zIndex: 1,
						...sx,
					}}>
					{isLoading ? (
						<Box sx={{ p: noPadding ? 3 : 0 }}>
							<Box sx={{ mt: 5 }}>
								<Lottie
									style={{ margin: 'auto', width: '96px' }}
									animationData={loadingAnimation}
									aria-hidden='true'
									data-visual-test='blackout'
								/>
							</Box>
							<Copy aria-live='polite' large sx={{ pt: 5 }}>
								{loadingMessage}
							</Copy>
						</Box>
					) : (
						children
					)}
				</Box>
				<Footer mini={useMiniFooter} showWeglotButton={!showTopWeglotButton} />
			</Box>
		</Box>
	)
}

export default Layout
