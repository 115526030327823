import { useCallback, useState } from 'react'
import config from '&config'

/**
 * This hook is used to fetch data from the API.
 *
 * @param {String} path – the path to the endpoint
 * @param {Object} options – the options to pass to the fetch  call
 * @returns {Object} – an object containing the response, a loading indicator, and any errors
 */
const useFetch = () => {
	const [response, setResponse] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const executeFetch = useCallback(
		async (
			path,
			options = {
				headers: {
					accept: 'application/json',
				},
			}
		) => {
			setLoading(true)
			try {
				if (path) {
					const url = path.startsWith('http') ? path : config.apiBaseUrl + path
					const response = await window.fetch(url, {
						...options,
					})

					const json = await response.json()
					if (response.ok) {
						setResponse(json)
					} else {
						console.error('Error returned by API', json)
						setError(json)
					}
				}
			} catch (error) {
				console.error('Error in call to API', error)
				setError(error)
			} finally {
				setLoading(false)
			}
		},
		[]
	)
	return { response, loading, error, executeFetch }
}

export default useFetch
