import {
	Autocomplete,
	Box,
	FormControl,
	FormHelperText,
	InputLabel,
	TextField,
} from '@mui/material'
import { useNavigation } from '&hooks/useNavigation'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Copy from '../../Copy'
import { IMaskMixin } from 'react-imask'
import { memo, useEffect, useState } from 'react'
import {
	getMonthLabel,
	getMonthList,
	getMonthObject,
} from '&utils/dropdownConstants'
import { IconBase, InfoDialog, Button, ButtonGrid } from '&components'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { NotQualifiedView } from '&views'
import { useWeglot } from '&state/weglot/context'

// component to wrap MUI TextField with IMask for masking
const IMaskInput = IMaskMixin(({ ...props }) => {
	return <TextField {...props} />
})

/*
 * Reusable component for date of birth input. Houses month dropdown, day input, and year input.
 *
 * @param {boolean} [isDayInvalid=false] – whether the day is invalid
 * @param {boolean} [isMonthInvalid=false] – whether the month is invalid
 * @param {boolean} [isYearInvalid=false] – whether the year is invalid
 * @param {boolean} [isTooYoung=false] – whether the applicant is too young (regulatory requirements prevent us from collecting PII of those younger than 13)
 * @param {function} onMonthChange – callback function to handle changes to the month
 * @param {function} onDayChange – callback function to handle changes to the day
 * @param {function} onYearChange – callback function to handle changes to the year
 * @param {string} [dayValue] – (optional) the value of the day input if the input is controlled
 * @param {string} [monthValue] – (optional) the value of the month input if the input is controlled
 * @param {string} [yearValue] – (optional) the value of the year input if the input is controlled
 * @param {function} resetError – resets the error state in the parent to allow the message to be opened repeatedly
 * @param {object} [sx={}] – (optional) optional styles to pass to the container
 */
const DOBInput = ({
	isDayInvalid = false,
	isMonthInvalid = false,
	isYearInvalid = false,
	isTooYoung = false,
	onMonthChange,
	onDayChange,
	onYearChange,
	dayValue = undefined,
	monthValue = undefined,
	yearValue = undefined,
	resetError,
	sx = {},
}) => {
	const [{ currentLanguage }] = useWeglot()
	const monthList = getMonthList(currentLanguage)

	// the default value to display in the month autocomplete
	const [monthInputValue, setMonthInputValue] = useState(
		getMonthLabel(monthValue, monthList)
	)
	const [showDialog, setShowDialog] = useState(false)
	const { handleModalNavigation, goToView } = useNavigation()

	useEffect(() => {
		setShowDialog(window.location.pathname.endsWith('/modal'))
	}, [window?.location?.pathname])

	useEffect(() => {
		if (isTooYoung) {
			setDialogState(true)
		}
	}, [isTooYoung])

	const isDOBInvalid =
		isDayInvalid || isMonthInvalid || isYearInvalid || isTooYoung

	const getFormStyles = (invalidBool) => ({
		'&:focus-within': {
			'& > label': {
				color: invalidBool ? 'customError.main' : '#00585A', // todo – replace with theme (everywhere, mind you)
				fontWeight: '700',
			},
		},
	})

	const labelStyles = {
		fontSize: '1em',
		position: 'relative',
		transform: 'unset',
		pb: '8px',
		color: 'veryDark',
		'&.Mui-error': {
			color: 'customError.main',
			fontWeight: '700',
		},
	}

	const getInputRootStyles = (invalidBool) => ({
		background: 'white',
		height: '48px',
		'& input': {
			py: 1,
		},
		'& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
			{
				WebkitAppearance: 'none',
				margin: 0,
			},
		'&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: 'customError.main',
		},
		'& fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: invalidBool ? 'customError.main' : 'rgba(0, 0, 0, 0.23)',
		},
		'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: invalidBool ? 'customError.main' : 'secondary.main',
			// todo – replace with theme (ask Alan how)
			// todo? - turn on box shadows in theme? Alan turned them off 😬
			boxShadow: invalidBool ? '0px 0px 4px #A30101' : '0px 0px 4px #009296',
			borderWidth: '1px',
		},
	})

	const getMessageStyles = (invalidBool) => ({
		display: 'flex',
		alignItems: 'center',
		'& > svg': { pr: 0.5 },
		color: invalidBool ? 'customError.main' : 'secondary.main',
	})

	const setDialogState = (isOpen) => {
		setShowDialog(isOpen)
		handleModalNavigation(isOpen)
		if (!isOpen) resetError()
	}

	return (
		<>
			<Copy
				sx={{
					mb: 2,
					color: isDOBInvalid ? 'customError.main' : 'veryDark',
					...sx,
				}}>
				{'Date of Birth'}
			</Copy>
			{/* month dropdown */}
			<FormControl
				error={isMonthInvalid || isTooYoung}
				variant='standard'
				required
				sx={{ ...getFormStyles(isMonthInvalid) }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor='month-input'>
					{'Month'}
				</InputLabel>
				<Autocomplete
					disableClearable
					id='month-input'
					/*
					 this is a little different from what you might be used to for inputs.

					  - inputValue: value that the user has typed
						- value: the specific option corresponding to that value (should one exist)

						in our case, the "value" is an object, as found in the monthList constant from which we
						pull the options, as seen in the "options" attribute below.

						for example, if the user types "October" into the input, the inputValue will be
						"October", and the value will be { label: 'October', value: '10' }
					 */
					inputValue={monthInputValue}
					value={getMonthObject(monthValue, monthList)}
					options={monthList}
					/*
					 we onInputChange instead of onChange due to the way Autocomplete works. onChange fires
					 when the user selects an option from the dropdown, but we want to fire when the user
					 types into the input. onInputChange fires when the user types into the input
					 */
					onInputChange={(_, newInputValue, reason) => {
						setMonthInputValue(newInputValue)
						const newMonthObject = monthList.find(
							(month) => month.label === newInputValue
						)
						if (newMonthObject) {
							onMonthChange(newMonthObject.value)
							/*
							 this part is probably not necessary due to the blur below, but I'm leaving it in for
							 future use / safety
							 */
						} else if (reason === 'clear' || reason === 'reset') {
							onMonthChange('')
						}
					}}
					/*
					 this bad boy will actually do the "autocomplete" part of the autocomplete. when the user
					 blurs the input (i.e. clicks out of it), we check to see if the inpputValue matches the
					 beginning of any of the options. if it does, we set the value to that option. if it
					 doesn't, we do nothing.

					 example: if the user types "Oct" into the input, then clicks out of it, the input value
					 will be completed to "October", and the value will be { label: 'October', value: '10' }
					 */
					onBlur={() => {
						const matchingMonths = monthList.filter((month) =>
							month.label
								.toLowerCase()
								.startsWith(monthInputValue.toLowerCase())
						)
						if (matchingMonths.length) {
							const newMonthObject = matchingMonths[0]
							onMonthChange(newMonthObject.value)
							setMonthInputValue(newMonthObject.label)
						}
					}}
					/*
					 as mentioned above, our "options" are objects, so we need to tell Autocomplete how to
					 compare them to the "value" (which is also an object). we do this by passing a function
					 to isOptionEqualToValue that returns true if the option and value are equal, and false
					 otherwise
					 */
					isOptionEqualToValue={(option, selection) => {
						return option.value === selection.value
					}}
					sx={{
						'& .MuiInputBase-root': {
							height: '48px',
							width: '153px',
							...getInputRootStyles(isMonthInvalid || isTooYoung),
						},
					}}
					ListboxProps={{ className: 'weglot_exclude' }}
					renderInput={(params) => (
						<TextField
							{...params}
							inputProps={{
								...params.inputProps,
								'aria-required': true,
								'aria-describedby': isMonthInvalid
									? 'month-error-text'
									: undefined,
								style: { padding: '0 0 0 5px' },
								autoComplete: 'bday-month',
								name: 'bday-month',
								'data-cy': 'input-bday-month',
								'data-ga-id': 'input_month',
							}}
							placeholder={'Month'}
						/>
					)}
				/>
			</FormControl>
			{/* day input */}
			<FormControl
				error={isDayInvalid || isTooYoung}
				variant='standard'
				required
				sx={{ px: 2, ...getFormStyles(isDayInvalid) }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor='day-input'>
					{'Day'}
				</InputLabel>
				<IMaskInput
					id='day-input'
					name='bday-day'
					autoComplete='bday-day'
					value={dayValue}
					error={isDayInvalid}
					onChange={(e) => onDayChange(e.target.value)}
					sx={{ width: '73px' }}
					InputProps={{
						'aria-describedby': isDayInvalid ? 'day-error-text' : undefined,
						sx: {
							...getInputRootStyles(isDayInvalid || isTooYoung),
						},
						inputProps: {
							'aria-label': 'Day', // adding an aria-label so the screen reader does not read the asterisk
							autoComplete: 'bday-day',
							name: 'bday-day',
							'data-cy': 'input-bday-day',
							'data-ga-id': 'input_day',
							'aria-required': true,
						},
					}}
					mask={/^(0?[1-9]|[12]\d|3[01])$/}
					placeholder={'DD'}
				/>
			</FormControl>
			{/* year input */}
			<FormControl
				error={isYearInvalid || isTooYoung}
				variant='standard'
				required
				sx={{ ...getFormStyles(isYearInvalid) }}>
				<InputLabel sx={{ ...labelStyles }} htmlFor='year-input'>
					{'Year'}
				</InputLabel>
				<IMaskInput
					id='year-input'
					name='bday-year'
					autoComplete='bday-year'
					error={isYearInvalid}
					value={yearValue}
					onChange={(e) => onYearChange(e.target.value)}
					sx={{ width: '86px' }}
					InputProps={{
						'aria-describedby': isYearInvalid ? 'year-error-text' : undefined,
						sx: {
							...getInputRootStyles(isYearInvalid || isTooYoung),
						},
						inputProps: {
							'aria-label': 'Year', // adding an aria-label so the screen reader does not read the asterisk
							'aria-required': true,
							autoComplete: 'bday-year',
							name: 'bday-year',
							'data-cy': 'input-bday-year',
							'data-ga-id': 'input_year',
						},
					}}
					mask={'0000'}
					placeholder={'YYYY'}
				/>
			</FormControl>
			<Box role='alert'>
				{/* error texts */}
				{isMonthInvalid && (
					<FormHelperText
						sx={{ ...getMessageStyles(isMonthInvalid) }}
						id='month-error-text'>
						<ErrorOutlineIcon fontSize='small' />
						{'Please select a month from the dropdown menu'}
					</FormHelperText>
				)}
				{isDayInvalid && (
					<FormHelperText
						sx={{ ...getMessageStyles(isDayInvalid) }}
						id='day-error-text'>
						<ErrorOutlineIcon fontSize='small' />
						{'Please enter a valid two digit number for your day of birth'}
					</FormHelperText>
				)}
				{isYearInvalid && (
					<FormHelperText
						sx={{ ...getMessageStyles(isYearInvalid) }}
						id='year-error-text'>
						<ErrorOutlineIcon fontSize='small' />
						{'Please enter a valid four digit number for your year of birth'}
					</FormHelperText>
				)}
			</Box>
			<InfoDialog
				isOpen={showDialog}
				setDialogState={setDialogState}
				title={
					<Box
						data-cy='confirm-dob-dialog'
						sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<IconBase size='small' icon={CalendarMonthIcon} />
						{'Confirm Your Date of Birth'}
					</Box>
				}
				sx={{
					'& .MuiDialogActions-root': {
						justifyContent: 'flex-start',
						backgroundColor: 'almostWhite',
					},
					'& .MuiDialogContent-root': {
						p: 3,
						gap: 4,
					},
				}}
				actions={
					<ButtonGrid sx={{ m: 0 }}>
						<Button
							isCondensed
							onClick={() => setDialogState(false)}
							variant='outlined'>
							{'No'}
						</Button>
						<Button isCondensed onClick={() => goToView(NotQualifiedView)}>
							{'Yes'}
						</Button>
					</ButtonGrid>
				}>
				{`Is ${getMonthLabel(monthValue, monthList)}`}
				<span className='weglot_exclude'>{` ${dayValue}, ${yearValue}`}</span>
				{` your date of birth?`}
			</InfoDialog>
		</>
	)
}

// memoization comparator so component updates only when one of the Invalid props or the values change
const comparator = (prevProps, nextProps) => {
	const dayValueUnchanged = prevProps.dayValue === nextProps.dayValue
	const monthValueUnchanged = prevProps.monthValue === nextProps.monthValue
	const yearValueUnchanged = prevProps.yearValue === nextProps.yearValue
	const dayInvalidUnchanged = prevProps.isDayInvalid === nextProps.isDayInvalid
	const monthInvalidUnchanged =
		prevProps.isMonthInvalid === nextProps.isMonthInvalid
	const yearInvalidUnchanged =
		prevProps.isYearInvalid === nextProps.isYearInvalid
	const isTooYoungUnchanged = prevProps.isTooYoung === nextProps.isTooYoung

	return (
		dayValueUnchanged &&
		monthValueUnchanged &&
		yearValueUnchanged &&
		dayInvalidUnchanged &&
		monthInvalidUnchanged &&
		yearInvalidUnchanged &&
		isTooYoungUnchanged
	)
}

export default memo(DOBInput, comparator)
