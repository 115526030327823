import { ADD_TO_VIEWS, SET_CURRENT_VIEW } from './actions'

/**
 * a reducer to control the state of our navigation. This reducer is used in the Navigation context
 */
const navigationReducer = (state, action) => {
	switch (action.type) {
		case SET_CURRENT_VIEW: {
			const { viewName } = action.payload

			// this value could be a path param, so let’s make sure it’s safe
			const safeView = state.order.includes(viewName)
				? viewName
				: state.order[0]

			return { ...state, currentViewName: safeView }
		}
		case ADD_TO_VIEWS: {
			const { component, viewName } = action.payload
			const currentIndex = state.order.indexOf(state.currentViewName)

			// clear out any steps which might come after this one
			const newOrder = [...state.order]
			newOrder.length = currentIndex + 1

			// and add in our new step
			newOrder.push(viewName)

			// same for the views object
			let newViews = { ...state.views }
			// remove any views which might come after this one
			//* note: this is not really necessary, but does keep the object clean
			Object.keys(newViews)
				.filter((view) => newOrder.indexOf(view) === -1)
				.forEach((unusedKey) => {
					delete newViews[unusedKey]
				})

			// add the new step to our views object with the provided key.
			newViews = { ...newViews, [viewName]: component }

			return {
				...state,
				views: newViews,
				order: newOrder,
				currentViewName: viewName,
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default navigationReducer
