import { Children, cloneElement, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import InfoDialog from '../InfoDialog'
import useNavigation from '../../hooks/useNavigation'

import IconBase from '../IconBase'
import Notice from '../../assets/icons/components/Notice'
import Copy from '../Copy'

/**
 * This component renders checkboxes in a grid layout, receives the function to perform
 * when one of them is checked and passes it down to all children, and shows an error message
 * when the parent element informs of an error.
 *
 * @param {any} children - The MultiSelect buttons to render
 * @param {Object} sx - Styling passed down from parent element.
 * @param {function} checkFunction - The function triggered when the MultiSelect buttons are checked.
 * @param {string} label - The label text that will describe the group element.
 * @param {boolean} error - Since the mapping and state management regarding the options happens in the
 * views, this component relies on the parent to pass down an error value to display the error message.
 * @param {resetError} function - Re sets the error state in the parent to allow the message to be opened
 * repeatedly.
 * @param {any} args - Other arguments applied to the grid element.
 */
const MultiSelectGrid = ({
	children,
	sx,
	checkFunction,
	label,
	error,
	resetError,
	...args
}) => {
	const [dialogOpen, setDialogOpen] = useState(false)
	const { handleModalNavigation } = useNavigation()

	// a quick check to make sure that our pathname corresponds with our dialog state
	useEffect(() => {
		setDialogOpen(window.location.pathname.endsWith('/modal'))
	}, [window?.location?.pathname])

	useEffect(() => {
		if (error) {
			setDialogOpen(true)
		}
	}, [error])

	const setDialogState = (isOpen) => {
		setDialogOpen(isOpen)
		handleModalNavigation(isOpen)
		// Re setting error state to allow the modal to open multiple times
		if (!isOpen) resetError()
	}

	const theme = useTheme()

	return (
		<>
			<Copy sx={{ mb: theme.spacing(4) }} id='checkbox-group-label'>
				{label}
			</Copy>
			<Box
				role='group'
				aria-labelledby='checkbox-group-label'
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(auto-fit, 110px)',
					justifyContent: 'center',
					columnGap: theme.spacing(4.5),
					rowGap: theme.spacing(4),
					marginX: 'auto',
					alignItems: 'flex-start', // this is to make sure the buttons are aligned even if the text is different lengths
					...sx,
				}}
				{...args}>
				{Children.map(children, (child) => (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}>
						{cloneElement(child, {
							style: { ...child.props.style },
							onCheck: checkFunction,
						})}
					</Box>
				))}
				<InfoDialog
					isOpen={dialogOpen}
					title={'No Selection Found'}
					setDialogState={setDialogState}
					titleWidth='fit-content'>
					<Copy
						component='span'
						center
						sx={{
							display: 'flex',
							justifyContent: 'space-evenly',
							width: '100%',
							mb: 0,
							color: 'veryDark',
						}}>
						<IconBase
							icon={Notice}
							color={theme.palette.veryDark}
							size='small'
							sx={{ display: 'inline-block', marginRight: '4px' }}
							aria-hidden='true'
						/>
						{'Please select one before proceeding'}
					</Copy>
				</InfoDialog>
			</Box>
		</>
	)
}

export default MultiSelectGrid
