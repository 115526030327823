import { Box } from '@mui/material'
import Copy from '../Copy'
import Link from '../Link'
import LanguageSelector from '../LanguageSelector'
import IconBase from '../IconBase'
import { useWeglot } from '&state/weglot/context'
import FacebookIcon from '../../assets/icons/facebook.svg'
import InstagramIcon from '../../assets/icons/instagram.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import LinkedInIcon from '../../assets/icons/linkedin.svg'

const Footer = ({ mini = true, showWeglotButton = true }) => {
	const [{ enabled: enableWeglot }] = useWeglot()
	const eshLogo = new URL(
		'../../assets/images/esh-logo.png?as=webp&width=200',
		import.meta.url
	)

	return (
		<Box
			component='footer'
			sx={{
				mt: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: 3,
				paddingBottom: 4,
				paddingTop: mini ? 4 : 2,
				backgroundColor: mini ? 'almostWhite' : 'white',
			}}>
			<Box display='flex' gap={2} alignItems={'center'}>
				<Copy sx={{ mb: 0 }}>{'Powered by'}</Copy>
				<Link
					data-ga-id='footer_esh-logo'
					newTab
					href='https://www.educationsuperhighway.org/'
					aria-label='Education Superhighway'>
					<img
						alt={'Education SuperHighway logo'}
						src={eshLogo}
						width='100'
						style={{ pointerEvents: 'none' }}
					/>
				</Link>
			</Box>
			<Box display={'flex'} gap={3}>
				<Link
					data-ga-id='footer_privacy-policy'
					newTab
					href='https://www.educationsuperhighway.org/privacy-policy/'>
					{'Privacy Policy'}
				</Link>
				<Link
					data-ga-id='footer_terms-of-service'
					newTab
					href='https://www.educationsuperhighway.org/terms-of-service/'>
					{'Terms Of Service'}
				</Link>
			</Box>
			{showWeglotButton && enableWeglot && (
				<Box display={'flex'} justifyContent={'center'}>
					<LanguageSelector opensUp />
				</Box>
			)}
			<Box display={mini ? 'none' : 'flex'} gap={3}>
				<Link newTab href='https://www.facebook.com/EducationSuperHighway/'>
					<IconBase
						aria-label={'ESH Facebook page'}
						size='medium'
						icon={FacebookIcon}
						data-ga-id='esh-fb'
					/>
				</Link>
				<Link newTab href='https://twitter.com/EdSuperHighway'>
					<IconBase
						aria-label={'ESH Twitter page'}
						size='medium'
						icon={TwitterIcon}
						data-ga-id='esh-twitter'
					/>
				</Link>
				<Link newTab href='https://www.instagram.com/educationsuperhighway/'>
					<IconBase
						aria-label={'ESH Instagram page'}
						size='medium'
						icon={InstagramIcon}
						data-ga-id='esh-instagram'
					/>
				</Link>
				<Link
					newTab
					href='https://www.linkedin.com/company/educationsuperhighway'>
					<IconBase
						aria-label={'ESH LinkedIn page'}
						size='medium'
						icon={LinkedInIcon}
						data-ga-id='esh-linkedin'
					/>
				</Link>
			</Box>
		</Box>
	)
}

export default Footer
